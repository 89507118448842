<template>
    <div>
      <v-card :disabled="isDisabled" class="mx-auto" tile flat>
        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
          <v-container pa-0 fluid>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :loading="false"
                  loading-text="Please wait..."
                  :items="purchases"
                  hide-default-footer
                  :mobile-breakpoint="0"
                  hide-default-header
                  class="v-data-table-6 mx-auto"
                >
                <!-- Body -->
                <template  v-slot:item="{ item, index }">
                    <v-card tile flat class="mb-6 pa-2 rounded-xl" color="light_gray_1">

                        <div class="d-flex justify-space-between align-center py-2 px-4 flex-table-row">

                            <div class="d-flex flex-column">
                                <div class="mr-6 body-1 font-weight-medium">
                                    {{ getPurchaseName(item) }}
                                </div>
                            </div>

                            <div class="d-flex flex-column">
                                <div>
                                    <v-chip :filter="false" :ripple="false"
                                        class="px-5 font-weight-medium">
                                        {{getPurchaseKind(item)}}
                                    </v-chip>

                                </div>
                            </div>

                            <v-spacer></v-spacer>

                            <div class="d-flex justify-end">
                                <v-btn
                                    depressed
                                    small
                                    @click="handleAddDetails(item)"
                                    :color="getCompleted.includes(item.id) ? 'success' : 'secondary'">
                                    {{ getCompleted.includes(item.id) ? 'Completed' : 'Add Details' }}
                                </v-btn>
                            </div>
                        </div>


                    </v-card>
                </template>
                </v-data-table>
  
                <v-alert dense outlined type="error" v-if="errors.length > 0">
                  <div v-for="msg in errors" v-html="msg"></div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
  
      <!-- Modal -->
      <v-dialog
        max-width="844"
        persistent
        scrollable
        v-model="requirementsDialog"
      >
        <v-card flat :disabled="requirementsDialogLoading">
          <v-progress-linear
            color="secondary"
            indeterminate
            stream
            absolute
            top
            v-if="requirementsDialogLoading"
          ></v-progress-linear>
  
          <v-card-title class="justify-end">
            <v-btn
              icon
              max-width="40"
              max-height="40"
              :style="`background-color: ${hexToRGBA(
                getActiveTheme.colors.secondary,
                0.1
              )}`"
              @click="closeDialog"
              fab
            >
              <v-icon color="secondary">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
  
          <v-card-text class="px-0 py-0 pb-8">
            <v-carousel
              light
              v-model="carousel"
              :continuous="false"
              :show-arrows="false"
              hide-delimiters
              touchless
              height="auto"
              hide-delimiter-background
            >
              <v-carousel-item v-for="(step, key) in steps" :key="key">
                <v-card flat>
                  <v-card-title
                    class="d-flex py-0"
                    :class="vb.mdAndUp ? 'px-8' : 'px-4'"
                  >
                    <div>
                      <h4 :class="'font-size-26'">
                        {{ step.title }} | {{ selectedPurchase ? getPurchaseName(selectedPurchase) : '' }}
                      </h4>
                      <p :class="vb.mdAndUp ? 'body-1' : 'caption'">
                        {{ step.subtitle }}
                      </p>
                    </div>
                    <v-spacer />
                  </v-card-title>
  
  
                    <v-card-text class="px-0">
                        <template v-if=" key == 'purchase'" >
                          <FormPurchase
                            :ref="step['form-ref']"
                            :updater="updater"
                            :data="selectedPurchase"
                            @loading="handleLoading"
                            @submit="handleFormPurchaseSubmit"
                          />
                        </template>
  
                  </v-card-text>
  
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
  
          <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">
            <v-btn
              v-if="!isFirstStep"
              color="light_gray_2"
              class="flex-back-button"
              :min-width="140"
              large
              outlined
              @click="handleBack"
              elevation="0"
              nuxt>
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              :min-width="140"
              large
              elevation="0"
              @click="handleNext"
              nuxt>
              {{ isLastStep ? "Save" : "Next" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  
  import { mapActions, mapState, mapMutations } from "vuex";
  import subscriptions from "~/components/shared/subscriptions";
  import shared from "~/components/v1/submission/WestpacSecurity1/__shared";
  import { store, actions, } from "~/components/v1/submission/WestpacSecurity1/__store";
  import FormPurchase from "~/components/v1/submission/WestpacSecurity1/_FormPurchase";
  
  export default {
    components: {
      FormPurchase,
    },
  
    mixins: [subscriptions, shared],
  
    data() {
      return {
        requirementsDialog: false,
        requirementsDialogLoading: false,
  
        purchases: [],
        selectedPurchase: null,
  
        errors: [],
        updater: 0
      };
    },
  
    computed: {
      ...mapState("flows", [
        "lenderSubmissionTo",
        "calculation",
        "termGroup",
        "commitments",
      ]),
      vb() {
        return this.$vuetify.breakpoint;
      },
      getApplicantKinds() {
        let parts = this.kinds.find((kind) => {
          if (kind.name == "Part") {
            return kind;
          }
        });
  
        return parts.children;
      },
      carousel: {
        get() {
          return store.carousel;
        },
        set(value) {
          actions.setCarousel(value);
        },
      },
      steps() {
        return store.steps;
      },
      isLastStep() {
        if (this.carousel == Object.keys(this.steps).length - 1) {
          return true;
        }
  
        return false;
      },
      isFirstStep() {
        if (this.carousel == 0) {
          return true;
        }
  
        return false;
      },
      showBackButton(){
  
      },
      showNextButton(){
  
      },
      getCompleted() {
  
        let completed = [];

        let fields = ['financedGoodType', 'subFinancedGoodType']

        this.purchases.forEach( purchase => {

            let filled = true

            fields.forEach(field => {
                if(purchase.attributes.lender.westpac[field] == null ) {
                    filled = false
                }
            })

            if(filled) {
                completed.push(purchase.id)
            }
            
        })

        return completed;
      },
      getLoanId() {
        return this.appData?.relationships?.loan?.data?.id;
      },
      primaryApplicantId(){
        return this.calculation['primary-applicant-id']
      },
      assetTypes() {
            let purchasesKinds = this.kinds.find( kind => {
                return kind.name == 'Purchase'
            })
            return purchasesKinds ? purchasesKinds.children : []
        },
    },
  
    methods: {
      formUpdater() {
          return this.updater = Date.now()
      },
      ...mapActions("resource-actions", [
        "getLoan",
        "getPurchases",
        "updatePurchases",
      ]),
      ...mapMutations("dialogs", ["toggleLenderSubmissionHelperDialog"]),
      async pull() {
  
        const res = await this.getPurchases()
        this.purchases = res.data?.data
  
      },
      async push() {

        this.checkCompleted();
  
        let valid = this.errors.length == 0;
  
        let status = valid ? "valid" : "dirty";
  
        if (valid) {
  
          // console.log(this.form);
  
        }
  
        return { valid, status, data: {} };
      },

        getPurchaseName(purchase) {
            let fkind = purchase?.relationships?.kind.data?.id ? purchase.relationships.kind.data : 0
            let filteredKind = null
            let filteredParent = null

            this.assetTypes.forEach( kind => {
                if ( kind.children?.length ) {
                    return kind.children.find( child_kind => {
                        if ( fkind.id === child_kind.id) {
                            filteredParent = kind
                            filteredKind = child_kind
                        }
                    })
                }
                else {
                    if ( kind.id === fkind.id )
                        filteredKind = kind
                }
            })
                
            let desc = `${purchase.attributes['year-of-manufacture']} ${this.$options.filters.formalAndCapitalize(purchase.attributes['make'])} ${purchase.attributes['model']}`

            return desc.indexOf('null') >= 0 ? `Motor Vehicle (${purchase.attributes['year-of-manufacture'] ? purchase.attributes['year-of-manufacture'] : '--'})` : desc

        },
        getPurchaseKind(purchase) {

            let fkind = purchase?.relationships?.kind.data?.id ? purchase.relationships.kind.data : 0
                let filteredKind = null
                let filteredParent = null

                this.assetTypes.forEach( kind => {
                    if ( kind.children?.length ) {
                        return kind.children.find( child_kind => {
                            if ( fkind.id === child_kind.id) {
                                filteredParent = kind
                                filteredKind = child_kind
                            }
                        })
                    }
                    else {
                        if ( kind.id === fkind.id )
                            filteredKind = kind
                    }
                })

                if ( filteredKind?.name === 'Motor Vehicle') {
                    return filteredKind?.name
                }
                
                if ( filteredParent?.name === filteredKind?.name)
                    return filteredKind?.name
                return `${filteredParent?.name} - ${filteredKind?.name}`
        },

      closeDialog() {
        this.requirementsDialog = false;
        actions.resetStore();
      },
      checkCompleted() {
        this.errors = [];
  
        if (this.getCompleted.length != this.purchases.length) {
          this.errors.push("Please complete all requirements");
        }
      },
  
      showHelperModal() {
        this.toggleLenderSubmissionHelperDialog(true);
      },
  
      async handleAddDetails(data) {
        this.requirementsDialog = true;
        this.selectedPurchase = data;
        this.formUpdater()
  
      },
      async handleNext() {
        let stepsArray = Object.keys(this.steps);
  
        switch (stepsArray[this.carousel]) {
  
          case "purchase":
            if (this.$refs.FormPurchase[0]) {
              await this.$refs.FormPurchase[0].validate();
            }
            break;
          default:
            this.carousel++;
            break;
        }
      },
      async handleBack() {
        this.carousel--;
      },
      async handleFormPurchaseSubmit(payload) {
  
        // console.log(payload)
  
        this.requirementsDialogLoading = true;
  
        if (payload?.purchase?.id) {
  
          let res = await this.updatePurchases({
            id: payload.purchase.id,
            type: payload.purchase.type,
            attributes: payload.purchase.attributes,
          });
  
            if (res.status == 200) {

            this.updateLocalResourceAttributes(res.data.data);

                setTimeout(() => {
                this.requirementsDialogLoading = false;
                this.requirementsDialog = false;
                actions.resetStore();
                }, 600);
            
            } else {
                this.requirementsDialogLoading = false;
            }
        }
      },
      updateLocalResourceAttributes(resource) {
        if (!resource) return;
  
        let getType = resource.type;
  
        this[getType].forEach((v, i) => {
          if (v.id == resource.id) {
            this[getType][i]["attributes"] = resource.attributes;
          }
        });
      },
      handleLoading(payload) {
        this.requirementsDialogLoading = payload;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  