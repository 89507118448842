import appTypes from '~/components/shared/app-types'
import appLoanTypes from '~/components/shared/app-loan-types'


export default async (context) => {

    let { calculation, loanData, activeStep, appData } = context.store.state.flows
    let { userSettings } = context.store.state['auth-actions']
    let { onKeywordRoute } = context.store.state

    const { flow_slug } = context.params

    // set app owner settings
    if(appData?.relationships?.owner?.data?.id && !onKeywordRoute) {

        let ownerSettings = await context.store.dispatch(`auth-actions/getUserSetting`, appData.relationships.owner.data.id)

        if(ownerSettings.status == 200) {
            context.store.commit('auth-actions/setAppOwnerSettings', ownerSettings?.data?.data?.attributes)
        }
        
    }

    let route = {
        name: context.store.state.slug.flowSlug.includes('b2b') ? process.env.b2bFlowRouteOnRequestCompleted : process.env.b2cFlowRouteOnRequestCompleted, 
        params: { 
            group_slug: context.store.state.slug.groupSlug, 
            flow: context.store.state.slug.flowSlug, 
            application: context.store.state.flows.appGuid
        } 
    }

    // If App is a b2c flow and completed flow
    // patch the completed to 0

    if (context.store.state.statuses.flowStatus.attributes.completed && context.store.state.flows.flowTags && context.store.state.flows.flowTags.includes('invite')) {
        let flowStatus = {
            "type": "flow-statuses",
            "id": context.store.state.statuses.flowStatus.id,
            "attributes": {
                "completed": 0
            }
        }

        let newFlowStatus = await context.store.dispatch(`statuses/completeFlowStatus`, flowStatus)

        context.store.commit('statuses/setFlowStatus', newFlowStatus.data.data)

    }

    if ( context.store.state.statuses.flowStatus.attributes.completed && !context.store.state.flows.flowTags && context.store.state.flows.flowTags?.[1] != 'invite' ) {
        
        // If App is COMPLETE an don't have tags invite

        context.redirect(`/${route.params.group_slug}/completed-flows/b2b/completed/${route.params.flow}/${route.params.application}`)

    }
    
    else {

        // If App is NOT complete


        if (calculation) {

            let app_flow = getFlowForApp(calculation, loanData, userSettings)

            let appTypeFE = appTypes.data.find(type => type.type == calculation['application-type'])
            
            if(appTypeFE){
                context.store.commit('flows/setAppTypeFE', appTypeFE)
            }

            if ( app_flow === flow_slug ) {
                
                let entity = null 
                let entityId = calculation['primary-applicant-id']
                
                if(calculation['primary-applicant-type'] == 'people'){
                    entity = await context.store.dispatch(`resource-actions/getPerson`, entityId)
                }

                if(calculation['primary-applicant-type'] == 'businesses'){
                    entity = await context.store.dispatch(`resource-actions/getBusiness`, entityId)
                }

                if(entity.data.data){
                    context.store.commit('flows/setPrimaryApplicant', entity.data.data)
                }
            }

            else {
                // context.redirect(`/${route.params.group_slug}/flows/${app_flow}/${activeStep}/${route.params.application}`)
            }

        }
    }    

}



const getFlowForApp = (calcData, loanData, userData) => {

    let attributes = loanData?.attributes
    
    let slug = appLoanTypes[calcData['application-type']][attributes['loan-type']]
    
    if (userData) {
        if ( userData.b2b === 1 )
            return slug.b2b
        else 
            return slug.b2c
    }
    // if user setting is null, its a guest user
    else return slug.b2c

}