<template>
    <div>
      <div class="d-flex" style="width: 100%">
          <v-sheet color="light_gray_1" class="py-12 px-12" width="50%" outlined elevation="0">
              <v-img
                  max-width="120"
                  class="flex-brand"
                  :src="require(`~/assets/images/nodifi.svg`)"
                  position="center left"
                  contain>
              </v-img>
              <div class="mt-2 font-size-20 light_gray_2--text">Data</div>
  
              <v-list color="transparent">
                  <v-list-item two-line class="px-0">
                      <v-list-item-content>
                          <v-list-item-title class="font-weight-medium">Year</v-list-item-title>
                          <v-list-item-subtitle>{{data ? data.attributes['year-of-manufacture'] : '&mdash;'}}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
  
                  <v-list-item two-line class="px-0">
                      <v-list-item-content>
                          <v-list-item-title class="font-weight-medium">Make</v-list-item-title>
                          <v-list-item-subtitle>{{data ? data.attributes['make'] : '&mdash;'}}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line class="px-0">
                      <v-list-item-content>
                          <v-list-item-title class="font-weight-medium">Model</v-list-item-title>
                          <v-list-item-subtitle>{{data ? data.attributes['model'] : '&mdash;'}}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
              </v-list>
  
          </v-sheet>
          <v-sheet class="py-8 px-12" width="50%">
  
              <v-img
                  max-height="30"
                  class="flex-brand mt-6"
                  :src="require(`~/assets/images/logo/cropped/lender-logo-${getLenderLogo}.png`)"
                  position="center left"
                  contain>
              </v-img>
              <div class="mt-2 mb-4 font-size-20 light_gray_2--text">Requirements</div>

              <ValidationObserver ref="observer" tag="form">

                  <ValidationProvider
                          v-slot="{ errors }"
                          :name="labels.financedGoodType"
                          :rules="getRules('financedGoodType')"
                          :vid="'financedGoodType'">
                              <v-select
                                  v-model="resources.purchase.attributes.lender.westpac.financedGoodType"
                                  :items="options.financedGoodType"
                                  :error-messages="errors"
                                  color="secondary"
                                  :label="labels.financedGoodType"
                                  ></v-select>
                  </ValidationProvider>

                  <ValidationProvider
                          v-slot="{ errors }"
                          :name="labels.subFinancedGoodType"
                          :rules="getRules('subFinancedGoodType')"
                          vid="subFinancedGoodType">
                              <v-select
                                  v-model="resources.purchase.attributes.lender.westpac.subFinancedGoodType"
                                  :items="options.subFinancedGoodType[resources.purchase.attributes.lender.westpac.financedGoodType]"
                                  :error-messages="errors"
                                  color="secondary"
                                  :label="labels.subFinancedGoodType"
                                  ></v-select>
                  </ValidationProvider>

              </ValidationObserver>
  
  
          </v-sheet>
      </div>
    </div>
  </template>
  
  <script>
  
      import { mapActions, mapGetters } from 'vuex'
      import subscriptions from '~/components/shared/subscriptions'
      import shared from '~/components/v1/submission/WestpacSecurity1/__shared'
  
      export default {
          components: {},
          mixins: [subscriptions, shared],
          props: {
              data: {
                  type: Object,
                  default: null
              },
              updater: undefined
          },
          data() {
              return {
                  resources: {
                    purchase: {
                        attributes: {
                            lender: {
                                westpac: {
                                    "financedGoodType": null,
                                    "subFinancedGoodType": null,
                                }
                            }
                        }
                    }
                },
                labels: {
                    "financedGoodType": "Finance Goods Types",
                    "subFinancedGoodType": "Motor Vehicle Asset Type"
                },
                validation_rules: {
                    attributes: {
                        "financedGoodType": "required|string",
                        "subFinancedGoodType": "required|string",
                    }
                },
                options: {
                    'financedGoodType': [
                        {value: "Agricultural", text:"Agricultural"},
                        {value: "Aircraft", text:"Aircraft"},
                        {value: "Cleaning", text:"Cleaning"},
                        {value: "EarthMovingMiningAndConstruction", text:"Earth Moving, Mining and Construction"},
                        {value: "FinancialAsset", text:"Financial Asset"},
                        {value: "HospitalityAndLeisure", text:"Hospitality and Leisure"},
                        {value: "ITAndAVEquipment", text:"IT and AV equipment"},
                        {value: "MaterialsHandlingAndLifting", text:"Materials Handling and Lifting"},
                        {value: "Medical", text:"Medical"},
                        {value: "MobileComputing", text:"Mobile Computing"},
                        {value: "MotorVehicleAndTransport", text:"Motor Vehicle and Transport"},
                        {value: "OfficeEquipment", text:"Office Equipment"},
                        {value: "PlantEquipmentAndIndustrial", text:"Plant, Equipment and Industrial"},
                        {value: "ToolsOfTrade", text:"Tools of Trade"},
                        {value: "Other", text:"Other"},
                    ],
                    'subFinancedGoodType': {
                        "Agricultural": [
                            {value: "AllTerrainVehicle", text: "All Terrain Vehicle" },
                            {value: "CropsHay", text: "Crops / Hay" },
                            {value: "Fencing", text: "Fencing" },
                            {value: "FertiliserSlurry", text: "Fertiliser / Slurry" },
                            {value: "GPSLaserLevelling", text: "GPS / Laser / Levelling" },
                            {value: "HandlingStorage", text: "Handling / Storage" },
                            {value: "HarvesterHeader", text: "Harvester / Header" },
                            {value: "Irrigation", text: "Irrigation" },
                            {value: "Livestock", text: "Livestock" },
                            {value: "SeedingPlanting", text: "Seeding / Planting" },
                            {value: "Sprayer", text: "Sprayer" },
                            {value: "StorageAgricultural", text: "Storage" },
                            {value: "TillageEquipment", text: "Tillage Equipment" },
                            {value: "TractorWheeled", text: "Tractor - Wheeled" },
                            {value: "TractorFrontAttachments", text: "Tractor Front Attachments" },
                            {value: "OtherAgricultural", text: "Other" },
                        ],
                        "Aircraft": [
                           { value:"Engine", text:"Engine"},
                           { value:"FixedWingCylinder", text:"Fixed Wing - Cylinder"},
                           { value:"FixedWingJet", text:"Fixed Wing - Jet"},
                           { value:"FixedWingTurboProp", text:"Fixed Wing - Turbo Prop"},
                           { value:"Helicopter", text:"Helicopter"},
                           { value:"UltraLight", text:"Ultra Light"},
                           { value:"OtherAircraft", text:"Other"},
                        ],
                        "Cleaning": [
                           { value:"DryCleaning", text:"Dry Cleaning"},
                           { value:"WashersAndDryers", text:"Washers and Dryers"},
                           { value:"VacuumEquipment", text:"Vacuum Equipment"},
                           { value:"WasteBins", text:"Waste Bins"},
                           { value:"OtherCleaning", text:"Other"},
                        ],
                        "EarthMovingMiningAndConstruction": [
                           { value:"ConcreteEquipment", text:"Concrete Equipment"},
                           { value:"Conveyor", text:"Conveyor"},
                           { value:"CrushingScreening", text:"Crushing / Screening"},
                           { value:"Dozer", text:"Dozer"},
                           { value:"DrillRig", text:"Drill Rig"},
                           { value:"ExcavatorFaceShovel", text:"Excavator / Face Shovel"},
                           { value:"Grader", text:"Grader"},
                           { value:"Loader", text:"Loader"},
                           { value:"RollerCompacting", text:"Roller / Compacting"},
                           { value:"Scraper", text:"Scraper"},
                           { value:"OffHighwayTruck", text:"Off Highway Truck"},
                           { value:"Forestry", text:"Forestry"},
                           { value:"RoadMaintenance", text:"Road Maintenance"},
                           { value:"Trenching", text:"Trenching"},
                           { value:"SweepingCleaning", text:"Sweeping / Cleaning"},
                           { value:"OtherEarthMovingMiningAndConstruction", text:"Other"},
                        ],
                        "FinancialAsset": [
                           { value:"Cash", text:"Cash"},
                           { value:"Investment", text:"Investment"},
                           { value:"ManagedFunds", text:"Managed Funds"},
                           { value:"PensionAccount", text:"Pension Account"},
                           { value:"Receiveables", text:"Receiveables"},
                           { value:"SharesAndDebentures", text:"Shares and Debentures"},
                           { value:"Superannuation", text:"Superannuation"},
                           { value:"TermDeposit", text:"Term Deposit"},
                           { value:"TransactionAccount", text:"Transaction Account"},
                           { value:"OtherFinancialAsset", text:"Other"},
                        ],
                        "HospitalityAndLeisure": [
                           { value:"Spa", text:"Spa"},
                           { value:"Salon", text:"Salon"},
                           { value:"Kitchen", text:"Kitchen"},
                           { value:"Gaming", text:"Gaming"},
                           { value:"OtherHospitalityAndLeisure", text:"Other"},
                        ],
                        "ITAndAVEquipment": [
                           { value:"ClientComputing", text:"Client Computing"},
                           { value:"Peripherals", text:"Peripherals"},
                           { value:"NetworkInfrastructure", text:"Network Infrastructure"},
                           { value:"SecurityHardwareAndAppliances", text:"Security Hardware and Appliances"},
                           { value:"ServerComputing", text:"Server Computing"},
                           { value:"StorageInfrastructure", text:"Storage Infrastructure"},
                           { value:"AudioVisual", text:"Audio Visual"},
                           { value:"Software", text:"Software"},
                           { value:"PointOfSaleSystem", text:"Point of Sale System"},
                           { value:"SurveillanceEquipment", text:"Surveillance Equipment"},
                           { value:"OtherITAndAVEquipment", text:"Other"},
                        ],
                        "MaterialsHandlingAndLifting": [
                           { value:"Forklift", text:"Forklift"},
                           { value:"Crane", text:"Crane"},
                           { value:"LiftElevator", text:"Lift / Elevator"},
                           { value:"JackLifting", text:"Jack / Lifting"},
                           { value:"ScissorLift", text:"Scissor Lift"},
                           { value:"StorageMaterialsHandlingAndLifting", text:"Storage"},
                           { value:"TelescopicHandler", text:"Telescopic Handler"},
                           { value:"OtherMaterialsHandlingAndLifting", text:"Other"},
                        ],
                        "Medical": [
                           { value:"DiagnosticEquipment", text:"Diagnostic Equipment"},
                           { value:"TherapySystems", text:"Therapy Systems"},
                           { value:"LaboratoryEquipment", text:"Laboratory Equipment"},
                           { value:"ImagingEquipment", text:"Imaging Equipment"},
                           { value:"OtherMedical", text:"Other"},
                        ],
                        "MobileComputing": [
                           { value:"SmartPhone", text:"Smart Phone"},
                           { value:"Tablet", text:"Tablet"},
                           { value:"OtherMobileComputing", text:"Other"},
                        ],
                        "MotorVehicleAndTransport": [
                           { value:"Car", text:"Car"},
                           { value:"TaxiHireCar", text:"Taxi / Hire Car"},
                           { value:"LightCommercial", text:"Light Commercial"},
                           { value:"Truck", text:"Truck"},
                           { value:"Motorcycle", text:"Motorcycle"},
                           { value:"Watercraft", text:"Watercraft"},
                           { value:"Bicycle", text:"Bicycle"},
                           { value:"BusCoachOrSpecialPurpose", text:"Bus / Coach"},
                           { value:"Caravan", text:"Caravan"},
                           { value:"Trailer", text:"Trailer"},
                           { value:"Bodies", text:"Bodies"},
                           { value:"Locomotive", text:"Locomotive"},
                           { value:"RailCars", text:"Rail Cars"},
                           { value:"OtherMotorVehicleAndTransport", text:"Other"},
                        ],
                        "OfficeEquipment": [
                           { value:"FixturesAndFittings", text:"Fixtures and Fittings"},
                           { value:"PhotocopierPrinter", text:"Photocopier / Printer"},
                           { value:"TelephoneSystems", text:"Telephone Systems"},
                           { value:"OtherOfficeEquipment", text:"Other"},
                        ],
                        "PlantEquipmentAndIndustrial": [
                           { value:"HeatingAndCooling", text:"Heating and Cooling"},
                           { value:"ArboristAndLandscapingEquipment", text:"Arborist and Landscaping Equipment"},
                           { value:"ButchersPlant", text:"Butchers Plant"},
                           { value:"ManufacturingAndMachining", text:"Manufacturing and Machining"},
                           { value:"Cleaning", text:"Cleaning"},
                           { value:"Compressors", text:"Compressors"},
                           { value:"EngineeringMachine", text:"Engineering Machine"},
                           { value:"EnginePlantEquipmentAndIndustrial", text:"Engine"},
                           { value:"Generator", text:"Generator"},
                           { value:"HotWaterPlant", text:"Hot Water Plant"},
                           { value:"LightingPlant", text:"Lighting Plant"},
                           { value:"PhotoProcessPlant", text:"Photo Process Plant"},
                           { value:"PrintingPress", text:"Printing Press"},
                           { value:"Pump", text:"Pump"},
                           { value:"Fitout", text:"Fitout"},
                           { value:"SewingMachine", text:"Sewing Machine"},
                           { value:"WoodWorkingPlant", text:"Wood Working Plant"},
                           { value:"PowerMeteringEquipment", text:"Power Metering Equipment"},
                           { value:"SolarRooftopPV", text:"Solar Rooftop PV"},
                           { value:"SolarDCInverters", text:"Solar DC Inverters"},
                           { value:"OtherEnergyEfficientEquipment", text:"Other Energy Efficient Equipment"},
                           { value:"OtherPlantEquipmentAndIndustrial", text:"Other"},
                        ],
                        "ToolsOfTrade": [
                           { value:"Camera", text:"Camera"},
                           { value:"PowerTools", text:"Power Tools"},
                           { value:"SurveyingEquipment", text:"Surveying Equipment"},
                           { value:"ToolsLaserBeam", text:"Tools - Laser Beam"},
                           { value:"ToolsGeneral", text:"Tools General"},
                        ],
                        "Other": [
                           { value:"ArtWorks", text:"Art Works"},
                           { value:"HomeContents", text:"Home Contents"},
                           { value:"IntellectualProperty", text:"Intellectual Property"},
                           { value:"GoodWillOfBusiness", text:"Good Will of Business"},
                           { value:"StockAndInventory", text:"Stock and Inventory"},
                           { value:"OtherOther", text:"Other"},
                        ],
                        "ExistLoan": [
                            {value: "Car", text: "Car"},
                            {value: "TaxiHireCar", text: "Taxi / Hire Car"},
                            {value: "LightCommercial", text: "Light Commercial"},
                            {value: "Truck", text: "Truck"},
                            {value: "Motorcycle", text: "Motorcycle"},
                            {value: "Watercraft", text: "Watercraft"},
                            {value: "Bicycle", text: "Bicycle"},
                            {value: "BusCoachOrSpecialPurpose", text: "Bus / Coach"},
                            {value: "Caravan", text: "Caravan"},
                            {value: "Trailer", text: "Trailer"},
                            {value: "Bodies", text: "Bodies"},
                            {value: "Locomotive", text: "Locomotive"},
                            {value: "RailCars", text: "Rail Cars"},
                            {value: "OtherMotorVehicleAndTransport", text: "Other"},
                        ],
                    }
                },
                  visible: {
                      "industry": false,
                      "occupation": true
                  },
              }
          },
          computed: {
                vb() {
                    return this.$vuetify.breakpoint
                },
                ...mapGetters('slug', [
                    'getFlowSlug'
                ]),
                getLenderName(){
                    return this.getFlowSlug.includes('westpac') ? 'Westpac' : 'Capital'
                },
                getLenderLogo(){
                    return this.getFlowSlug.includes('westpac') ? 'westpac' : 'capital'
                }
          },
          mounted() {
              this.updateResourceFields()
          },
          methods: {
              ...mapActions('resource-actions', [
                  'getPurchase',
              ]),
                async validate() {
                    const valid = await this.$refs.observer.validate();

                    if (valid) {
                        this.$emit("submit", this.resources);
                    }
                },
                async updateResourceFields() {

                    this.$emit("loading", true);

                    const { data } = this;

                    if (data) {

                        let purchaseRes = await this.getPurchase(data.id);

                        if(purchaseRes.status == 200) {
                            this.resources.purchase = purchaseRes.data.data
                        }

                        this.$emit("loading", false);

                    } else {
                        this.$emit("loading", false);
                    }
                },
          },
        watch: {
            updater(val) {
                this.updateResourceFields();
            },
        },
      }
  </script>
  
  <style lang="scss" scoped>
  </style>
  