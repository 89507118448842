export default {
    steps: {
        "Company": {
            "business": {
                "data": null,
                "component-type": "business-information",
                "title": "Company Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "company-information",
                "form-fields": [
                    "industry",
                    "companyType"
                ]
            },
            "address": {
                "data": null,
                "component-type": "address",
                "title": "Company Address",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "company-address",
                "form-fields": [
                    "addressType",
                    "businessPremisesStatus",
                    "street-type"
                ]
            }
        },
        "Director": {
            "incomes": {
                "data": null,
                "component-type": "person-information",
                "title": "Director Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "director-information",
                "form-fields": [
                    "currentOccupation"
                ]
            },
            "address": {
                "data": null,
                "component-type": "address",
                "title": "Address Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "director-address",
                "form-fields": [
                    "addressType",
                    "businessPremisesStatus",
                    "street-type"
                ]
            }
        },
        "Individual": {
            "incomes": {
                "data": null,
                "component-type": "person-information",
                "title": "Individual Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "individual-information",
                "form-fields": [
                    "currentOccupation"
                ]
            },
            "address": {
                "data": null,
                "component-type": "address",
                "title": "Address Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "individual-address",
                "form-fields": [
                    "addressType",
                    "businessPremisesStatus",
                    "street-type"
                ]
            }
        },
        "Partner": {
            "incomes": {
                "data": null,
                "component-type": "person-information",
                "title": "Individual Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "individual-information",
                "form-fields": [
                    "currentOccupation"
                ]
            },
            "address": {
                "data": null,
                "component-type": "address",
                "title": "Address Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "individual-address",
                "form-fields": [
                    "addressType",
                    "businessPremisesStatus",
                    "street-type"
                ]
            }
        },
        "Trust": {
            "business": {
                "data": null,
                "component-type": "business-information",
                "title": "Trust Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "trust-information",
                "form-fields": [
                    "trustType",
                    "industry"
                ]
            },
            "address": {
                "data": null,
                "component-type": "address",
                "title": "Trust Address",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "trust-address",
                "form-fields": [
                    "addressType",
                    "businessPremisesStatus"
                ]
            }
        },
        "Trustee": {
            "business": {
                "data": null,
                "component-type": "business-information",
                "title": "Trustee Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "trustee-information",
                "form-fields": [
                    "trustType",
                    "industry"
                ]
            },
            "incomes": {
                "data": null,
                "component-type": "person-information",
                "title": "Trustee Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "trustee-information",
                "form-fields": [
                    "currentOccupation"
                ]
            },
            "address": {
                "data": null,
                "component-type": "address",
                "title": "Trustee Address",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "trustee-address",
                "form-fields": [
                    "addressType",
                    "businessPremisesStatus"
                ]
            }
        },
        "Guarantor": {
            "incomes": {
                "data": null,
                "component-type": "person-information",
                "title": "Guarantor Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "guarantor-information",
                "form-fields": [
                    "currentOccupation"
                ]
            }, 
            "address": {
                "data": null,
                "component-type": "address",
                "title": "Guarantor Address",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "guarantor-address",
                "form-fields": [
                    "addressType",
                    "businessPremisesStatus"
                ]
            }
        },
        "Beneficial Owner": {
            "incomes": {
                "data": null,
                "component-type": "person-information",
                "title": "Beneficial Owner Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "beneficial-owner-information",
                "form-fields": [
                    "currentOccupation"
                ]
            },
            "business": {
                "data": null,
                "component-type": "business-information",
                "title": "Beneficial Owner Information",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "company-information",
                "form-fields": [
                    "trustType",
                    "industry"
                ]
            },
            "address": {
                "data": null,
                "component-type": "address",
                "title": "Beneficial Owner Address",
                "subtitle":"Please use the Nodifi Data to answer the questions in the Requirements",
                "form-ref": "beneficial-owner-address",
                "form-fields": [
                    "addressType",
                    "businessPremisesStatus"
                ]
            }
        },
    }
}