import { isEqual } from 'lodash'
import {stringify} from 'qs'

/**
 * Note: We have a middleware that fetch the app config
 *       And reads url for current step
 */

// initial state
export const  state = () => ({

    showComplianceModal: false,

    /**
     * Calculation includes
     *  - application type
     *  - loan type
     *  - primary applicant and more...
     */
    calculation: null,

    /**
     * App type for FE use
     * includeds friendly name app type
     */
    primaryApplicant: null, 
    appTypeFE: null, 

    appId: null, 
    appGuid: null,
    appData: null,
    loanData: null,
    
    flowId: null, 
    flowData: null, 

    steps: null,
    config: null,

    spaces: null,
    layout: 'FullWidth1', //  LeftSideBar1 | RightSideBar1 | DualSideBar1,

    activeStep: "1", // Page active step

    appDataWatcher: 0, // Date.now() 
    redirectPaths: null,
    flowTags: null,

    //  Box Data
    boxData: null,

    expand: true,

    record: null,  // {type: <entity>, id: <id>}
    // submit selection
    lenderSubmissionTo: '',

    // Term slug
    termGroup: null,
    commitments: null,


    // application owner & group data
    appOwnerData: null, 
    appGroupData: null,
})


// getters
export const  getters = {

    getAppTypeFE(state){
        return state.appTypeFE
    },

    getConfig(state){
        return state.config
    },

    getParentFlow(state){

        if(state.config?.flow.hasOwnProperty('parent-flow')) {
            return state.config?.flow['parent-flow']
        }

        return null

    },
    getSteps(state){
        const {steps} = state
        return steps ? steps : []
    },
    getActiveStep(state){
        return state.activeStep
    },
    getActiveStepData(state){
        const {steps, activeStep} = state
        return steps ? steps[parseInt(activeStep) - 1] : null
    },
    getNextStep: (state, getters, rootState) => {
        const {steps, activeStep} = state
        const { stepStatuses } = rootState.statuses
        let nextStep = parseInt(activeStep) + 1
        const activeStepIndex = parseInt(activeStep) - 1
        let shouldSkip = false // foreach stopper
        
        // logic for next steps that is included
        stepStatuses.forEach((status, index) => {

            if(index > activeStepIndex && !shouldSkip) {
                if(status.attributes.included === 1) {
                    nextStep = index + 1
                    shouldSkip = true
                }
            }

        })

        if(steps && steps.length == parseInt(activeStep)) return null;

        return nextStep
    },
    getPrevStep(state){
        const {activeStep} = state
        if(1 == activeStep) return null;
        return parseInt(activeStep) - 1
    },
    /**
     * Helper: spaces array to object.
     */
    getSpacesObj({spaces}){

        let obj = {}

        if(spaces) {

            spaces.forEach(space => {
                obj[space.name] = {
                    ...space
                }
            });
            
        }
        
        return obj;
    },
    redirectPaths(state){
        return state.redirectPaths
    },
    getCalculation(state){
        return state.calculation
    }
    
}

// actions
export const actions = {
    fetchAppData({commit, getters, dispatch, rootState}){
        return this.$api.get(`applications/${rootState.flows.appId}`)
    },
    async saveAndExit({commit, getters, dispatch, rootState}){

        const {stepablePush, isStepable, isStepableDataWorkable} = rootState.subscriptions
        /**
         * #1 Set hard validation to false
         * 
         */
        commit('subscriptions/setHardValidation', true, { root: true })

        if(isStepable && isStepableDataWorkable){

            commit('subscriptions/setGlobalMode', 'disabled', { root: true })
            const {valid, status, data = {}}  = await stepablePush();
            commit('subscriptions/setGlobalMode', 'enabled', { root: true })

            dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
                commit('statuses/updateStepStatus', res, { root: true })
            })

            /**
             * Update Store AppData from FE
             * To update Appable components instantly.
             */
            commit('updateAppData', data)
            
            // Update Store AppData from DB 
            dispatch('fetchAppData').then(res => {
                commit('updateAppData', res.data.data)
            })

        } 

    },
    async selectNextStep({commit, getters, dispatch, rootState}, payload){

        const {stepablePush, isStepable, isStepableDataWorkable, validation} = rootState.subscriptions

        /**
         * #1 Set hard validation to false if hardValidation is ignored, see #2
         * 
         */
        commit('subscriptions/setHardValidation', true, { root: true })

        if(isStepable && isStepableDataWorkable && validation){
            
            commit('subscriptions/setGlobalMode', 'disabled', { root: true })
            const {valid, status, data = {}}  = await stepablePush();

            if(data.hasOwnProperty('error')){
                commit('subscriptions/setGlobalMode', 'enabled', { root: true })
                return
            }

            await dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
                commit('statuses/updateStepStatus', res, { root: true })
            })


            /**
             * Updates step statuses 'included', 'enabled'
             */
             await dispatch('statuses/fetchStepStatuses', null, {root: true}).then(res => {
                commit('statuses/setStepStatuses', res, { root: true })
                commit('findNextStep', {data: res, type: 'next'})
            })

            /**
             * Update Store AppData from FE
             * To update Appable components instantly.
             */
            commit('updateAppData', data)
            
            // Update Store AppData from DB 
            dispatch('fetchAppData').then(res => {
                commit('updateAppData', res.data.data)
            })

            /**
             *  #2 Uncomment the if condition if hardValidation is true see #1 above
             */
                
            // TEMP:  see StepIncrementer1
            if(payload == undefined || !payload.hasOwnProperty('goToNext')){
                payload = {goToNext: true, returnStatus: false}
            }

            if (valid) {

                await dispatch('statuses/updateFlowStatus', getters.getNextStep, {root: true}).then(res => {
                    commit('statuses/updateFlowStatus', res, { root: true })
                })

                commit('subscriptions/setGlobalMode', 'enabled', { root: true })

                if(payload.goToNext){

                    let route = {
                        name: process.env.flowRouteName,
                        params: {
                            group_slug: rootState.slug.groupSlug, 
                            flow_slug: rootState.slug.flowSlug, 
                            steps: `${getters.getNextStep}`, 
                            app_guid: rootState.flows.appGuid
                        }
                    }
                    

                    if(rootState.flows.record) {
                        route.name = process.env.flowRouteSubFlowName
                        route.params.record = rootState.flows.record.type
                        route.params.record_id = rootState.flows.record.id
                    }


                    this.$router.push(route)

                }

            } else {

                commit('subscriptions/setGlobalMode', 'enabled', { root: true })
            }

            if(payload.returnStatus){
                return {valid, status}
            }

        } else {

            dispatch('statuses/updateFlowStatus', getters.getNextStep, {root: true}).then(res => {
                commit('statuses/updateFlowStatus', res, { root: true })
            })

            let route = {
                name: process.env.flowRouteName,
                params: {
                    group_slug: rootState.slug.groupSlug, 
                    flow_slug: rootState.slug.flowSlug, 
                    steps: `${getters.getNextStep}`, 
                    app_guid: rootState.flows.appGuid
                }
            }


            if(rootState.flows.record) {
                route.name = process.env.flowRouteSubFlowName
                route.params.record = rootState.flows.record.type
                route.params.record_id = rootState.flows.record.id
            }

            this.$router.push(route)

        }
        
    },
    async selectPrevStep({commit, getters, dispatch, rootState}){

        const {stepablePush, isStepable, isStepableDataWorkable, validation} = rootState.subscriptions

        /**
         * #1 Set hard validation to false
         * 
         */
        commit('subscriptions/setHardValidation', false, { root: true })

        if(isStepable && isStepableDataWorkable && validation) {

            commit('subscriptions/setGlobalMode', 'disabled', { root: true })
            const {valid, status, data = {}}  = await stepablePush();
            commit('subscriptions/setGlobalMode', 'enabled', { root: true })

            await dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
                commit('statuses/updateStepStatus', res, { root: true })
            })


            /**
             * Updates step statuses 'included', 'enabled'
             */
             await dispatch('statuses/fetchStepStatuses', null, {root: true}).then(res => {
                commit('statuses/setStepStatuses', res, { root: true })
                commit('findNextStep', {data: res, type: 'prev'})
            })


            /**
             * Update Store AppData from FE
             * To update Appable components instantly.
             */
            commit('updateAppData', data)
            
            // Update Store AppData from DB 
            dispatch('fetchAppData').then(res => {
                commit('updateAppData', res.data.data)
            })

            /**
             *  Un-comment the if condition if hardValidation is true see #1 above
             */
            // if (valid) {

                dispatch('statuses/updateFlowStatus', getters.getPrevStep, {root: true}).then(res => {
                    commit('statuses/updateFlowStatus', res, { root: true })
                })

                let route = {
                    name: process.env.flowRouteName,
                    params: {
                        group_slug: rootState.slug.groupSlug, 
                        flow_slug: rootState.slug.flowSlug, 
                        steps: `${getters.getPrevStep}`, 
                        app_guid: rootState.flows.appGuid
                    }
                }

                if(rootState.flows.record) {
                    route.name = process.env.flowRouteSubFlowName
                    route.params.record = rootState.flows.record.type
                    route.params.record_id = rootState.flows.record.id
                }

                this.$router.push(route)
   

            // }

        } else {

            dispatch('statuses/updateFlowStatus', getters.getPrevStep, {root: true}).then(res => {
                commit('statuses/updateFlowStatus', res, { root: true })
            })

            let route = {
                name: process.env.flowRouteName,
                params: {
                    group_slug: rootState.slug.groupSlug, 
                    flow_slug: rootState.slug.flowSlug, 
                    steps: `${getters.getPrevStep}`, 
                    app_guid: rootState.flows.appGuid
                }
            }

            if(rootState.flows.record) {
                route.name = process.env.flowRouteSubFlowName
                route.params.record = rootState.flows.record.type
                route.params.record_id = rootState.flows.record.id
            }

            this.$router.push(route)
            
        }

    },
    async selectFinalStep({commit, getters, dispatch, rootState}, params = { url: '', flowStatus: ''}) {
        
        const { stepablePush, isStepable, isStepableDataWorkable } = rootState.subscriptions
        
        commit('subscriptions/setHardValidation', true, { root: true })

        let route = {
            name: rootState.slug.flowSlug.includes('b2b') ? process.env.b2bFlowRouteOnComplete : process.env.b2cFlowRouteOnComplete, 
            params: { 
                group_slug: rootState.slug.groupSlug, 
                flow: rootState.slug.flowSlug, 
                application: rootState.flows.appGuid
            } 
        }

        if ( isStepable ) {
            
            commit('subscriptions/setGlobalMode', 'disabled', { root: true })
            const {valid, status, data = {}}  = await stepablePush()
            
            if ( !valid )
                commit('subscriptions/setGlobalMode', 'enabled', { root: true })
            
            await dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
                commit('statuses/updateStepStatus', res, { root: true })
            })

            /**
             * Updates step statuses 'included', 'enabled'
             */
             await dispatch('statuses/fetchStepStatuses', null, {root: true}).then(res => {
                commit('statuses/setStepStatuses', res, { root: true })
            })

            commit('updateAppData', data)
            dispatch('fetchAppData').then(res => {
                commit('updateAppData', res.data.data)
            })
            
            // ---------------------------------------------------------
            // Used 'valid' and 'pristine' status just to complete the flow
            // This is a temporary implementation for now
            // As long as the component throw valid = true,
            // it should call the action to complete the application
            // ---------------------------------------------------------
            if (valid) {

                let validSteps = true

                rootState.statuses.stepStatuses.forEach( step => {

                    if ( step.attributes.value == 'dirty' ) {
                        validSteps = false
                    }

                    if ( step.attributes.value == 'pristine') {
                        if(step.attributes?.included == 1) {
                            validSteps = false
                        }
                    }

                })

                if ( validSteps ) {

                    dispatch('statuses/completeFlowStatus', 
                        {
                            type: 'flow-statuses',
                            id: params.flowStatus,
                            attributes: { completed: 1 }
                        }, 
                        {
                            root: true
                        }
                    ).then( res => {                    
                        
                        commit('statuses/updateFlowStatus', res.data.data, { root: true })
                        
                        if ( params.url ) {
                            this.$router.push({ path: params.url })
                        }
                        else {
                            this.$router.push(route)
                        }

                        commit('subscriptions/setGlobalMode', 'enabled', { root: true })

                    })
                    
                }
            }

        }
        else {
            
            dispatch('statuses/updateFlowStatus', getters.getPrevStep + 1, {root: true}).then(res => {
                commit('statuses/updateFlowStatus', res, { root: true })
            })
        }
        
        
    },
    async forceCompleteApplication({commit, getters, dispatch, rootState}, params) {
        
        const { stepablePush, isStepable, isStepableDataWorkable } = rootState.subscriptions
        
        commit('subscriptions/setHardValidation', true, { root: true })

        let route = {
            name: rootState.slug.flowSlug.includes('b2b') ? process.env.b2bFlowRouteOnComplete : process.env.b2cFlowRouteOnComplete, 
            params: { 
                group_slug: rootState.slug.groupSlug, 
                flow: rootState.slug.flowSlug, 
                application: rootState.flows.appGuid
            } 
        }

            
        commit('subscriptions/setGlobalMode', 'disabled', { root: true })

        const {valid, status, data = {}}  = await stepablePush()
        
        if ( !valid ){
            commit('subscriptions/setGlobalMode', 'enabled', { root: true })
        }
        
        // await dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
        //     commit('statuses/updateStepStatus', res, { root: true })
        // })

        if (valid) {

            // console.log(rootState)

            let flowStatus = rootState.statuses.flowStatus
            const appId = rootState.flows.appId

            await dispatch('resource-actions/updateApplication', 
                {
                    "type": "applications",
                    "id": appId,
                    "attributes": {
                        "status": "Submitted",
                        "target": "Lender",
                    }
                }, 
                {
                    root: true
                }
            )

            dispatch('statuses/completeFlowStatus', 
                {
                    type: 'flow-statuses',
                    id: flowStatus.id,
                    attributes: { completed: 1 }
                }, 
                {
                    root: true
                }
            ).then( res => {                    
                
                commit('statuses/updateFlowStatus', res.data.data, { root: true })
                
                this.$router.push(route)

                commit('subscriptions/setGlobalMode', 'enabled', { root: true })

            })
                
        }

        
    },
    async selectFinalStepNoFlowComplete({commit, getters, dispatch, rootState}, params = { url: '', flowStatus: ''}) {
        
        const { stepablePush, isStepable, isStepableDataWorkable } = rootState.subscriptions
        
        commit('subscriptions/setHardValidation', true, { root: true })

        let route = {
            name: rootState.slug.flowSlug.includes('b2b') ? process.env.b2bFlowRouteOnComplete : process.env.b2cFlowRouteOnComplete, 
            params: { 
                group_slug: rootState.slug.groupSlug, 
                flow: rootState.slug.flowSlug, 
                application: rootState.flows.appGuid
            } 
        }

        if ( isStepable ) {
            
            commit('subscriptions/setGlobalMode', 'disabled', { root: true })
            const {valid, status, data = {}}  = await stepablePush()
            
            if ( !valid )
                commit('subscriptions/setGlobalMode', 'enabled', { root: true })
            
            await dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
                commit('statuses/updateStepStatus', res, { root: true })
            })

            /**
             * Updates step statuses 'included', 'enabled'
             */
             await dispatch('statuses/fetchStepStatuses', null, {root: true}).then(res => {
                commit('statuses/setStepStatuses', res, { root: true })
            })

            commit('updateAppData', data)
            dispatch('fetchAppData').then(res => {
                commit('updateAppData', res.data.data)
            })
            
            if (valid) {

                let validSteps = true

                rootState.statuses.stepStatuses.forEach( step => {

                    if(step.attributes?.included == 1) {

                        if ( step.attributes.value == 'dirty' ) {
                            validSteps = false
                        }
    
                        if ( step.attributes.value == 'pristine') {
                            validSteps = false
                        }
                    }

                })

                if ( validSteps ) {

                    if ( params.url ) {
                        this.$router.push({ path: params.url })
                    }
                    else {
                        this.$router.push(route)
                    }
                    
                }
            }

        }
        else {
            
            dispatch('statuses/updateFlowStatus', getters.getPrevStep + 1, {root: true}).then(res => {
                commit('statuses/updateFlowStatus', res, { root: true })
            })
        }
        
    },
    async selectStep({commit, getters, dispatch, rootState}, payload){
        
        const {stepablePush, isStepable, isStepableDataWorkable, validation} = rootState.subscriptions
        commit('subscriptions/setHardValidation', false, { root: true })
        
        let route = {
                    name: process.env.flowRouteName, 
                    params: { 
                        group_slug: rootState.slug.groupSlug, 
                        flow_slug: rootState.slug.flowSlug, 
                        steps: `${payload}`, 
                        app_guid: rootState.flows.appGuid
                    } 
                }

            if(rootState.flows.record) {
                route.name = process.env.flowRouteSubFlowName
                route.params.record = rootState.flows.record.type
                route.params.record_id = rootState.flows.record.id
            }


        if(isStepable && isStepableDataWorkable && validation){

            commit('subscriptions/setGlobalMode', 'disabled', { root: true })
            const {valid, status, data = {}} = await stepablePush();

            await dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
                commit('statuses/updateStepStatus', res, { root: true })
            })

            /**
             * Updates step statuses 'included', 'enabled'
             */
             await dispatch('statuses/fetchStepStatuses', null, {root: true}).then(res => {
                commit('statuses/setStepStatuses', res, { root: true })
            })


            /**
             * Update Store AppData from FE
             * To update Appable components instantly.
             */
            commit('updateAppData', data)
            
            // Update Store AppData from DB 
            dispatch('fetchAppData').then(res => {
                commit('updateAppData', res.data.data)
            })

            dispatch('statuses/updateFlowStatus', `${payload}`, {root: true}).then(res => {
                commit('statuses/updateFlowStatus', res, { root: true })
            })
            
            this.$router.push(route)

        } else {

            dispatch('statuses/updateFlowStatus', `${payload}`, {root: true}).then(res => {
                commit('statuses/updateFlowStatus', res, { root: true })
            })
            
            this.$router.push(route)
        }

    },
    // Well refactor this in the future, also see statuses/updateFlowStatusOL
    async selectNextStepOL({commit, getters, dispatch, rootState}, payload){
        
        const {stepablePush, isStepable, isStepableDataWorkable} = rootState.subscriptions

        /**
         * #1 Set hard validation to false if hardValidation is ignored, see #2
         * 
         */
        commit('subscriptions/setHardValidation', true, { root: true })

        if(isStepable && isStepableDataWorkable){
            
            commit('subscriptions/setGlobalMode', 'disabled', { root: true })
            const {valid, status, data = {}}  = await stepablePush();

            if(data.hasOwnProperty('error')){
                commit('subscriptions/setGlobalMode', 'enabled', { root: true })
                return
            }

            await dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
                commit('statuses/updateStepStatus', res, { root: true })
            })


            /**
             * Updates step statuses 'included', 'enabled'
             */
             await dispatch('statuses/fetchStepStatuses', null, {root: true}).then(res => {
                commit('statuses/setStepStatuses', res, { root: true })
                commit('findNextStep', {data: res, type: 'next'})
            })

            /**
             * Update Store AppData from FE
             * To update Appable components instantly.
             */
            commit('updateAppData', data)
            
            // Update Store AppData from DB 
            dispatch('fetchAppData').then(res => {
                commit('updateAppData', res.data.data)
            })

            /**
             *  #2 Uncomment the if condition if hardValidation is true see #1 above
             */
                
            // TEMP:  see StepIncrementer1
            if(payload == undefined || !payload.hasOwnProperty('goToNext')){
                payload = {goToNext: true, returnStatus: false, params: null}
            }

            if (valid) {

                await dispatch('statuses/updateFlowStatusOL', {step: getters.getNextStep, params: payload.params}, {root: true}).then(res => {
                    commit('statuses/updateFlowStatus', res, { root: true })
                })

                commit('subscriptions/setGlobalMode', 'enabled', { root: true })

                if(payload.goToNext){

                    this.$router.push({
                        name: process.env.flowRouteName, 
                        params: { 
                            group_slug: rootState.slug.groupSlug, 
                            flow_slug: rootState.slug.flowSlug, 
                            steps: `${getters.getNextStep}`, 
                            app_guid: rootState.flows.appGuid
                        } 
                    })

                }

            } else {

                commit('subscriptions/setGlobalMode', 'enabled', { root: true })
            }

            if(payload.returnStatus){
                return {valid, status}
            }

        } else {

            dispatch('statuses/updateFlowStatus', getters.getNextStep, {root: true}).then(res => {
                commit('statuses/updateFlowStatus', res, { root: true })
            })
            
            this.$router.push({
                name: process.env.flowRouteName, 
                params: { 
                    group_slug: rootState.slug.groupSlug, 
                    flow_slug: rootState.slug.flowSlug, 
                    steps: `${getters.getNextStep}`, 
                    app_guid: rootState.flows.appGuid
                } 
            })

        }
        
    },
    async saveDataOnly({commit, dispatch, rootState}) {

        const {stepablePush} = rootState.subscriptions

        const {data = {}, status}  = await stepablePush()

        /**
         * Update Store AppData from FE
         * To update Appable components instantly.
         */
        commit('updateAppData', data)
        
        // Update Store AppData from DB 
        dispatch('fetchAppData').then(res => {
            commit('updateAppData', res.data.data)
        })

    },
    async saveDataAndStepStatusOnly({commit, dispatch, rootState}) {

        const {stepablePush} = rootState.subscriptions

        const {data = {}, status}  = await stepablePush()

        await dispatch('statuses/updateStepStatus', `${status}`, {root: true}).then(res => {
            commit('statuses/updateStepStatus', res, { root: true })
        })

        /**
         * Update Store AppData from FE
         * To update Appable components instantly.
         */
        commit('updateAppData', data)
        
        // Update Store AppData from DB 
        dispatch('fetchAppData').then(res => {
            commit('updateAppData', res.data.data)
        })

        return status

    },
    selectCollapseLeftSideBarSpace({commit}){
        commit('setup/toggleSideBarSpace', ['LeftSideBar', 'collapsed'], { root: true })
    },
    selectExpandLeftSideBarSpace({commit}){
        commit('setup/toggleSideBarSpace', ['LeftSideBar', 'expanded'], { root: true })
    },
    selectMinimiseLeftSideBarSpace({commit}){
        commit('setup/toggleSideBarSpace', ['LeftSideBar', 'mini'], { root: true })
    },
    selectCollapseRightSideBarSpace({commit}){
        commit('setup/toggleSideBarSpace', ['RightSideBar', 'collapsed'], { root: true })
    },
    selectExpandRightSideBarSpace({commit}){
        commit('setup/toggleSideBarSpace', ['RightSideBar', 'expanded'], { root: true })
    },
    selectMinimiseRightSideBarSpace({commit}){
        commit('setup/toggleSideBarSpace', ['RightSideBar', 'mini'], { root: true })
    },
    async checkStepsListeners({rootState, commit, getters, context}) {

        const { activeStep, steps, appGuid } = rootState.flows
        const { flowSlug, groupSlug } = rootState.slug

        commit('subscriptions/setGlobalMode', 'disabled', { root: true })

        let redirectUrl = null

        // get the current step
        let currentStep = steps[parseInt(activeStep) - 1]

        // check for listeners in step
        if(currentStep && currentStep.listeners && currentStep.listeners.length > 0) {
            currentStep.listeners.forEach(listener => {

                if(listener.hasOwnProperty('event') && listener.hasOwnProperty('method') ) {
                    if(listener.event.name == 'steps.beforeLoad' && listener.method.name == 'flexRedirect') {
                        redirectUrl = listener.method.arguments.url
                    }
                }
                
            })
        }

        // if has url
        if(redirectUrl) {

            const parsedUrl = await this.$flexConfigStringParser(redirectUrl, {
                config: getters.getConfig,
                route: {
                    groupSlug: groupSlug,
                    flowSlug: flowSlug,
                    applicationGuid: appGuid,
                },
                data: null
            })

            if(parsedUrl !== redirectUrl) {

                commit('flows/setAppID', null, { root: true })

                // window.location.href = parsedUrl    
                this.$router.push({ path: parsedUrl })

            } else {

                commit('subscriptions/setGlobalMode', 'enabled', { root: true })
            }

        } else {
            commit('subscriptions/setGlobalMode', 'enabled', { root: true })
        }
    },
    resetFlowData({commit, getters, dispatch, rootState}){
        commit('resetFlow')
        commit('subscriptions/resetSubscriptions', null , { root: true })
        commit('requirements-checklist/reset', null , { root: true })
    },
    showComplianceModalAction({commit, getters, dispatch, rootState}, payload) {

        // logic for hybrid options 

        const {tenant} = rootState.slug
        const {applicationGroup} = rootState.groups

        if( rootState.appConfig.complianceTenants.includes(tenant) ){

            // if flow is assister edit or view flow don't show the compliance modal
            // if b2c-consumer-1 don't show compliance modal

            let flowSlugs = [
                'assister-view',
                'assister-edit',
                'b2c-consumer-1'
            ]

            if( 
                flowSlugs.some(slug => rootState.slug.flowSlug.includes(slug)) || 
                rootState.slug.flowSlug.includes('b2c-') || 
                applicationGroup?.attributes?.crm === "nodificrm"
            ) {
                commit('setComplianceModal', false)
            } else if(payload && payload.attributes['hybrid-tnf'] === null) {
                commit('setComplianceModal', true)
            }

        }

    }
}

// mutations
export const  mutations = {

    // Flow
    setFlowID (state, payload) {
        state.flowId = payload
    },
    setFlowData (state, payload) {
        state.flowData = payload
    },
    setStep (state, payload) {
        state.activeStep = payload
    },
    setConfigData(state, payload) {
        state.config = payload
    },
    setStepsData (state, payload) {
        state.steps = payload
    },
    findNextStep(state, payload){

        const {steps, activeStep} = state
        const {type, data} = payload

        // console.log('From Step: ', activeStep)

        if(type == 'next') {
            let excludedStepCount = 0

            for (let i = 0; i < steps.length; i++) {

                const step = steps[i];
                
                if(i > parseInt(activeStep) - 1){
    
                    let status = data.find(stat => {
                        return stat.relationships.step.data.id == step.id
                    })

                    if(status && status.attributes.included == 1) {

                        // console.log(`Next step-${step.id}`)
                        // console.log(`Next status-${status.id}`, status.attributes)
                        // console.log('Next Destination Step : ', i + 1)
    
                        state.activeStep = `${i - excludedStepCount}` // minus excluded steps
                        break;

                    } else {
                        // count the excluded step statuses
                        excludedStepCount += 1

                    }
    
                }
    
            }

        } else {

            for (let i = steps.length - 1; i >= 0; i--) {

                const step = steps[i];

                if(i < parseInt(activeStep) - 1){
    
                    let status = data.find(stat => {
                        return stat.relationships.step.data.id == step.id
                    })

                    if(status && status.attributes.included == 1) {

                        // console.log(`Back step-${step.id}`)
                        // console.log(`Back status-${status.id}`, status.attributes)
                        // console.log('Back Destination Step : ', i + 1)
    
                        state.activeStep = `${i+2}`

                        break;
                    }
    
                }
    
            }
        }


    },
    setLayout (state, payload = null) {
        const {steps, activeStep} = state
        if(payload) {
            state.layout = payload
        } else {
            state.layout = steps[parseInt(activeStep) - 1].layout.name
        }
    },
    setSpaces (state) {
        const {steps, activeStep} = state
        state.spaces = steps[parseInt(activeStep) - 1].layout.spaces
    },

    // Application
    setAppData (state, payload) {
        state.appData = payload
    },
    setLoanData (state, payload) {
        state.loanData = payload
    },
    setAppID (state, payload) {
        state.appId = payload
    },
    setAppGuid(state, payload) {
        state.appGuid = payload
    },
    updateAppData(state, payload){


        const {appData} = state

        // Update AppData from DB
        if(payload.hasOwnProperty('id')) {

            let a = appData.relationships
            let b = payload.relationships

            // console.log('fetch appData and store appData are equal: ', isEqual(a, b))

            if(!isEqual(a, b)) {
                state.appData.relationships = payload.relationships
                state.appDataWatcher = Date.now();
            }

            return;
        } 

        // Update AppData from FE
        for (const [key, source] of Object.entries(payload)) {

            // Original AppData resource
            let target = appData.relationships[key]
            let newData = null;

            /**
             *  Check if target.data is Object Ex: Loan
             *  Do nothing for now.
             */
            if( target.data != null && target.data.hasOwnProperty('type') ) {
                continue
            }

            if( source.method == 'post' ) {

                delete source.method

                if(target == null){

                    newData = source;

                } else {

                    newData = [...target.data, source]; // new data at the end
                }

                state.appData.relationships[key].data = newData
                state.appDataWatcher = Date.now();

                continue
            }

            /** Do nothing if Patch for now */
            if( source.method == 'patch' ) {
                continue
            }

            if( source.method == 'delete' ) {

                delete source.method

                if(target.data.hasOwnProperty('type')){
                    
                } else {

                    newData = target.data.filter( data => {
                        return data.id != source.id
                    })
                }

                state.appData.relationships[key].data = newData
                state.appDataWatcher = Date.now();

                continue
            }
 
        }

    },

    setRedirectPaths(state, data) {
        state.redirectPaths = data
    },

    setFlowTags(state, data) {
        state.flowTags = data
    },

    setCalculation (state, payload) {
        state.calculation = payload
    },
    setAppTypeFE (state, payload) {
        state.appTypeFE = payload
    },
    setPrimaryApplicant(state, payload) {
        state.primaryApplicant = payload
    },
    setBoxData (state, payload) {
        state.boxData = payload
    },
    setAppOwnerData (state,payload) {
        state.appOwnerData = payload
    },
    setAppGroupData (state,payload) {
        state.appGroupData = payload
    },
    setExpand (state,payload) {
        state.expand = !payload
    },
    setLenderSubmissionTo (state, payload) {
        state.lenderSubmissionTo = payload
    },
    setTermGroup (state, payload) {
        state.termGroup = payload
    },
    setCommitments (state, payload) {
        state.commitments = payload
    },
    resetFlow(state) {

        state.calculation = null
        state.appTypeFE = null
        state.primaryApplicant = null

        state.appId = null 
        state.appGuid = null
        state.appData = null        
        state.flowId = null 

        state.steps = null
        state.record = null
        
        state.spaces = null
        state.appOwnerData = null
        state.appGroupData = null

        state.termGroup = null
        state.commitments = null

    },
    //
    setRecords(state, payload) {
        state.record = payload
    },

    setComplianceModal(state, payload) {
        state.showComplianceModal = payload
    }
}