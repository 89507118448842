<template>
    <v-dialog
        v-model="dialog"
        width="900"
        persistent>
        
        <v-card class="overflow-y-auto" flat>

            <v-progress-linear 
                v-if="pageLoader"
                color="secondary"
                indeterminate stream top 
                value="0">
            </v-progress-linear>

            <v-overlay
                absolute
                :opacity="0.1"
                v-if="pageLoader">
            </v-overlay>

            <v-card-title>
                <v-spacer />
                <v-btn icon @click="cancel" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-0">
                <v-card flat class="mx-auto" width="700">
                    <v-card-text>
                        <div class="body-1 font-weight-bold">Convert to</div>
                        <div class="display-1">{{ conversion }}</div>
                    </v-card-text>
                    <v-card-text>
                        <ValidationObserver ref="observer1" tag="form">
                            <div v-for="(question, key) in primaryQuestions" :key="key" class="question">
                                <p class="mb-0 font-weight-medium">
                                    <span v-if="question.type === 'people'">
                                        {{ getPeople.length > 0 ? question.text1 : question.text2 }}
                                    </span>
                                    <span v-else-if="question.type === 'businesses'">
                                        {{ getBusinesses.length > 0 ? question.text1 : question.text2 }}
                                    </span>
                                </p>
                            
                                <ValidationProvider 
                                    v-if="question.type === 'people'"
                                    v-slot="{ errors }" 
                                    :name="question.key" 
                                    rules="required" 
                                    :vid="question.meta">
                                    <v-select                                        
                                        v-model="resourceObj.data[question.meta]"
                                        :readonly="getPeople.length > 0 ? false : true"
                                        :items="getPeople"
                                        :placeholder="getPeople.length > 0 ? '' : 'No data available. Please create new record.'"
                                        class="flex-nvic-finder mt-0 pt-0"
                                        :error-messages="errors"
                                        clearable
                                        single-line>
                                        <template v-slot:append-outer active-class="ma-0 pa-0">
                                            <v-btn depressed color="primary" :disabled="created" @click.stop="createNew(question.type, key, question.meta, question.kind)">
                                                Create New
                                            </v-btn>
                                        </template>
                                    </v-select>
                                </ValidationProvider>
                                <ValidationProvider 
                                    v-else-if="question.type === 'businesses'"
                                    v-slot="{ errors }" 
                                    :name="question.key" 
                                    rules="required" 
                                    :vid="question.meta">
                                    <v-select                                        
                                        v-model="resourceObj.data[question.meta]"
                                        :readonly="getBusinesses.length > 0 ? false : true"
                                        :items="getBusinesses[key]"
                                        :placeholder="getBusinesses.length > 0 ? '' : 'No data available. Please create new record.'"
                                        class="flex-nvic-finder mt-0 pt-0"
                                        :error-messages="errors"
                                        clearable
                                        single-line>
                                        <template v-slot:append-outer active-class="ma-0 pa-0">
                                            <v-btn depressed color="primary" :disabled="created" @click.stop="createNew(question.type, key, question.meta, question.kind)">
                                                Create New
                                            </v-btn>
                                        </template>                                    
                                    </v-select>
                                </ValidationProvider>                            
                            </div>
                        </ValidationObserver>
                    
                    </v-card-text>
                </v-card>
            </v-card-text>


            <v-divider></v-divider>

            <v-card-actions class="pa-6 pa-md-8 flex-wrap controls">                                
                <v-btn
                    outlined
                    class="flex-back-button"
                    large
                    style="min-width:140px;"
                    @click="cancel">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    depressed
                    large
                    style="min-width:140px;"
                    @click="confirmSave">
                    Convert
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog
            v-if="dialog2"
            persistent
            v-model="dialog2"
            max-width="700">
            <v-card>

                <v-card-title>
                    <v-spacer />
                    <v-btn icon @click="dialog2 = false" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-card flat class="mx-auto" width="500">
                        <v-card-text class="text-center">
                            <div class="display-1 font-weight-bold">Create New</div>
                            <div class="text-subtitle-1">Please fill out the details below</div>                            
                        </v-card-text>
                        <v-card-text>
                            <ValidationObserver ref="observer2" tag="form">
                                <v-row v-if="selectedType === 'people'">
                                    <v-col cols="12" md="6">
                                        <ValidationProvider v-slot="{ errors }" name="First Name" rules="required" vid="first-name">
                                            <v-text-field 
                                                v-model="applicants[selectedIndex]['first-name']"
                                                label="First Name"
                                                :error-messages="errors">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <ValidationProvider v-slot="{ errors }" name="Last Name" rules="required" vid="last-name">
                                            <v-text-field 
                                                v-model="applicants[selectedIndex]['last-name']"
                                                label="Last Name"
                                                :error-messages="errors">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col cols="12">
                                        <ValidationProvider v-slot="{ errors }" name="Business Legal Name" rules="required" vid="business-legal-name">
                                            <v-text-field 
                                                v-model="applicants[selectedIndex]['business-legal-name']"
                                                label="Business Legal Name"
                                                :error-messages="errors">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </ValidationObserver>                            
                        </v-card-text>
                    </v-card>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="py-6 px-8">
                    <v-btn
                        color="secondary"
                        @click="dialog2 = false"
                        depressed outlined large>
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        @click="storeTempData"
                        depressed large>
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="confirm"
            persistent
            v-model="confirm"
            max-width="480">
            <v-card>

                <v-card-text class="pb-0">
                    <v-card flat class="mx-auto">
                        <v-card-text>
                            <div class="display-1 font-weight-bold py-6">
                                Warning
                            </div>
                            <div class="text-subtitle-1">You can not undo this action. The conversion process may remove some structural data that conflicts with the new application type. Are you sure you wish to continue?</div>                            
                        </v-card-text>                        
                    </v-card>
                </v-card-text>

                <v-card-actions class="py-7 px-8">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light_gray_2"
                        @click="confirm = false"
                        depressed outlined large>
                        Cancel
                    </v-btn>                    
                    <v-btn
                        color="warning"
                        @click="save"
                        depressed large>
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>

    import { mapState, mapGetters, mapActions } from 'vuex'
    import formHelpers from '~/components/shared/form-helpers.js'
    import validation from '~/components/shared/validation'
    import helpers from '~/plugins/helpers'
    import appLoanTypes from '~/components/shared/app-loan-types'
    import { isEmpty} from 'lodash'

    export default {
        name: 'AppConvertDialog',

        mixins: [ helpers, validation, formHelpers ],

        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            conversion: {
                type: String,
                default: ''
            },
            conversionTypes: {
                type: Array,
                default: () => []
            },
            loanType: {
                type: String,
                default: 'Unsecured'
            }
        },

        computed: {
            ...mapState('flows', [
                'appData',
                'appGuid',
                'loanData',
                'calculation'
            ]),
            ...mapState('kinds', [
                'kinds'
            ]),
            ...mapState('slug', [
                'groupSlug',
                'flowSlug'
            ]),
            ...mapState('auth-actions', [
                'userSettings',
                'appOwnerSettings'
            ]),
            ...mapGetters('flows', [
                'getActiveStep',
                'getConfig'
            ]),
            getPeople() {
                let collection = []
                
                this.resources?.people?.forEach( person => {
                    if ( person.attributes['first-name'] && person.attributes['last-name'] ) {
                        if (person.id.includes('temporary'))
                            collection.push({ text: `${person.attributes['first-name']} ${person.attributes['last-name']} (Created)`, meta: person.meta, value: person.id, disabled: false })
                        else
                            collection.push({ text: `${person.attributes['first-name']} ${person.attributes['last-name']}`, meta: person.relationships.kind.data.id, value: person.id, disabled: false })
                    }
                    else {
                        if (person.id.includes('temporary'))
                            collection.push({ text: `Person ${person.id} (Created)`, meta: person.relationships.kind.data.id, value: person.id, disabled: false })
                        else
                            collection.push({ text: `Person ${person.id}`, meta: person.relationships.kind.data.id, value: person.id, disabled: false })
                    }
                })
                
                this.primaryQuestions.forEach( que => {
                    if ( que.type === 'people' ) {
                        let val = this.resourceObj.data[que.meta]
                        collection.forEach( col => {
                            if ( col.value === val ) {
                                col.disabled = true                                
                            }
                        })
                    }
                })
                
                return collection
            },
            getBusinesses() {
                let collection = []
                let filteredCollection = []
                let businessKind = this.kinds.find( kind => {
                    return kind.name === 'Business'
                })
                this.resources?.businesses?.forEach( (business, index) => {

                    if ( business.attributes['business-legal-name'] ) {
                        if (business.id.includes('temporary'))
                            collection.push({ text: `${business.attributes['business-legal-name']} (Created)`, meta: business.meta, value: business.id })
                        else
                            collection.push({ text: `${business.attributes['business-legal-name']}`, meta: business.relationships.kind.data.id, value: business.id })
                    }
                    else {                        
                        let kindPart = businessKind.children.find( kind => {                        
                            return kind.id === business.relationships.kind.data.id
                        })
                        
                        if (business.id.includes('temporary'))
                            collection.push({ text: `${kindPart.name} ${business.id} (Created)`, meta: business.relationships.kind.data.id, value: business.id })
                        else
                            collection.push({ text: `${kindPart.name} ${business.id}`, meta: business.relationships.kind.data.id, value: business.id })
                    }
                })

                this.primaryQuestions.forEach( (que, index) => {
                    
                    let tempKind = businessKind.children.find( f => { return f.name === que.kind})
                    
                    if ( que.type === 'businesses' ) {
                        
                        let val = this.resourceObj.data[que.meta]
                        let flag = false
                        let temp = []
                        collection.forEach( col => {
                            let list_of_kind = col
                            if ( list_of_kind.meta === tempKind.id ) {       
                                if ( list_of_kind.value === val ) {
                                    list_of_kind.disabled = true                                
                                }
                                temp.push(list_of_kind)
                                flag = true                                
                            }
                        })

                        if ( flag ) {
                            filteredCollection.push(temp)
                        }
                        else {
                            filteredCollection.push([])
                        }
                    }
                })
                
                return filteredCollection
            },
            getPersonKind() {
                let kinds = this.kinds.find( kind => {
                    return kind.name === 'Person'
                })
                return kinds.children
            },
            getBusinessKind() {
                let kinds = this.kinds.find( kind => {
                    return kind.name === 'Business'
                })
                return kinds.children
            },
            applicantOne() {
                return this.resourceObj.data['applicant-1-id']
            },
            applicantTwo() {
                return this.resourceObj.data['applicant-2-id']
            },
            applicantNbs() {
                return this.resourceObj.data['non-borrowing-spouse-1-id']
            },
            applicantTrustee() {
                return this.resourceObj.data['trustee-1-id']
            },
            cleanObjectResource() {
                
                let obj = {}

                let { data } = this.resourceObj

                Object.keys(data).forEach( ob => {
                    if (!isEmpty(data[ob])) {
                        obj[ob] = data[ob]
                    }
                })

                return obj
                
            },
              getLoanType() {
                return this.loanData?.attributes?.['loan-type']
            },
            selectedLoanType() {
                return this.loanType === 'Secured' ? 'Asset Finance' : this.loanType
            },
            isAppOwnerBid() {
                return this.appOwnerSettings.bid == 1
            }
        },

        async mounted() {
            this.pageLoader = true
            await this.initResources()
            this.resourceObj.data['application-id'] = this.appData.id
        },

        methods: {
            ...mapActions('resource-actions', [
                'getApplicantEntity',
                'getBusinessEntity',
                'createBusiness',
                'createPerson',
                'runConvert'
            ]),

            getFlowForApp(application = null, b2b = true) {
                if ( application?.calculation?.['application-type'] === 'Consumer - Individual' ) {
                    if ( applications.calculation['loan-type'] === 'Asset Finance' ) {
                        if ( b2b ) {
                            return 'b2b-consumer-asset'
                        }
                        else {
                            return 'b2c-consumer-asset'
                        }
                    }
                }
            },

            async confirmSave() {
                const valid = await this.$refs.observer1.validate()
                if ( valid ) {
                    this.confirm = true
                }
            },

            async save() {
                
                await this.saveAndConvert()                
                
            },

            async saveAndConvert() {

                let kind = null
                let flags = []
                let payload = []
                let resConvert = null
                let tempResource = null

                this.applicants.forEach( app => {

                    if ( app['business-legal-name'] && this.resourceObj.data[app.type].includes('temporary') ) {
                        
                        kind = this.getBusinessKind.find( k => {
                            return k.name === app.kind
                        })

                        payload.push(
                            this.createBusiness({                            
                                type: 'businesses',
                                attributes: {
                                    'business-legal-name': app['business-legal-name']
                                },
                                relationships: {
                                    application: {
                                        data: { type: 'applications', id: this.appData.id }
                                    },
                                    kind: {
                                        data: { type: 'kinds', id: kind.id }
                                    }
                                }
                            })
                        )

                        flags.push(app.type)

                        // if ( res.status === 201 ) {
                        //     this.resourceObj.data[app.type] = res.data.data.id
                        //     console.log(`Item 100 : `, 100)
                        // }
                    }
                    else if ( (app['first-name'] && app['last-name']) && this.resourceObj.data[app.type].includes('temporary') ) {
                        
                        kind = this.getPersonKind.find( k => {
                            return k.name === app.kind
                        })

                        payload.push(
                            this.createPerson({                            
                                type: 'people',
                                attributes: {
                                    'first-name': app['first-name'],
                                    'last-name': app['last-name']
                                },
                                relationships: {
                                    application: {
                                        data: { type: 'applications', id: this.appData.id }
                                    },
                                    kind: {
                                        data: { type: 'kinds', id: kind.id }
                                    }
                                }
                            })
                        )

                        flags.push(app.type)

                        // if ( res.status === 201 ) {
                        //     this.resourceObj.data[app.type] = res.data.data.id
                        //     console.log(`Item 200 : `, 200)
                        // }

                    }
                    
                })

                if ( payload.length ) {

                    try {
                        Promise.all(payload).then( async res => {
                            
                            res.forEach( (obj, index) => {
                                this.resourceObj.data[flags[index]] = obj.data.data.id
                            })

                            tempResource = this.cleanObjectResource
                            tempResource['loan-type'] = this.selectedLoanType;  

                            
                            resConvert = await this.runConvert( tempResource )

                            if ( resConvert.status === 200 ) {
                                this.$emit('closed-action', true)
                                this.redirectFlow()
                            }

                        })

                    } catch ( error ) {
                        console.log(`Item error : `, error)
                    }

                }
                else {
                    tempResource = this.cleanObjectResource
                    tempResource['loan-type'] = this.selectedLoanType;

                    resConvert = await this.runConvert( tempResource )

                    if ( resConvert.status === 200 ) {
                        this.$emit('closed-action', true)
                        this.redirectFlow()
                    }
                }
                
            },
            redirectFlow() {

                const {conversion} = this

                let flow = appLoanTypes[conversion][this.selectedLoanType]
                let bid = ""
                
                if(!conversion.includes('Commercial')) {
                    bid = this.isAppOwnerBid ? '-bid' : ''                
                }

                const hasLenderQuoteAlgoSlug = !!this.loanData?.attributes?.['selected-lender-quote-algorithm-slug']
                const hasSubmittedToLenderDate = !!this.appData?.attributes?.["submitted-to-lender-date"]

                if (this.getConfig?.flow?.tags?.includes('assister')) {
                    
                    flow = flow.base
                    window.location.href = `/${this.groupSlug}/flows/assister-edit-${flow}${bid}/first/${this.appGuid}`   

                } else {

                    if (this.appData.attributes.target == 'Other') {

                        flow = flow.base
                        window.location.href = `/${this.groupSlug}/flows/b2b-edit-${flow}${bid}/first/${this.appGuid}`  

                    }  else if (this.appData.attributes.target == 'Lender' && hasLenderQuoteAlgoSlug) {

                        flow = flow.base
                        window.location.href = `/${this.groupSlug}/flows/b2b-edit-${flow}${bid}/first/${this.appGuid}`  

                    }  else {
                        
                        flow = (this.userSettings.b2b === 1) ? flow.b2b : flow.b2c
                        window.location.href = `/${this.groupSlug}/flows/${flow}${bid}/first/${this.appGuid}`                
                    }
                }
            },

            createNew(dataType, index = 0, meta = '', kind = '') {
                this.selectedIndex = index
                this.selectedType = dataType
                this.selectedMeta = meta
                this.applicants[index].kind = kind
                this.applicants[index].type = meta
                this.applicants[index].meta = dataType
                
                // this.created = true
                this.dialog2 = true
            },

            async storeTempData() {

                const valid = await this.$refs.observer2.validate()
                let temp = `temporary-${this.selectedMeta}-${this.resources[this.selectedType].length}`

                let kind_selected = this.kinds.find( k => {
                    if ( this.selectedType === 'businesses')
                        return k.name === 'Business'
                    else 
                        return k.name === 'Person'
                }).children.find( k => {
                    return k.name === this.applicants[this.selectedIndex]['kind']
                })
                
                if ( valid ) {
                    this.dialog2 = false
                    this.resources[this.selectedType].push({
                        attributes: {
                            'first-name': this.applicants[this.selectedIndex]['first-name'],
                            'last-name': this.applicants[this.selectedIndex]['last-name'],
                            'business-legal-name': this.applicants[this.selectedIndex]['business-legal-name'],
                        },
                        id: temp,
                        meta: kind_selected?.id
                    })
                    this.applicants[this.selectedIndex].id = temp
                    this.resourceObj.data[this.selectedMeta] = temp
                }                
            },

            async initResources() {

                Promise.all([ 
                    this.getBusinessEntity(this.appData.id), 
                    this.getApplicantEntity(this.appData.id) 
                ]).then( responses => {
                    
                    responses.forEach( res => {
                        if ( !isEmpty(res.data.data) ) {
                            if ( res.config.url.includes('people') ) {
                                this.resources.people = res.data.data
                            }
                            else if ( res.config.url.includes('businesses') ) {
                                this.resources.businesses = res.data.data
                            }
                        }
                    })

                    if ( this.conversion === 'Consumer - Individual' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these people will be the Primary Applicant on the application?', 
                                text2: 'Please create a Primary Applicant',
                                meta: 'applicant-1-id',
                                key: 'Primary Applicant',
                                type: 'people',
                                kind: 'Individual'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Consumer - Individual'
                    }
                    else if ( this.conversion === 'Consumer - Joint' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these people will be the primary applicant on the application?',
                                text2: 'Please create a Primary Applicant',
                                meta: 'applicant-1-id',
                                key: 'Primary Applicant',
                                type: 'people',
                                kind: 'Individual'
                            },
                            { 
                                text1: 'Which of these people will be the secondary applicant on the application?', 
                                text2: 'Please create a Secondary Applicant',
                                meta: 'applicant-2-id',
                                key: 'Secondary Applicant',
                                type: 'people',
                                kind: 'Individual'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Consumer - Joint'
                    }
                    else if ( this.conversion === 'Consumer - Individual - Non Borrowing Spouse' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these people will be the primary applicant on the application?', 
                                text2: 'Please create a Primary Applicant',
                                meta: 'applicant-1-id',
                                key: 'Primary Applicant',
                                type: 'people',
                                kind: 'Individual'
                            },
                            { 
                                text1: 'Which of these people will be the non borrowing spouse on the application?', 
                                text2: 'Please create a Non Borrowing Spouse (NBS)',
                                meta: 'non-borrowing-spouse-1-id',
                                key: 'Non Borrowing Spouse (NBS)',
                                type: 'people',
                                kind: 'Individual'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Consumer - Individual - Non Borrowing Spouse'
                    }
                    else if ( this.conversion === 'Commercial - Company' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these companies will be the primary applicant on the application?', 
                                text2: 'Please create a Primary Applicant',
                                meta: 'applicant-1-id',
                                key: 'Primary Applicant',
                                type: 'businesses',
                                kind: 'Company'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Commercial - Company'
                    }
                    else if ( this.conversion === 'Commercial - Partnership' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these partnerships will be the primary applicant on the application?', 
                                text2: 'Please create a Primary Partnership',
                                meta: 'applicant-1-id',
                                key: 'Primary Partnership',
                                type: 'businesses',
                                kind: 'Partnership'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Commercial - Partnership'
                    }
                    else if ( this.conversion === 'Commercial - Trust - Individual Trustee' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these trusts will be the primary applicant on the application?', 
                                text2: 'Please create a Primary Trust',
                                meta: 'applicant-1-id',
                                key: 'Primary Trust',
                                type: 'businesses',
                                kind: 'Trust'
                            },
                            { 
                                text1: 'Which of these people will be the individual trustee on the application?', 
                                text2: 'Please create an Individual Trustee',
                                meta: 'trustee-1-id',
                                key: 'Individual Trustee',
                                type: 'people',
                                kind: 'Individual'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Commercial - Trust - Individual Trustee'
                    }
                    else if ( this.conversion === 'Commercial - Trust - Company Trustee' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these trusts will be the primary applicant on the application?',
                                text2: 'Please create a Primary Trust',
                                meta: 'applicant-1-id',
                                key: 'Primary Trust',
                                type: 'businesses',
                                kind: 'Trust'
                            },
                            {
                                text1: 'Which of these companies will be the company trustee on the application?', 
                                text2: 'Please create a Company Trustee',
                                meta: 'trustee-1-id',
                                key: 'Company Trustee',
                                type: 'businesses',
                                kind: 'Company'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Commercial - Trust - Company Trustee'
                    }
                    else if ( this.conversion === 'Commercial - Sole Trader' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these people will be the primary applicant on the application?', 
                                text2: 'Please create a Primary Applicant',
                                meta: 'applicant-1-id',
                                key: 'Primary Applicant',
                                type: 'people',
                                kind: 'Individual'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Commercial - Sole Trader'
                    }
                    else if ( this.conversion === 'Commercial - PAYG Chattel Mortgage' ) {
                        this.primaryQuestions = [
                            { 
                                text1: 'Which of these people will be the primary applicant on the application?', 
                                text2: 'Please create a Primary Applicant',
                                meta: 'applicant-1-id',
                                key: 'Primary Applicant',
                                type: 'people',
                                kind: 'Individual'
                            }
                        ]
                        this.resourceObj.data['application-type'] = 'Commercial - PAYG Chattel Mortgage'
                    }

                    this.pageLoader = false

                }).catch ( error => {
                    this.pageLoader = false
                })

            },

            organizeApplicants(val = '', keyword = '') {
                
                let data = this.applicants.find( (dat, index) => {
                    return dat.type === keyword
                })

                if ( data ) {

                    let business = this.resources[data.meta].find( b => {
                        return b.id === val
                    })

                    if (val?.includes('temporary')) {                        
                        data.id = val
                        if ( data.kind === 'Individual' ) {
                            data['first-name'] = business.attributes['first-name']
                            data['last-name'] = business.attributes['last-name']
                        }
                        else {
                            data['business-legal-name'] = business.attributes['business-legal-name']
                        }                        
                    }
                    else {
                        data['first-name'] = ''
                        data['last-name'] = ''
                        data['business-legal-name'] = ''
                    }
                }
            },
            
            cancel() {
                this.$emit('closed-action', true)
            }
        },

        watch: {
            applicantOne: {
                handler(val) {
                    this.organizeApplicants(val, 'applicant-1-id')                    
                },
                deep: true
            },
            applicantTwo: {
                handler(val) {
                    this.organizeApplicants(val, 'applicant-2-id')                    
                },
                deep: true
            },
            applicantNbs: {
                handler(val) {
                    this.organizeApplicants(val, 'non-borrowing-spouse-1-id')                    
                },
                deep: true
            },
            applicantTrustee: {
                handler(val) {
                    this.organizeApplicants(val, 'trustee-1-id')                    
                },
                deep: true
            }
        },

        data() {
            return {
                dialog2: false,
                confirm: false,
                pageLoader: false,
                created: false,
                resources: {
                    people: [],
                    businesses: []
                },
                applicants: [
                    { 'first-name': '', 'last-name': '', 'business-legal-name': '', 'kind': '', 'type': '', 'id': '' , 'meta': ''},
                    { 'first-name': '', 'last-name': '', 'business-legal-name': '', 'kind': '', 'type': '', 'id': '' , 'meta': ''},
                ],
                resourceObj: {
                    data: {
                        'to': '',
                        'application-id': '',
                        'applicant-1-id': '',
                        'applicant-2-id': '',
                        'non-borrowing-spouse-1-id': '',
                        'trustee-1-id': ''
                    }
                },
                primaryQuestions: null,
                selectedType: '',
                selectedIndex: null,
                selectedMeta: null
            }
        }
    }
</script>

<style lang="scss">
    .v-toolbar__content {
        padding: 0 !important;
    }
</style>