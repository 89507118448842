export default {
    currentOccupation: [
        "Agriculture, forestry, fishing and hunting",
        "Plant Nurseries",
        "Cut Flower & Flower Seed Growing",
        "Vegetable Growing",
        "Grape Growing",
        "Apple & Pear Growing",
        "Stone Fruit Growing",
        "Kiwi Fruit Growing",
        "Citrus Fruit Growing",
        "Fruit Growing n.e.c.",
        "Grain Growing",
        "Grain-Sheep Farming",
        "Sheep-Beef Cattle Farming",
        "Sheep Farming",
        "Beef Cattle Farming",
        "Grain-Beef Cattle Farming",
        "Oilseeds & Grain Legumes Growing",
        "Dairy Cattle Farming",
        "Poultry Farming (Meat)",
        "Poultry Farming (Eggs)",
        "Pig Farming",
        "Horse Farming",
        "Deer Farming",
        "Livestock Farming n.e.c.",
        "Sugar Cane Growing",
        "Cotton Growing",
        "Crop & Plant Growing n.e.c.",
        "Cotton Ginning",
        "Shearing Services",
        "Aerial Agricultural Services",
        "Services, to Agriculture n.e.c.",
        "Hunting &, trapping",
        "Forestry",
        "Logging",
        "Services, to Forestry",
        "Rock Lobster Fishing",
        "Prawn Fishing",
        "Finfish, trawling",
        "Squid Jigging",
        "Line Fishing",
        "Marine Fishing n.e.c.",
        "Aquaculture",
        "Mining and services, to mining industry",
        "Black Coal Mining",
        "Brown Coal Mining",
        "Oil & Gas Extraction",
        "Iron Ore Mining",
        "Bauxite Mining",
        "Copper Ore Mining",
        "Gold Ore Mining",
        "Mineral Sand Mining",
        "Nickel Ore Mining",
        "Silver-Lead-Zinc Ore Mining",
        "Metal Ore Mining n.e.c.",
        "Gravel & Sand Quarrying",
        "Construction Material Mining n.e.c.",
        "Other Mining",
        "Petroleum Exploration (Own Account)",
        "Petroleum Exploration Services",
        "Mineral Exploration (Own Account)",
        "Mineral Exploration Services",
        "Other Mining Services",
        "Manufacturing",
        "Meat Processing",
        "Poultry Processing",
        "Bacon, Ham & Smallgood Manufacturing",
        "Milk & Cream Processing",
        "Ice Cream Manufacturing",
        "Dairy Product Manufacturing n.e.c.",
        "Fruit & Vegetable Processing",
        "Oil & Fat Manufacturing",
        "Flour Mill Product Manufacturing",
        "Cereal Food & Baking Mix Manufacturing",
        "Bread Manufacturing",
        "Cake & Pastry Manufacturing",
        "Biscuit Manufacturing",
        "Sugar Manufacturing",
        "Confectionery Manufacturing",
        "Seafood Processing",
        "Prepared Animal & Bird Feed Manufacturing",
        "Food Manufacturing n.e.c.",
        "Soft Drink, Cordial & Syrup Manufacturing",
        "Beer & Malt Manufacturing",
        "Wine Manufacturing",
        "Spirit Manufacturing",
        "Tobacco Product Manufacturing",
        "Wool Scouring",
        "Synthetic Fibre, textile Manufacturing",
        "Cotton, textile Manufacturing",
        "Wool, textile Manufacturing",
        "Textile Finishing",
        "Made-Up, textile Product Manufacturing",
        "Textile Floor Covering Manufacturing",
        "Rope, Cordage &, twine Manufacturing",
        "Textile Product Manufacturing n.e.c.",
        "Hosiery Manufacturing",
        "Cardigan & Pullover Manufacturing",
        "Knitting Mill Product Manufacturing n.e.c.",
        "Men's & Boys' Wear Manufacturing",
        "Women's & Girls' Wear Manufacturing",
        "Sleepwear, Underwear & Infant Clothing Manufacturing",
        "Clothing Manufacturing n.e.c.",
        "Footwear Manufacturing",
        "Leather, tanning & Fur Dressing",
        "Leather & Leather Substitute Product Manufacturing",
        "Log Sawmilling",
        "Wood Chipping",
        "Timber Resawing & Dressing",
        "Plywood & Veneer Manufacturing",
        "Fabricated Wood Manufacturing",
        "Wooden Structural Component Manufacturing",
        "Wood Product Manufacturing n.e.c.",
        "Pulp, Paper & Paperboard Manufacturing",
        "Solid Paperboard Container Manufacturing",
        "Corrugated Paperboard Container Manufacturing",
        "Paper Bag & Sack Manufacturing",
        "Paper & Paper Product Manufacturing n.e.c.",
        "Paper Stationery Manufacturing",
        "Printing",
        "Services, to Printing",
        "Newspaper Printing or Publishing",
        "Other Periodical Publishing",
        "Book & Other Publishing",
        "Recorded Media Manufacturing & Publishing",
        "Petroleum Refining",
        "Petroleum & Coal Product Manufacturing n.e.c.",
        "Fertiliser Manufacturing",
        "Industrial Gas Manufacturing",
        "Synthetic Resin Manufacturing",
        "Organic Industrial Chemical Manufacturing n.e.c.",
        "Inorganic Industrial Chemical Manufacturing n.e.c.",
        "Explosive Manufacturing",
        "Paint Manufacturing",
        "Medicinal & Pharmaceutical Product Manufacturing",
        "Pesticide Manufacturing",
        "Soap & Other Detergent Manufacturing",
        "Cosmetic &, toiletry Preparation Manufacturing",
        "Ink Manufacturing",
        "Chemical Product Manufacturing n.e.c.",
        "Rubber, tyre Manufacturing",
        "Rubber Product Manufacturing n.e.c.",
        "Plastic Blow Moulded Product Manufacturing",
        "Plastic Extruded Product Manufacturing",
        "Plastic Bag & Film Product Manufacturing",
        "Plastic Product Rigid Reinforced Manufacturing",
        "Plastic Foam Product Manufacturing",
        "Plastic Injection Manufacturing Moulded Product",
        "Glass & Glass Product Manufacturing",
        "Clay Brick Manufacturing",
        "Ceramic Product Manufacturing",
        "Ceramic, tile & Pipe Manufacturing",
        "Ceramic Product Manufacturing n.e.c.",
        "Cement & Lime Manufacturing",
        "Plaster Product Manufacturing",
        "Concrete Slurry Manufacturing",
        "Concrete Pipe & Box Culvert Manufacturing",
        "Concrete Product Manufacturing n.e.c.",
        "Non-Metallic Mineral Product Manufacturing n.e.c.",
        "Basic Iron & Steel Manufacturing",
        "Iron & Steel Casting & Forging",
        "Steel Pipe &, tube Manufacturing",
        "Alumina Production",
        "Aluminium Smelting",
        "Copper, Silver, Lead & Zinc Smelting, Refining",
        "Basic Non-ferrous Metal Manufacturing n.e.c.",
        "Aluminium Rollings, Drawing, Extruding",
        "Non-Ferrous Metal Rolling, Drawing, Extruding n.e.c.",
        "Non-Ferrous Metal Casting",
        "Structural Steel Fabricating",
        "Architectural Aluminium Product Manufacturing",
        "Structural Metal Product Manufacturing n.e.c.",
        "Metal Container Manufacturing",
        "Sheet Metal Product Manufacturing n.e.c.",
        "Hand, tool & General Hardware Manufacturing",
        "Spring & Wire Product Manufacturing",
        "Nut, Bolt, Screw & Rivet Manufacturing",
        "Metal Coating & Finishing",
        "Non-Ferrous Pipe Fitting Manufacturing",
        "Fabricated Metal Product Manufacturing n.e.c.",
        "Motor Vehicle Manufacturing",
        "Motor Vehicle Body Manufacturing",
        "Automotive Electrical & Instrument Manufacturing",
        "Automotive Component Manufacturing n.e.c.",
        "Shipbuilding",
        "Boatbuilding",
        "Railway Equipment Manufacturing",
        "Aircraft Manufacturing",
        "Transport Equipment Manufacturing n.e.c.",
        "Photographic & Optical Good Manufacturing",
        "Medical & Surgical Equipment Manufacturing",
        "Professional & Scientific Equipment Manufacturing n.e.c.",
        "Computer & Business Machine Manufacturing",
        "Telecommunication, Broadcasting &, transceiving Equipment",
        "Electronic Equipment Manufacturing n.e.c.",
        "Household Appliance Manufacturing",
        "Electric Cable & Wire Manufacturing",
        "Battery Manufacturing",
        "Electric Light & Sign Manufacturing",
        "Electrical Equipment Manufacturing n.e.c.",
        "Agricultural Machinery Manufacturing",
        "Mining & Construction Machinery Manufacturing",
        "Food Processing Machinery Manufacturing",
        "Machine, tool & Part Manufacturing",
        "Lifting & Material Handling Equipment Manufacturing",
        "Pump & Compressor Manufacturing",
        "Commercial Space Heating & Cooling Equipment Manufacturing",
        "Industrial Machinery & Equipment Manufacturing n.e.c.",
        "Prefabricated Metal Building Manufacturing",
        "Prefabricated Building Manufacturing n.e.c.",
        "Wooden Furniture & Upholstered Seat Manufacturing",
        "Sheet Metal Furniture Manufacturing",
        "Mattress Manufacturing (Except Rubber )",
        "Furniture Manufacturing n.e.c.",
        "Jewellery & Silverware Manufacturing",
        "Toy & Sporting Good Manufacturing",
        "Manufacturing n.e.c.",
        "Utilities: Electricity, gas and water",
        "Electricity Supply",
        "Gas Supply",
        "Water Supply",
        "Sewerage & Drainage Services",
        "Construction",
        "House Construction",
        "Residential Building Construction n.e.c.",
        "Non-Residential Building Construction",
        "Industrial Building Construction",
        "Retail Building Construction",
        "Hotel/Resort Building Construction",
        "Commercial Building Construction",
        "Road & Bridge Construction",
        "Non-Building Construction n.e.c",
        "Site Preparation Services",
        "Concreting Services",
        "Bricklaying Services",
        "Roofing Services",
        "Structural Steel Erection Services",
        "Plumbing Services",
        "Electrical Services",
        "Air Conditioning & Heating Services",
        "Fire & Security System Services",
        "Plastering & Ceiling Services",
        "Carpentry Services",
        "Tiling & Carpeting Services",
        "Painting & Decorating Services",
        "Glazing Services",
        "Landscaping Services",
        "Construction Services n.e.c.",
        "Wholesale and retail, trade",
        "Wool Wholesaling",
        "Cereal Grain Wholesaling",
        "Farm Produce & Supplies Wholesaling n.e.c.",
        "Petroleum Product Wholesaling",
        "Metal & Mineral Wholesaling",
        "Chemical Wholesaling",
        "Timber Wholesaling",
        "Builders Supplies Wholesaling n.e.c.",
        "Farm & Construction Machinery Wholesaling",
        "Professional Equipment Wholesaling",
        "Computer Wholesaling",
        "Business Machine Wholesaling n.e.c.",
        "Electrical & Electronic Equipment Wholesaling n.e.c.",
        "Machinery & Equipment Wholesaling n.e.c.",
        "Car Wholesaling",
        "Commercial Vehicle Wholesaling",
        "Motor Vehicle New Part Dealing",
        "Motor Vehicle Dismantling & Used Part Dealing",
        "Meat Wholesaling",
        "Poultry & Smallgood Wholesaling",
        "Dairy Produce Wholesaling",
        "Fish Wholesaling",
        "Fruit & Vegetable Wholesaling",
        "Confectionery & Soft Drink Wholesaling",
        "Liquor Wholesaling",
        "Tobacco Product Wholesaling",
        "Grocery Wholesaling n.e.c.",
        "Textile Product Wholesaling",
        "Clothing Wholesaling",
        "Footwear Wholesaling",
        "Household Appliance Wholesaling",
        "Furniture Wholesaling",
        "Floor Covering Wholesaling",
        "Household Good Wholesaling n.e.c.",
        "Photographic Equipment Wholesaling",
        "Jewellery & Watch Wholesaling",
        "Toy & Sporting Good Wholesaling",
        "Book & Magazine Wholesaling",
        "Paper Product Wholesaling",
        "Pharmaceutical &, toiletry Wholesaling",
        "Wholesaling n.e.c.",
        "Supermarket & Grocery Stores",
        "Fresh Meat, Fish & Poultry Retailing",
        "Fruit & Vegetable Retailing",
        "Liquor Retailing",
        "Bread & Cake Retailing",
        "Takeaway Food Retailing",
        "Milk Vending",
        "Specialised Food Retailing n.e.c.",
        "Department Stores",
        "Clothing Retailing",
        "Footwear Retailing",
        "Fabric & Other Soft Good Retailing",
        "Furniture Retailing",
        "Floor Covering Retailing",
        "Domestic Hardware & Houseware Retailing",
        "Domestic Appliance Retailing",
        "Recorded Music Retailing",
        "Sport & Camping Equipment Retailing",
        "Toy & Game Retailing",
        "Newspaper, Book & Stationery Retailing",
        "Photographic Equipment Retailing",
        "Marine Equipment Retailing",
        "Pharmaceutical, Cosmetic &, toiletry Retailing",
        "Antique & Used Good Retailing",
        "Garden Equipment Retailing",
        "Flower Retailing",
        "Watch & Jewellery Retailing",
        "Retailing n.e.c.",
        "Household Equipment Repair Services (Electrical)",
        "Household Equipment Repair Services n.e.c.",
        "Service stations and mechanical workshops",
        "Car Retailing",
        "Motor Cycle Dealing",
        "Trailer & Caravan Dealing",
        "Automotive Fuel Retailing",
        "Automotive Electrical Services",
        "Smash Repairing",
        "Tyre Retailing",
        "Automotive Repair & Services n.e.c.",
        "Accommodation",
        "Pubs,, taverns & Bars",
        "Cafes & Restaurants",
        "Clubs (Hospitality)",
        "Transport and storage",
        "Road Freight, transport",
        "Long Distance Bus, transport",
        "Short Distance Bus, transport (including, tramway)",
        "Taxi & Other Road Passenger, transport",
        "Rail, transport",
        "International Sea, transport",
        "Coastal Water, transport",
        "Inland Water, transport",
        "Scheduled International Air, transport",
        "Scheduled Domestic Air, transport",
        "Air & Space, transport",
        "Non-Schedule Air & Space, transport",
        "Pipeline, transport",
        "Transport n.e.c.",
        "Parking Services",
        "Services, to Road, transport n.e.c.",
        "Stevedoring",
        "Water, transport, terminals",
        "Port Operators",
        "Services, to Water, transport n.e.c.",
        "Services, to Air, transport",
        "Travel Agency Services",
        "Road Freight Forwarding",
        "Freight Forwarding (except Road)",
        "Customs Agency Services",
        "Services, to, transport n.e.c.",
        "Grain Storage",
        "Storage n.e.c.",
        "Communications",
        "Postal Services",
        "Courier Services",
        "Telecommunication Services",
        "Financial Services, professional consulting services, real estate agents",
        "Central Bank",
        "Banks",
        "Building Societies",
        "Credit Unions",
        "Money Market Dealers",
        "Deposit, taking Financiers n.e.c.",
        "Other Financiers",
        "Financial Asset Investors",
        "Life Insurance",
        "Superannuation Funds",
        "Health Insurance",
        "General Insurance",
        "Financial Asset Broking Services",
        "Services, to Finance & Investment n.e.c.",
        "Services, to Insurance",
        "Residential Property Operators or Investors",
        "Commercial Property Developers",
        "Residential Property Developers",
        "Industrial Property Developers or Investors",
        "Retail Property Developers or Investors",
        "Hotel/Resort Property Developers or Investors",
        "Commercial Property Developers or Investors",
        "Non-Residential Property Operators, Developers or Investors",
        "Real Estate Agents",
        "Non-Financial Asset Investors",
        "Motor Vehicle Hiring",
        "Other, transport Equipment Leasing",
        "Plant Hiring or Leasing",
        "Scientific Research",
        "Architectural Services",
        "Surveying Services",
        "Consulting Engineering Services",
        "Technical Services n.e.c.",
        "Data Processing Services",
        "Information Storage & Retrieval Services",
        "Computer Maintenance Services",
        "Computer Consultancy Services",
        "Legal Services",
        "Accounting Services",
        "Advertising Services",
        "Commercial Art & Display Services",
        "Market Research Services",
        "Business Administrative Services",
        "Business Management Services",
        "Employment Placement Services",
        "Contract Staff Services",
        "Secretarial Services",
        "Security & Investigative Services (except Police)",
        "Pest Control Services",
        "Cleaning Services",
        "Contract Packing Services n.e.c.",
        "Business Services n.e.c.",
        "Central Government Administration",
        "State Government Administration",
        "Local Government Administration",
        "Justice",
        "Foreign Government Representation",
        "Defence",
        "Community services, schools, charities, non-profit organisations",
        "Preschool Education",
        "Primary Education",
        "Secondary Education",
        "Combined Primary & Secondary Education",
        "Special School Education",
        "Higher Education",
        "Technical & Further Education",
        "Other Education",
        "Health services, doctors, dentists, veterinarians",
        "Hospitals (except Psychiatric Hospitals)",
        "Psychiatric Hospitals",
        "Nursing Homes",
        "General Practice Medical Services",
        "Specialist Medical Services",
        "Dental Services",
        "Pathology Services",
        "Optometry & Optical Dispensing",
        "Ambulance Services",
        "Community Health Centres",
        "Physiotherapy Services",
        "Chiropractic Services",
        "Health Services n.e.c.",
        "Veterinary Services",
        "Child Care Services",
        "Accommodation for, the Aged",
        "Residential Care Services n.e.c.",
        "Non-Residential Care Services n.e.c.",
        "Recreational, hospitality, entertainment",
        "Film & Video Production",
        "Film & Video Distribution",
        "Motion Picture Exhibition",
        "Radio Services",
        "Television Services",
        "Libraries",
        "Museums",
        "Zoological & Botanic Gardens",
        "Recreational Parks & Gardens",
        "Music &, theatre Production",
        "Creative Arts",
        "Sound Recording Studios",
        "Performing Arts Venues",
        "Services, to, the Arts n.e.c.",
        "Horse & Dog Racing",
        "Sports Grounds & Facilities n.e.c.",
        "Sports & Services, to Sports n.e.c.",
        "Lotteries",
        "Casinos",
        "Gambling Services n.e.c.",
        "Other Recreation Services",
        "Video Hire Outlets",
        "Personal & Household Goods Hiring n.e.c.",
        "Laundries & Dry Cleaners",
        "Photographic Film Processing",
        "Photographic Studios",
        "Funeral Directors, Crematoria & Cemeteries",
        "Gardening Services",
        "Hairdressing & Beauty Salons",
        "Personal Services n.e.c.",
        "Religious Organisations",
        "Business & Professional Associations",
        "Labour Associations",
        "Interest Groups n.e.c.",
        "Police Services",
        "Corrective Centres",
        "Fire Brigade Services",
        "Waste Disposal Services",
        "Private Households Employing Staff",
    ],
    addressType: [
        "Residential",
        "Business",
        "Registered",
        "Mailing",
    ],
    businessPremisesStatus: [
        "Leased",
        "Own",
        "RunFromHome",
    ],
    "street-type": [
        "Street",
        "Road",
        "Avenue",
        "Crescent",
        "Drive",
        "Alley",
        "Arcade",
        "Boulevard",
        "Bend",
        "Bypass",
        "Brace",
        "Court",
        "Circuit",
        "Chase",
        "Circle",
        "Close",
        "Concourse",
        "Corner",
        "Circus",
        "Crossing",
        "Corso",
        "Centre",
        "Cove",
        "Causeway",
        "Driveway",
        "Entrance",
        "Esplanade",
        "Expressway",
        "Fairway",
        "Frontage",
        "Freeway",
        "Garden",
        "Gardens",
        "Glade",
        "Glen",
        "Grange",
        "Ground",
        "Green",
        "Gate",
        "Grove",
        "Heights",
        "Highway",
        "Junction",
        "Key",
        "Lane",
        "Link",
        "Loo",
        "Mall",
        "Mews",
        "Motorway",
        "Nook",
        "Outlook",
        "Place",
        "Parade",
        "Plaza",
        "Point",
        "Promenade",
        "Pass",
        "Port",
        "Path",
        "Parkway",
        "Quadrant",
        "Quadrangle",
        "Quay",
        "Ridge",
        "Roadway",
        "Reserve",
        "Rise",
        "Round",
        "Row",
        "Rest",
        "Retreat",
        "Route",
        "RightOfWay",
        "Siding",
        "Square",
        "Terrace",
        "Track",
        "Trail",
        "Tollway",
        "View",
        "Way",
        "Walk",
        "Walkway",
        "Wynd",
    ],
    industry: [
        "0100P",
        "Plant Nurseries",
        "Cut Flower & Flower Seed Growing",
        "Vegetable Growing",
        "Grape Growing",
        "Apple & Pear Growing",
        "Stone Fruit Growing",
        "Kiwi Fruit Growing",
        "Citrus Fruit Growing",
        "Fruit Growing n.e.c.",
        "Grain Growing",
        "Grain-Sheep Farming",
        "Sheep-Beef Cattle Farming",
        "Sheep Farming",
        "Beef Cattle Farming",
        "Grain-Beef Cattle Farming",
        "Oilseeds & Grain Legumes Growing",
        "Dairy Cattle Farming",
        "Poultry Farming (Meat)",
        "Poultry Farming (Eggs)",
        "Pig Farming",
        "Horse Farming",
        "Deer Farming",
        "Livestock Farming n.e.c.",
        "Sugar Cane Growing",
        "Cotton Growing",
        "Crop & Plant Growing n.e.c.",
        "Cotton Ginning",
        "Shearing Services",
        "Aerial Agricultural Services",
        "Services, to Agriculture n.e.c.",
        "Hunting &, trapping",
        "Forestry",
        "Logging",
        "Services, to Forestry",
        "Rock Lobster Fishing",
        "Prawn Fishing",
        "Finfish, trawling",
        "Squid Jigging",
        "Line Fishing",
        "Marine Fishing n.e.c.",
        "Aquaculture",
        "Mining and services, to mining industry",
        "Black Coal Mining",
        "Brown Coal Mining",
        "Oil & Gas Extraction",
        "Iron Ore Mining",
        "Bauxite Mining",
        "Copper Ore Mining",
        "Gold Ore Mining",
        "Mineral Sand Mining",
        "Nickel Ore Mining",
        "Silver-Lead-Zinc Ore Mining",
        "Metal Ore Mining n.e.c.",
        "Gravel & Sand Quarrying",
        "Construction Material Mining n.e.c.",
        "Other Mining",
        "Petroleum Exploration (Own Account)",
        "Petroleum Exploration Services",
        "Mineral Exploration (Own Account)",
        "Mineral Exploration Services",
        "Other Mining Services",
        "Manufacturing",
        "Meat Processing",
        "Poultry Processing",
        "Bacon, Ham & Smallgood Manufacturing",
        "Milk & Cream Processing",
        "Ice Cream Manufacturing",
        "Dairy Product Manufacturing n.e.c.",
        "Fruit & Vegetable Processing",
        "Oil & Fat Manufacturing",
        "Flour Mill Product Manufacturing",
        "Cereal Food & Baking Mix Manufacturing",
        "Bread Manufacturing",
        "Cake & Pastry Manufacturing",
        "Biscuit Manufacturing",
        "Sugar Manufacturing",
        "Confectionery Manufacturing",
        "Seafood Processing",
        "Prepared Animal & Bird Feed Manufacturing",
        "Food Manufacturing n.e.c.",
        "Soft Drink, Cordial & Syrup Manufacturing",
        "Beer & Malt Manufacturing",
        "Wine Manufacturing",
        "Spirit Manufacturing",
        "Tobacco Product Manufacturing",
        "Wool Scouring",
        "Synthetic Fibre, textile Manufacturing",
        "Cotton, textile Manufacturing",
        "Wool, textile Manufacturing",
        "Textile Finishing",
        "Made-Up, textile Product Manufacturing",
        "Textile Floor Covering Manufacturing",
        "Rope, Cordage &, twine Manufacturing",
        "Textile Product Manufacturing n.e.c.",
        "Hosiery Manufacturing",
        "Cardigan & Pullover Manufacturing",
        "Knitting Mill Product Manufacturing n.e.c.",
        "Men's & Boys' Wear Manufacturing",
        "Women's & Girls' Wear Manufacturing",
        "Sleepwear, Underwear & Infant Clothing Manufacturing",
        "Clothing Manufacturing n.e.c.",
        "Footwear Manufacturing",
        "Leather, tanning & Fur Dressing",
        "Leather & Leather Substitute Product Manufacturing",
        "Log Sawmilling",
        "Wood Chipping",
        "Timber Resawing & Dressing",
        "Plywood & Veneer Manufacturing",
        "Fabricated Wood Manufacturing",
        "Wooden Structural Component Manufacturing",
        "Wood Product Manufacturing n.e.c.",
        "Pulp, Paper & Paperboard Manufacturing",
        "Solid Paperboard Container Manufacturing",
        "Corrugated Paperboard Container Manufacturing",
        "Paper Bag & Sack Manufacturing",
        "Paper & Paper Product Manufacturing n.e.c.",
        "Paper Stationery Manufacturing",
        "Printing",
        "Services, to Printing",
        "Newspaper Printing or Publishing",
        "Other Periodical Publishing",
        "Book & Other Publishing",
        "Recorded Media Manufacturing & Publishing",
        "Petroleum Refining",
        "Petroleum & Coal Product Manufacturing n.e.c.",
        "Fertiliser Manufacturing",
        "Industrial Gas Manufacturing",
        "Synthetic Resin Manufacturing",
        "Organic Industrial Chemical Manufacturing n.e.c.",
        "Inorganic Industrial Chemical Manufacturing n.e.c.",
        "Explosive Manufacturing",
        "Paint Manufacturing",
        "Medicinal & Pharmaceutical Product Manufacturing",
        "Pesticide Manufacturing",
        "Soap & Other Detergent Manufacturing",
        "Cosmetic &, toiletry Preparation Manufacturing",
        "Ink Manufacturing",
        "Chemical Product Manufacturing n.e.c.",
        "Rubber, tyre Manufacturing",
        "Rubber Product Manufacturing n.e.c.",
        "Plastic Blow Moulded Product Manufacturing",
        "Plastic Extruded Product Manufacturing",
        "Plastic Bag & Film Product Manufacturing",
        "Plastic Product Rigid Reinforced Manufacturing",
        "Plastic Foam Product Manufacturing",
        "Plastic Injection Manufacturing Moulded Product",
        "Glass & Glass Product Manufacturing",
        "Clay Brick Manufacturing",
        "Ceramic Product Manufacturing",
        "Ceramic, tile & Pipe Manufacturing",
        "Ceramic Product Manufacturing n.e.c.",
        "Cement & Lime Manufacturing",
        "Plaster Product Manufacturing",
        "Concrete Slurry Manufacturing",
        "Concrete Pipe & Box Culvert Manufacturing",
        "Concrete Product Manufacturing n.e.c.",
        "Non-Metallic Mineral Product Manufacturing n.e.c.",
        "Basic Iron & Steel Manufacturing",
        "Iron & Steel Casting & Forging",
        "Steel Pipe &, tube Manufacturing",
        "Alumina Production",
        "Aluminium Smelting",
        "Copper, Silver, Lead & Zinc Smelting, Refining",
        "Basic Non-ferrous Metal Manufacturing n.e.c.",
        "Aluminium Rollings, Drawing, Extruding",
        "Non-Ferrous Metal Rolling, Drawing, Extruding n.e.c.",
        "Non-Ferrous Metal Casting",
        "Structural Steel Fabricating",
        "Architectural Aluminium Product Manufacturing",
        "Structural Metal Product Manufacturing n.e.c.",
        "Metal Container Manufacturing",
        "Sheet Metal Product Manufacturing n.e.c.",
        "Hand, tool & General Hardware Manufacturing",
        "Spring & Wire Product Manufacturing",
        "Nut, Bolt, Screw & Rivet Manufacturing",
        "Metal Coating & Finishing",
        "Non-Ferrous Pipe Fitting Manufacturing",
        "Fabricated Metal Product Manufacturing n.e.c.",
        "Motor Vehicle Manufacturing",
        "Motor Vehicle Body Manufacturing",
        "Automotive Electrical & Instrument Manufacturing",
        "Automotive Component Manufacturing n.e.c.",
        "Shipbuilding",
        "Boatbuilding",
        "Railway Equipment Manufacturing",
        "Aircraft Manufacturing",
        "Transport Equipment Manufacturing n.e.c.",
        "Photographic & Optical Good Manufacturing",
        "Medical & Surgical Equipment Manufacturing",
        "Professional & Scientific Equipment Manufacturing n.e.c.",
        "Computer & Business Machine Manufacturing",
        "Telecommunication, Broadcasting &, transceiving Equipment",
        "Electronic Equipment Manufacturing n.e.c.",
        "Household Appliance Manufacturing",
        "Electric Cable & Wire Manufacturing",
        "Battery Manufacturing",
        "Electric Light & Sign Manufacturing",
        "Electrical Equipment Manufacturing n.e.c.",
        "Agricultural Machinery Manufacturing",
        "Mining & Construction Machinery Manufacturing",
        "Food Processing Machinery Manufacturing",
        "Machine, tool & Part Manufacturing",
        "Lifting & Material Handling Equipment Manufacturing",
        "Pump & Compressor Manufacturing",
        "Commercial Space Heating & Cooling Equipment Manufacturing",
        "Industrial Machinery & Equipment Manufacturing n.e.c.",
        "Prefabricated Metal Building Manufacturing",
        "Prefabricated Building Manufacturing n.e.c.",
        "Wooden Furniture & Upholstered Seat Manufacturing",
        "Sheet Metal Furniture Manufacturing",
        "Mattress Manufacturing (Except Rubber )",
        "Furniture Manufacturing n.e.c.",
        "Jewellery & Silverware Manufacturing",
        "Toy & Sporting Good Manufacturing",
        "Manufacturing n.e.c.",
        "Utilities: Electricity, gas and water",
        "Electricity Supply",
        "Gas Supply",
        "Water Supply",
        "Sewerage & Drainage Services",
        "Construction",
        "House Construction",
        "Residential Building Construction n.e.c.",
        "Non-Residential Building Construction",
        "Industrial Building Construction",
        "Retail Building Construction",
        "Hotel/Resort Building Construction",
        "Commercial Building Construction",
        "Road & Bridge Construction",
        "Non-Building Construction n.e.c",
        "Site Preparation Services",
        "Concreting Services",
        "Bricklaying Services",
        "Roofing Services",
        "Structural Steel Erection Services",
        "Plumbing Services",
        "Electrical Services",
        "Air Conditioning & Heating Services",
        "Fire & Security System Services",
        "Plastering & Ceiling Services",
        "Carpentry Services",
        "Tiling & Carpeting Services",
        "Painting & Decorating Services",
        "Glazing Services",
        "Landscaping Services",
        "Construction Services n.e.c.",
        "Wholesale and retail, trade",
        "Wool Wholesaling",
        "Cereal Grain Wholesaling",
        "Farm Produce & Supplies Wholesaling n.e.c.",
        "Petroleum Product Wholesaling",
        "Metal & Mineral Wholesaling",
        "Chemical Wholesaling",
        "Timber Wholesaling",
        "Builders Supplies Wholesaling n.e.c.",
        "Farm & Construction Machinery Wholesaling",
        "Professional Equipment Wholesaling",
        "Computer Wholesaling",
        "Business Machine Wholesaling n.e.c.",
        "Electrical & Electronic Equipment Wholesaling n.e.c.",
        "Machinery & Equipment Wholesaling n.e.c.",
        "Car Wholesaling",
        "Commercial Vehicle Wholesaling",
        "Motor Vehicle New Part Dealing",
        "Motor Vehicle Dismantling & Used Part Dealing",
        "Meat Wholesaling",
        "Poultry & Smallgood Wholesaling",
        "Dairy Produce Wholesaling",
        "Fish Wholesaling",
        "Fruit & Vegetable Wholesaling",
        "Confectionery & Soft Drink Wholesaling",
        "Liquor Wholesaling",
        "Tobacco Product Wholesaling",
        "Grocery Wholesaling n.e.c.",
        "Textile Product Wholesaling",
        "Clothing Wholesaling",
        "Footwear Wholesaling",
        "Household Appliance Wholesaling",
        "Furniture Wholesaling",
        "Floor Covering Wholesaling",
        "Household Good Wholesaling n.e.c.",
        "Photographic Equipment Wholesaling",
        "Jewellery & Watch Wholesaling",
        "Toy & Sporting Good Wholesaling",
        "Book & Magazine Wholesaling",
        "Paper Product Wholesaling",
        "Pharmaceutical &, toiletry Wholesaling",
        "Wholesaling n.e.c.",
        "Supermarket & Grocery Stores",
        "Fresh Meat, Fish & Poultry Retailing",
        "Fruit & Vegetable Retailing",
        "Liquor Retailing",
        "Bread & Cake Retailing",
        "Takeaway Food Retailing",
        "Milk Vending",
        "Specialised Food Retailing n.e.c.",
        "Department Stores",
        "Clothing Retailing",
        "Footwear Retailing",
        "Fabric & Other Soft Good Retailing",
        "Furniture Retailing",
        "Floor Covering Retailing",
        "Domestic Hardware & Houseware Retailing",
        "Domestic Appliance Retailing",
        "Recorded Music Retailing",
        "Sport & Camping Equipment Retailing",
        "Toy & Game Retailing",
        "Newspaper, Book & Stationery Retailing",
        "Photographic Equipment Retailing",
        "Marine Equipment Retailing",
        "Pharmaceutical, Cosmetic &, toiletry Retailing",
        "Antique & Used Good Retailing",
        "Garden Equipment Retailing",
        "Flower Retailing",
        "Watch & Jewellery Retailing",
        "Retailing n.e.c.",
        "Household Equipment Repair Services (Electrical)",
        "Household Equipment Repair Services n.e.c.",
        "Service stations and mechanical workshops",
        "Car Retailing",
        "Motor Cycle Dealing",
        "Trailer & Caravan Dealing",
        "Automotive Fuel Retailing",
        "Automotive Electrical Services",
        "Smash Repairing",
        "Tyre Retailing",
        "Automotive Repair & Services n.e.c.",
        "Accommodation",
        "Pubs,, taverns & Bars",
        "Cafes & Restaurants",
        "Clubs (Hospitality)",
        "Transport and storage",
        "Road Freight, transport",
        "Long Distance Bus, transport",
        "Short Distance Bus, transport (including, tramway)",
        "Taxi & Other Road Passenger, transport",
        "Rail, transport",
        "International Sea, transport",
        "Coastal Water, transport",
        "Inland Water, transport",
        "Scheduled International Air, transport",
        "Scheduled Domestic Air, transport",
        "Air & Space, transport",
        "Non-Schedule Air & Space, transport",
        "Pipeline, transport",
        "Transport n.e.c.",
        "Parking Services",
        "Services, to Road, transport n.e.c.",
        "Stevedoring",
        "Water, transport, terminals",
        "Port Operators",
        "Services, to Water, transport n.e.c.",
        "Services, to Air, transport",
        "Travel Agency Services",
        "Road Freight Forwarding",
        "Freight Forwarding (except Road)",
        "Customs Agency Services",
        "Services, to, transport n.e.c.",
        "Grain Storage",
        "Storage n.e.c.",
        "Communications",
        "Postal Services",
        "Courier Services",
        "Telecommunication Services",
        "Financial Services, professional consulting services, real estate agents",
        "Central Bank",
        "Banks",
        "Building Societies",
        "Credit Unions",
        "Money Market Dealers",
        "Deposit, taking Financiers n.e.c.",
        "Other Financiers",
        "Financial Asset Investors",
        "Life Insurance",
        "Superannuation Funds",
        "Health Insurance",
        "General Insurance",
        "Financial Asset Broking Services",
        "Services, to Finance & Investment n.e.c.",
        "Services, to Insurance",
        "Residential Property Operators or Investors",
        "Commercial Property Developers",
        "Residential Property Developers",
        "Industrial Property Developers or Investors",
        "Retail Property Developers or Investors",
        "Hotel/Resort Property Developers or Investors",
        "Commercial Property Developers or Investors",
        "Non-Residential Property Operators, Developers or Investors",
        "Real Estate Agents",
        "Non-Financial Asset Investors",
        "Motor Vehicle Hiring",
        "Other, transport Equipment Leasing",
        "Plant Hiring or Leasing",
        "Scientific Research",
        "Architectural Services",
        "Surveying Services",
        "Consulting Engineering Services",
        "Technical Services n.e.c.",
        "Data Processing Services",
        "Information Storage & Retrieval Services",
        "Computer Maintenance Services",
        "Computer Consultancy Services",
        "Legal Services",
        "Accounting Services",
        "Advertising Services",
        "Commercial Art & Display Services",
        "Market Research Services",
        "Business Administrative Services",
        "Business Management Services",
        "Employment Placement Services",
        "Contract Staff Services",
        "Secretarial Services",
        "Security & Investigative Services (except Police)",
        "Pest Control Services",
        "Cleaning Services",
        "Contract Packing Services n.e.c.",
        "Business Services n.e.c.",
        "Central Government Administration",
        "State Government Administration",
        "Local Government Administration",
        "Justice",
        "Foreign Government Representation",
        "Defence",
        "Community services, schools, charities, non-profit organisations",
        "Preschool Education",
        "Primary Education",
        "Secondary Education",
        "Combined Primary & Secondary Education",
        "Special School Education",
        "Higher Education",
        "Technical & Further Education",
        "Other Education",
        "Health services, doctors, dentists, veterinarians",
        "Hospitals (except Psychiatric Hospitals)",
        "Psychiatric Hospitals",
        "Nursing Homes",
        "General Practice Medical Services",
        "Specialist Medical Services",
        "Dental Services",
        "Pathology Services",
        "Optometry & Optical Dispensing",
        "Ambulance Services",
        "Community Health Centres",
        "Physiotherapy Services",
        "Chiropractic Services",
        "Health Services n.e.c.",
        "Veterinary Services",
        "Child Care Services",
        "Accommodation for, the Aged",
        "Residential Care Services n.e.c.",
        "Non-Residential Care Services n.e.c.",
        "Recreational, hospitality, entertainment",
        "Film & Video Production",
        "Film & Video Distribution",
        "Motion Picture Exhibition",
        "Radio Services",
        "Television Services",
        "Libraries",
        "Museums",
        "Zoological & Botanic Gardens",
        "Recreational Parks & Gardens",
        "Music &, theatre Production",
        "Creative Arts",
        "Sound Recording Studios",
        "Performing Arts Venues",
        "Services, to, the Arts n.e.c.",
        "Horse & Dog Racing",
        "Sports Grounds & Facilities n.e.c.",
        "Sports & Services, to Sports n.e.c.",
        "Lotteries",
        "Casinos",
        "Gambling Services n.e.c.",
        "Other Recreation Services",
        "Video Hire Outlets",
        "Personal & Household Goods Hiring n.e.c.",
        "Laundries & Dry Cleaners",
        "Photographic Film Processing",
        "Photographic Studios",
        "Funeral Directors, Crematoria & Cemeteries",
        "Gardening Services",
        "Hairdressing & Beauty Salons",
        "Personal Services n.e.c.",
        "Religious Organisations",
        "Business & Professional Associations",
        "Labour Associations",
        "Interest Groups n.e.c.",
        "Police Services",
        "Corrective Centres",
        "Fire Brigade Services",
        "Waste Disposal Services",
        "Private Households Employing Staff",
    ],
    industryOptions: [
        { value:"0100P", text:"Agriculture, forestry, fishing and hunting"},
        { value:"0111P", text:"Plant Nurseries"},
        { value:"0112P", text:"Cut Flower &amp; Flower Seed Growing"},
        { value:"0113P", text:"Vegetable Growing"},
        { value:"0114P", text:"Grape Growing"},
        { value:"0115P", text:"Apple &amp; Pear Growing"},
        { value:"0116P", text:"Stone Fruit Growing"},
        { value:"0117P", text:"Kiwi Fruit Growing"},
        { value:"0118P", text:"Citrus Fruit Growing"},
        { value:"0119P", text:"Fruit Growing n.e.c."},
        { value:"0121P", text:"Grain Growing"},
        { value:"0122P", text:"Grain-Sheep Farming"},
        { value:"0123P", text:"Sheep-Beef Cattle Farming"},
        { value:"0124P", text:"Sheep Farming"},
        { value:"0125P", text:"Beef Cattle Farming"},
        { value:"0126P", text:"Grain-Beef Cattle Farming"},
        { value:"0127P", text:"Oilseeds &amp; Grain Legumes Growing"},
        { value:"0130P", text:"Dairy Cattle Farming"},
        { value:"0141P", text:"Poultry Farming (Meat)"},
        { value:"0142P", text:"Poultry Farming (Eggs)"},
        { value:"0151P", text:"Pig Farming"},
        { value:"0152P", text:"Horse Farming"},
        { value:"0153P", text:"Deer Farming"},
        { value:"0159P", text:"Livestock Farming n.e.c."},
        { value:"0161P", text:"Sugar Cane Growing"},
        { value:"0162P", text:"Cotton Growing"},
        { value:"0169P", text:"Crop &amp; Plant Growing n.e.c."},
        { value:"0211P", text:"Cotton Ginning"},
        { value:"0212P", text:"Shearing Services"},
        { value:"0213P", text:"Aerial Agricultural Services"},
        { value:"0219P", text:"Services, to Agriculture n.e.c."},
        { value:"0220P", text:"Hunting &amp;, trapping"},
        { value:"0301P", text:"Forestry"},
        { value:"0302P", text:"Logging"},
        { value:"0303P", text:"Services, to Forestry"},
        { value:"0411P", text:"Rock Lobster Fishing"},
        { value:"0412P", text:"Prawn Fishing"},
        { value:"0413P", text:"Finfish, trawling"},
        { value:"0414P", text:"Squid Jigging"},
        { value:"0415P", text:"Line Fishing"},
        { value:"0419P", text:"Marine Fishing n.e.c."},
        { value:"0420P", text:"Aquaculture"},
        { value:"1000P", text:"Mining and services, to mining industry"},
        { value:"1101P", text:"Black Coal Mining"},
        { value:"1102P", text:"Brown Coal Mining"},
        { value:"1200P", text:"Oil &amp; Gas Extraction"},
        { value:"1311P", text:"Iron Ore Mining"},
        { value:"1312P", text:"Bauxite Mining"},
        { value:"1313P", text:"Copper Ore Mining"},
        { value:"1314P", text:"Gold Ore Mining"},
        { value:"1315P", text:"Mineral Sand Mining"},
        { value:"1316P", text:"Nickel Ore Mining"},
        { value:"1317P", text:"Silver-Lead-Zinc Ore Mining"},
        { value:"1319P", text:"Metal Ore Mining n.e.c."},
        { value:"1411P", text:"Gravel &amp; Sand Quarrying"},
        { value:"1419P", text:"Construction Material Mining n.e.c."},
        { value:"1420P", text:"Other Mining"},
        { value:"1511P", text:"Petroleum Exploration (Own Account)"},
        { value:"1512P", text:"Petroleum Exploration Services"},
        { value:"1513P", text:"Mineral Exploration (Own Account)"},
        { value:"1514P", text:"Mineral Exploration Services"},
        { value:"1520P", text:"Other Mining Services"},
        { value:"2000P", text:"Manufacturing"},
        { value:"2111P", text:"Meat Processing"},
        { value:"2112P", text:"Poultry Processing"},
        { value:"2113P", text:"Bacon, Ham &amp; Smallgood Manufacturing"},
        { value:"2121P", text:"Milk &amp; Cream Processing"},
        { value:"2122P", text:"Ice Cream Manufacturing"},
        { value:"2129P", text:"Dairy Product Manufacturing n.e.c."},
        { value:"2130P", text:"Fruit &amp; Vegetable Processing"},
        { value:"2140P", text:"Oil &amp; Fat Manufacturing"},
        { value:"2151P", text:"Flour Mill Product Manufacturing"},
        { value:"2152P", text:"Cereal Food &amp; Baking Mix Manufacturing"},
        { value:"2161P", text:"Bread Manufacturing"},
        { value:"2162P", text:"Cake &amp; Pastry Manufacturing"},
        { value:"2163P", text:"Biscuit Manufacturing"},
        { value:"2171P", text:"Sugar Manufacturing"},
        { value:"2172P", text:"Confectionery Manufacturing"},
        { value:"2173P", text:"Seafood Processing"},
        { value:"2174P", text:"Prepared Animal &amp; Bird Feed Manufacturing"},
        { value:"2179P", text:"Food Manufacturing n.e.c."},
        { value:"2181P", text:"Soft Drink, Cordial &amp; Syrup Manufacturing"},
        { value:"2182P", text:"Beer &amp; Malt Manufacturing"},
        { value:"2183P", text:"Wine Manufacturing"},
        { value:"2184P", text:"Spirit Manufacturing"},
        { value:"2190P", text:"Tobacco Product Manufacturing"},
        { value:"2211P", text:"Wool Scouring"},
        { value:"2212P", text:"Synthetic Fibre, textile Manufacturing"},
        { value:"2213P", text:"Cotton, textile Manufacturing"},
        { value:"2214P", text:"Wool, textile Manufacturing"},
        { value:"2215P", text:"Textile Finishing"},
        { value:"2221P", text:"Made-Up, textile Product Manufacturing"},
        { value:"2222P", text:"Textile Floor Covering Manufacturing"},
        { value:"2223P", text:"Rope, Cordage &amp;, twine Manufacturing"},
        { value:"2229P", text:"Textile Product Manufacturing n.e.c."},
        { value:"2231P", text:"Hosiery Manufacturing"},
        { value:"2232P", text:"Cardigan &amp; Pullover Manufacturing"},
        { value:"2239P", text:"Knitting Mill Product Manufacturing n.e.c."},
        { value:"2241P", text:"Men's &amp; Boys' Wear Manufacturing"},
        { value:"2242P", text:"Women's &amp; Girls' Wear Manufacturing"},
        { value:"2243P", text:"Sleepwear, Underwear &amp; Infant Clothing Manufacturing"},
        { value:"2249P", text:"Clothing Manufacturing n.e.c."},
        { value:"2250P", text:"Footwear Manufacturing"},
        { value:"2261P", text:"Leather, tanning &amp; Fur Dressing"},
        { value:"2262P", text:"Leather &amp; Leather Substitute Product Manufacturing"},
        { value:"2311P", text:"Log Sawmilling"},
        { value:"2312P", text:"Wood Chipping"},
        { value:"2313P", text:"Timber Resawing &amp; Dressing"},
        { value:"2321P", text:"Plywood &amp; Veneer Manufacturing"},
        { value:"2322P", text:"Fabricated Wood Manufacturing"},
        { value:"2323P", text:"Wooden Structural Component Manufacturing"},
        { value:"2329P", text:"Wood Product Manufacturing n.e.c."},
        { value:"2331P", text:"Pulp, Paper &amp; Paperboard Manufacturing"},
        { value:"2332P", text:"Solid Paperboard Container Manufacturing"},
        { value:"2333P", text:"Corrugated Paperboard Container Manufacturing"},
        { value:"2334P", text:"Paper Bag &amp; Sack Manufacturing"},
        { value:"2339P", text:"Paper &amp; Paper Product Manufacturing n.e.c."},
        { value:"2411P", text:"Paper Stationery Manufacturing"},
        { value:"2412P", text:"Printing"},
        { value:"2413P", text:"Services, to Printing"},
        { value:"2421P", text:"Newspaper Printing or Publishing"},
        { value:"2422P", text:"Other Periodical Publishing"},
        { value:"2423P", text:"Book &amp; Other Publishing"},
        { value:"2430P", text:"Recorded Media Manufacturing &amp; Publishing"},
        { value:"2510P", text:"Petroleum Refining"},
        { value:"2520P", text:"Petroleum &amp; Coal Product Manufacturing n.e.c."},
        { value:"2531P", text:"Fertiliser Manufacturing"},
        { value:"2532P", text:"Industrial Gas Manufacturing"},
        { value:"2533P", text:"Synthetic Resin Manufacturing"},
        { value:"2534P", text:"Organic Industrial Chemical Manufacturing n.e.c."},
        { value:"2535P", text:"Inorganic Industrial Chemical Manufacturing n.e.c."},
        { value:"2541P", text:"Explosive Manufacturing"},
        { value:"2542P", text:"Paint Manufacturing"},
        { value:"2543P", text:"Medicinal &amp; Pharmaceutical Product Manufacturing"},
        { value:"2544P", text:"Pesticide Manufacturing"},
        { value:"2545P", text:"Soap &amp; Other Detergent Manufacturing"},
        { value:"2546P", text:"Cosmetic &amp;, toiletry Preparation Manufacturing"},
        { value:"2547P", text:"Ink Manufacturing"},
        { value:"2549P", text:"Chemical Product Manufacturing n.e.c."},
        { value:"2551P", text:"Rubber, tyre Manufacturing"},
        { value:"2559P", text:"Rubber Product Manufacturing n.e.c."},
        { value:"2561P", text:"Plastic Blow Moulded Product Manufacturing"},
        { value:"2562P", text:"Plastic Extruded Product Manufacturing"},
        { value:"2563P", text:"Plastic Bag &amp; Film Product Manufacturing"},
        { value:"2564P", text:"Plastic Product Rigid Reinforced Manufacturing"},
        { value:"2565P", text:"Plastic Foam Product Manufacturing"},
        { value:"2566P", text:"Plastic Injection Manufacturing Moulded Product"},
        { value:"2610P", text:"Glass &amp; Glass Product Manufacturing"},
        { value:"2621P", text:"Clay Brick Manufacturing"},
        { value:"2622P", text:"Ceramic Product Manufacturing"},
        { value:"2623P", text:"Ceramic, tile &amp; Pipe Manufacturing"},
        { value:"2629P", text:"Ceramic Product Manufacturing n.e.c."},
        { value:"2631P", text:"Cement &amp; Lime Manufacturing"},
        { value:"2632P", text:"Plaster Product Manufacturing"},
        { value:"2633P", text:"Concrete Slurry Manufacturing"},
        { value:"2634P", text:"Concrete Pipe &amp; Box Culvert Manufacturing"},
        { value:"2635P", text:"Concrete Product Manufacturing n.e.c."},
        { value:"2640P", text:"Non-Metallic Mineral Product Manufacturing n.e.c."},
        { value:"2711P", text:"Basic Iron &amp; Steel Manufacturing"},
        { value:"2712P", text:"Iron &amp; Steel Casting &amp; Forging"},
        { value:"2713P", text:"Steel Pipe &amp;, tube Manufacturing"},
        { value:"2721P", text:"Alumina Production"},
        { value:"2722P", text:"Aluminium Smelting"},
        { value:"2723P", text:"Copper, Silver, Lead &amp; Zinc Smelting, Refining"},
        { value:"2729P", text:"Basic Non-ferrous Metal Manufacturing n.e.c."},
        { value:"2731P", text:"Aluminium Rollings, Drawing, Extruding"},
        { value:"2732P", text:"Non-Ferrous Metal Rolling, Drawing, Extruding n.e.c."},
        { value:"2733P", text:"Non-Ferrous Metal Casting"},
        { value:"2741P", text:"Structural Steel Fabricating"},
        { value:"2742P", text:"Architectural Aluminium Product Manufacturing"},
        { value:"2749P", text:"Structural Metal Product Manufacturing n.e.c."},
        { value:"2751P", text:"Metal Container Manufacturing"},
        { value:"2759P", text:"Sheet Metal Product Manufacturing n.e.c."},
        { value:"2761P", text:"Hand, tool &amp; General Hardware Manufacturing"},
        { value:"2762P", text:"Spring &amp; Wire Product Manufacturing"},
        { value:"2763P", text:"Nut, Bolt, Screw &amp; Rivet Manufacturing"},
        { value:"2764P", text:"Metal Coating &amp; Finishing"},
        { value:"2765P", text:"Non-Ferrous Pipe Fitting Manufacturing"},
        { value:"2769P", text:"Fabricated Metal Product Manufacturing n.e.c."},
        { value:"2811P", text:"Motor Vehicle Manufacturing"},
        { value:"2812P", text:"Motor Vehicle Body Manufacturing"},
        { value:"2813P", text:"Automotive Electrical &amp; Instrument Manufacturing"},
        { value:"2819P", text:"Automotive Component Manufacturing n.e.c."},
        { value:"2821P", text:"Shipbuilding"},
        { value:"2822P", text:"Boatbuilding"},
        { value:"2823P", text:"Railway Equipment Manufacturing"},
        { value:"2824P", text:"Aircraft Manufacturing"},
        { value:"2829P", text:"Transport Equipment Manufacturing n.e.c."},
        { value:"2831P", text:"Photographic &amp; Optical Good Manufacturing"},
        { value:"2832P", text:"Medical &amp; Surgical Equipment Manufacturing"},
        { value:"2839P", text:"Professional &amp; Scientific Equipment Manufacturing n.e.c."},
        { value:"2841P", text:"Computer &amp; Business Machine Manufacturing"},
        { value:"2842P", text:"Telecommunication, Broadcasting &amp;, transceiving Equipment"},
        { value:"2849P", text:"Electronic Equipment Manufacturing n.e.c."},
        { value:"2851P", text:"Household Appliance Manufacturing"},
        { value:"2852P", text:"Electric Cable &amp; Wire Manufacturing"},
        { value:"2853P", text:"Battery Manufacturing"},
        { value:"2854P", text:"Electric Light &amp; Sign Manufacturing"},
        { value:"2859P", text:"Electrical Equipment Manufacturing n.e.c."},
        { value:"2861P", text:"Agricultural Machinery Manufacturing"},
        { value:"2862P", text:"Mining &amp; Construction Machinery Manufacturing"},
        { value:"2863P", text:"Food Processing Machinery Manufacturing"},
        { value:"2864P", text:"Machine, tool &amp; Part Manufacturing"},
        { value:"2865P", text:"Lifting &amp; Material Handling Equipment Manufacturing"},
        { value:"2866P", text:"Pump &amp; Compressor Manufacturing"},
        { value:"2867P", text:"Commercial Space Heating &amp; Cooling Equipment Manufacturing"},
        { value:"2869P", text:"Industrial Machinery &amp; Equipment Manufacturing n.e.c."},
        { value:"2911P", text:"Prefabricated Metal Building Manufacturing"},
        { value:"2919P", text:"Prefabricated Building Manufacturing n.e.c."},
        { value:"2921P", text:"Wooden Furniture &amp; Upholstered Seat Manufacturing"},
        { value:"2922P", text:"Sheet Metal Furniture Manufacturing"},
        { value:"2923P", text:"Mattress Manufacturing (Except Rubber )"},
        { value:"2929P", text:"Furniture Manufacturing n.e.c."},
        { value:"2941P", text:"Jewellery &amp; Silverware Manufacturing"},
        { value:"2942P", text:"Toy &amp; Sporting Good Manufacturing"},
        { value:"2949P", text:"Manufacturing n.e.c."},
        { value:"3000P", text:"Utilities: Electricity, gas and water"},
        { value:"3610P", text:"Electricity Supply"},
        { value:"3620P", text:"Gas Supply"},
        { value:"3701P", text:"Water Supply"},
        { value:"3702P", text:"Sewerage &amp; Drainage Services"},
        { value:"4100P", text:"Construction"},
        { value:"4111P", text:"House Construction"},
        { value:"4112P", text:"Residential Building Construction n.e.c."},
        { value:"4113P", text:"Non-Residential Building Construction"},
        { value:"4114P", text:"Industrial Building Construction"},
        { value:"4115P", text:"Retail Building Construction"},
        { value:"4116P", text:"Hotel/Resort Building Construction"},
        { value:"4117P", text:"Commercial Building Construction"},
        { value:"4121P", text:"Road &amp; Bridge Construction"},
        { value:"4122P", text:"Non-Building Construction n.e.c"},
        { value:"4210P", text:"Site Preparation Services"},
        { value:"4221P", text:"Concreting Services"},
        { value:"4222P", text:"Bricklaying Services"},
        { value:"4223P", text:"Roofing Services"},
        { value:"4224P", text:"Structural Steel Erection Services"},
        { value:"4231P", text:"Plumbing Services"},
        { value:"4232P", text:"Electrical Services"},
        { value:"4233P", text:"Air Conditioning &amp; Heating Services"},
        { value:"4234P", text:"Fire &amp; Security System Services"},
        { value:"4241P", text:"Plastering &amp; Ceiling Services"},
        { value:"4242P", text:"Carpentry Services"},
        { value:"4243P", text:"Tiling &amp; Carpeting Services"},
        { value:"4244P", text:"Painting &amp; Decorating Services"},
        { value:"4245P", text:"Glazing Services"},
        { value:"4251P", text:"Landscaping Services"},
        { value:"4259P", text:"Construction Services n.e.c."},
        { value:"4500P", text:"Wholesale and retail, trade"},
        { value:"4511P", text:"Wool Wholesaling"},
        { value:"4512P", text:"Cereal Grain Wholesaling"},
        { value:"4519P", text:"Farm Produce &amp; Supplies Wholesaling n.e.c."},
        { value:"4521P", text:"Petroleum Product Wholesaling"},
        { value:"4522P", text:"Metal &amp; Mineral Wholesaling"},
        { value:"4523P", text:"Chemical Wholesaling"},
        { value:"4531P", text:"Timber Wholesaling"},
        { value:"4539P", text:"Builders Supplies Wholesaling n.e.c."},
        { value:"4611P", text:"Farm &amp; Construction Machinery Wholesaling"},
        { value:"4612P", text:"Professional Equipment Wholesaling"},
        { value:"4613P", text:"Computer Wholesaling"},
        { value:"4614P", text:"Business Machine Wholesaling n.e.c."},
        { value:"4615P", text:"Electrical &amp; Electronic Equipment Wholesaling n.e.c."},
        { value:"4619P", text:"Machinery &amp; Equipment Wholesaling n.e.c."},
        { value:"4621P", text:"Car Wholesaling"},
        { value:"4622P", text:"Commercial Vehicle Wholesaling"},
        { value:"4623P", text:"Motor Vehicle New Part Dealing"},
        { value:"4624P", text:"Motor Vehicle Dismantling &amp; Used Part Dealing"},
        { value:"4711P", text:"Meat Wholesaling"},
        { value:"4712P", text:"Poultry &amp; Smallgood Wholesaling"},
        { value:"4713P", text:"Dairy Produce Wholesaling"},
        { value:"4714P", text:"Fish Wholesaling"},
        { value:"4715P", text:"Fruit &amp; Vegetable Wholesaling"},
        { value:"4716P", text:"Confectionery &amp; Soft Drink Wholesaling"},
        { value:"4717P", text:"Liquor Wholesaling"},
        { value:"4718P", text:"Tobacco Product Wholesaling"},
        { value:"4719P", text:"Grocery Wholesaling n.e.c."},
        { value:"4721P", text:"Textile Product Wholesaling"},
        { value:"4722P", text:"Clothing Wholesaling"},
        { value:"4723P", text:"Footwear Wholesaling"},
        { value:"4731P", text:"Household Appliance Wholesaling"},
        { value:"4732P", text:"Furniture Wholesaling"},
        { value:"4733P", text:"Floor Covering Wholesaling"},
        { value:"4739P", text:"Household Good Wholesaling n.e.c."},
        { value:"4791P", text:"Photographic Equipment Wholesaling"},
        { value:"4792P", text:"Jewellery &amp; Watch Wholesaling"},
        { value:"4793P", text:"Toy &amp; Sporting Good Wholesaling"},
        { value:"4794P", text:"Book &amp; Magazine Wholesaling"},
        { value:"4795P", text:"Paper Product Wholesaling"},
        { value:"4796P", text:"Pharmaceutical &amp;, toiletry Wholesaling"},
        { value:"4799P", text:"Wholesaling n.e.c."},
        { value:"5110P", text:"Supermarket &amp; Grocery Stores"},
        { value:"5121P", text:"Fresh Meat, Fish &amp; Poultry Retailing"},
        { value:"5122P", text:"Fruit &amp; Vegetable Retailing"},
        { value:"5123P", text:"Liquor Retailing"},
        { value:"5124P", text:"Bread &amp; Cake Retailing"},
        { value:"5125P", text:"Takeaway Food Retailing"},
        { value:"5126P", text:"Milk Vending"},
        { value:"5129P", text:"Specialised Food Retailing n.e.c."},
        { value:"5210P", text:"Department Stores"},
        { value:"5221P", text:"Clothing Retailing"},
        { value:"5222P", text:"Footwear Retailing"},
        { value:"5223P", text:"Fabric &amp; Other Soft Good Retailing"},
        { value:"5231P", text:"Furniture Retailing"},
        { value:"5232P", text:"Floor Covering Retailing"},
        { value:"5233P", text:"Domestic Hardware &amp; Houseware Retailing"},
        { value:"5234P", text:"Domestic Appliance Retailing"},
        { value:"5235P", text:"Recorded Music Retailing"},
        { value:"5241P", text:"Sport &amp; Camping Equipment Retailing"},
        { value:"5242P", text:"Toy &amp; Game Retailing"},
        { value:"5243P", text:"Newspaper, Book &amp; Stationery Retailing"},
        { value:"5244P", text:"Photographic Equipment Retailing"},
        { value:"5245P", text:"Marine Equipment Retailing"},
        { value:"5251P", text:"Pharmaceutical, Cosmetic &amp;, toiletry Retailing"},
        { value:"5252P", text:"Antique &amp; Used Good Retailing"},
        { value:"5253P", text:"Garden Equipment Retailing"},
        { value:"5254P", text:"Flower Retailing"},
        { value:"5255P", text:"Watch &amp; Jewellery Retailing"},
        { value:"5259P", text:"Retailing n.e.c."},
        { value:"5261P", text:"Household Equipment Repair Services (Electrical)"},
        { value:"5269P", text:"Household Equipment Repair Services n.e.c."},
        { value:"5300P", text:"Service stations and mechanical workshops"},
        { value:"5311P", text:"Car Retailing"},
        { value:"5312P", text:"Motor Cycle Dealing"},
        { value:"5313P", text:"Trailer &amp; Caravan Dealing"},
        { value:"5321P", text:"Automotive Fuel Retailing"},
        { value:"5322P", text:"Automotive Electrical Services"},
        { value:"5323P", text:"Smash Repairing"},
        { value:"5324P", text:"Tyre Retailing"},
        { value:"5329P", text:"Automotive Repair &amp; Services n.e.c."},
        { value:"5710P", text:"Accommodation"},
        { value:"5720P", text:"Pubs,, taverns &amp; Bars"},
        { value:"5730P", text:"Cafes &amp; Restaurants"},
        { value:"5740P", text:"Clubs (Hospitality)"},
        { value:"6100P", text:"Transport and storage"},
        { value:"6110P", text:"Road Freight, transport"},
        { value:"6121P", text:"Long Distance Bus, transport"},
        { value:"6122P", text:"Short Distance Bus, transport (including, tramway)"},
        { value:"6123P", text:"Taxi &amp; Other Road Passenger, transport"},
        { value:"6200P", text:"Rail, transport"},
        { value:"6301P", text:"International Sea, transport"},
        { value:"6302P", text:"Coastal Water, transport"},
        { value:"6303P", text:"Inland Water, transport"},
        { value:"6401P", text:"Scheduled International Air, transport"},
        { value:"6402P", text:"Scheduled Domestic Air, transport"},
        { value:"6403P", text:"Air &amp; Space, transport"},
        { value:"6404P", text:"Non-Schedule Air &amp; Space, transport"},
        { value:"6501P", text:"Pipeline, transport"},
        { value:"6509P", text:"Transport n.e.c."},
        { value:"6611P", text:"Parking Services"},
        { value:"6619P", text:"Services, to Road, transport n.e.c."},
        { value:"6621P", text:"Stevedoring"},
        { value:"6622P", text:"Water, transport, terminals"},
        { value:"6623P", text:"Port Operators"},
        { value:"6629P", text:"Services, to Water, transport n.e.c."},
        { value:"6630P", text:"Services, to Air, transport"},
        { value:"6641P", text:"Travel Agency Services"},
        { value:"6642P", text:"Road Freight Forwarding"},
        { value:"6643P", text:"Freight Forwarding (except Road)"},
        { value:"6644P", text:"Customs Agency Services"},
        { value:"6649P", text:"Services, to, transport n.e.c."},
        { value:"6701P", text:"Grain Storage"},
        { value:"6709P", text:"Storage n.e.c."},
        { value:"7100P", text:"Communications"},
        { value:"7111P", text:"Postal Services"},
        { value:"7112P", text:"Courier Services"},
        { value:"7120P", text:"Telecommunication Services"},
        { value:"7300P", text:"Financial Services, professional consulting services, real estate agents"},
        { value:"7310P", text:"Central Bank"},
        { value:"7321P", text:"Banks"},
        { value:"7322P", text:"Building Societies"},
        { value:"7323P", text:"Credit Unions"},
        { value:"7324P", text:"Money Market Dealers"},
        { value:"7329P", text:"Deposit, taking Financiers n.e.c."},
        { value:"7330P", text:"Other Financiers"},
        { value:"7340P", text:"Financial Asset Investors"},
        { value:"7411P", text:"Life Insurance"},
        { value:"7412P", text:"Superannuation Funds"},
        { value:"7421P", text:"Health Insurance"},
        { value:"7422P", text:"General Insurance"},
        { value:"7511P", text:"Financial Asset Broking Services"},
        { value:"7519P", text:"Services, to Finance &amp; Investment n.e.c."},
        { value:"7520P", text:"Services, to Insurance"},
        { value:"7711P", text:"Residential Property Operators or Investors"},
        { value:"7712P", text:"Commercial Property Developers"},
        { value:"7713P", text:"Residential Property Developers"},
        { value:"7714P", text:"Industrial Property Developers or Investors"},
        { value:"7715P", text:"Retail Property Developers or Investors"},
        { value:"7716P", text:"Hotel/Resort Property Developers or Investors"},
        { value:"7717P", text:"Commercial Property Developers or Investors"},
        { value:"7718P", text:"Non-Residential Property Operators, Developers or Investors"},
        { value:"7720P", text:"Real Estate Agents"},
        { value:"7730P", text:"Non-Financial Asset Investors"},
        { value:"7741P", text:"Motor Vehicle Hiring"},
        { value:"7742P", text:"Other, transport Equipment Leasing"},
        { value:"7743P", text:"Plant Hiring or Leasing"},
        { value:"7810P", text:"Scientific Research"},
        { value:"7821P", text:"Architectural Services"},
        { value:"7822P", text:"Surveying Services"},
        { value:"7823P", text:"Consulting Engineering Services"},
        { value:"7829P", text:"Technical Services n.e.c."},
        { value:"7831P", text:"Data Processing Services"},
        { value:"7832P", text:"Information Storage &amp; Retrieval Services"},
        { value:"7833P", text:"Computer Maintenance Services"},
        { value:"7834P", text:"Computer Consultancy Services"},
        { value:"7841P", text:"Legal Services"},
        { value:"7842P", text:"Accounting Services"},
        { value:"7851P", text:"Advertising Services"},
        { value:"7852P", text:"Commercial Art &amp; Display Services"},
        { value:"7853P", text:"Market Research Services"},
        { value:"7854P", text:"Business Administrative Services"},
        { value:"7855P", text:"Business Management Services"},
        { value:"7861P", text:"Employment Placement Services"},
        { value:"7862P", text:"Contract Staff Services"},
        { value:"7863P", text:"Secretarial Services"},
        { value:"7864P", text:"Security &amp; Investigative Services (except Police)"},
        { value:"7865P", text:"Pest Control Services"},
        { value:"7866P", text:"Cleaning Services"},
        { value:"7867P", text:"Contract Packing Services n.e.c."},
        { value:"7869P", text:"Business Services n.e.c."},
        { value:"8111P", text:"Central Government Administration"},
        { value:"8112P", text:"State Government Administration"},
        { value:"8113P", text:"Local Government Administration"},
        { value:"8120P", text:"Justice"},
        { value:"8130P", text:"Foreign Government Representation"},
        { value:"8200P", text:"Defence"},
        { value:"8400P", text:"Community services, schools, charities, non-profit organisations"},
        { value:"8410P", text:"Preschool Education"},
        { value:"8421P", text:"Primary Education"},
        { value:"8422P", text:"Secondary Education"},
        { value:"8423P", text:"Combined Primary &amp; Secondary Education"},
        { value:"8424P", text:"Special School Education"},
        { value:"8431P", text:"Higher Education"},
        { value:"8432P", text:"Technical &amp; Further Education"},
        { value:"8440P", text:"Other Education"},
        { value:"8600P", text:"Health services, doctors, dentists, veterinarians"},
        { value:"8611P", text:"Hospitals (except Psychiatric Hospitals)"},
        { value:"8612P", text:"Psychiatric Hospitals"},
        { value:"8613P", text:"Nursing Homes"},
        { value:"8621P", text:"General Practice Medical Services"},
        { value:"8622P", text:"Specialist Medical Services"},
        { value:"8623P", text:"Dental Services"},
        { value:"8631P", text:"Pathology Services"},
        { value:"8632P", text:"Optometry &amp; Optical Dispensing"},
        { value:"8633P", text:"Ambulance Services"},
        { value:"8634P", text:"Community Health Centres"},
        { value:"8635P", text:"Physiotherapy Services"},
        { value:"8636P", text:"Chiropractic Services"},
        { value:"8639P", text:"Health Services n.e.c."},
        { value:"8640P", text:"Veterinary Services"},
        { value:"8710P", text:"Child Care Services"},
        { value:"8721P", text:"Accommodation for, the Aged"},
        { value:"8722P", text:"Residential Care Services n.e.c."},
        { value:"8729P", text:"Non-Residential Care Services n.e.c."},
        { value:"9100P", text:"Recreational, hospitality, entertainment"},
        { value:"9111P", text:"Film &amp; Video Production"},
        { value:"9112P", text:"Film &amp; Video Distribution"},
        { value:"9113P", text:"Motion Picture Exhibition"},
        { value:"9121P", text:"Radio Services"},
        { value:"9122P", text:"Television Services"},
        { value:"9210P", text:"Libraries"},
        { value:"9220P", text:"Museums"},
        { value:"9231P", text:"Zoological &amp; Botanic Gardens"},
        { value:"9239P", text:"Recreational Parks &amp; Gardens"},
        { value:"9241P", text:"Music &amp;, theatre Production"},
        { value:"9242P", text:"Creative Arts"},
        { value:"9251P", text:"Sound Recording Studios"},
        { value:"9252P", text:"Performing Arts Venues"},
        { value:"9259P", text:"Services, to, the Arts n.e.c."},
        { value:"9311P", text:"Horse &amp; Dog Racing"},
        { value:"9312P", text:"Sports Grounds &amp; Facilities n.e.c."},
        { value:"9319P", text:"Sports &amp; Services, to Sports n.e.c."},
        { value:"9321P", text:"Lotteries"},
        { value:"9322P", text:"Casinos"},
        { value:"9329P", text:"Gambling Services n.e.c."},
        { value:"9330P", text:"Other Recreation Services"},
        { value:"9511P", text:"Video Hire Outlets"},
        { value:"9519P", text:"Personal &amp; Household Goods Hiring n.e.c."},
        { value:"9521P", text:"Laundries &amp; Dry Cleaners"},
        { value:"9522P", text:"Photographic Film Processing"},
        { value:"9523P", text:"Photographic Studios"},
        { value:"9524P", text:"Funeral Directors, Crematoria &amp; Cemeteries"},
        { value:"9525P", text:"Gardening Services"},
        { value:"9526P", text:"Hairdressing &amp; Beauty Salons"},
        { value:"9529P", text:"Personal Services n.e.c."},
        { value:"9610P", text:"Religious Organisations"},
        { value:"9621P", text:"Business &amp; Professional Associations"},
        { value:"9622P", text:"Labour Associations"},
        { value:"9629P", text:"Interest Groups n.e.c."},
        { value:"9631P", text:"Police Services"},
        { value:"9632P", text:"Corrective Centres"},
        { value:"9633P", text:"Fire Brigade Services"},
        { value:"9634P", text:"Waste Disposal Services"},
        { value:"9700P", text:"Private Households Employing Staff"},
    ],
    companyType: [
        "Ltd",
        "PtyLtd",
        "Pty",
        "ForeignCompany",
        "NL",
    ],
    trustType: [
        "DiscretionaryTrust",
        "BareTrust",
        "SuperannuationTrust",
        "Other",
        "UnitTrust",
    ]
}

 

