<template>
    <v-card class="mx-auto" :disabled="isDisabled" tile flat>

        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">


            <v-container pa-0 fluid>
                <v-row>

                    <v-col cols="12">
                        <ValidationObserver ref="observer" tag="form">

                                <div v-show="false">
                                    <div
                                        v-for="(field, fkey) in fields"
                                        :key="fkey">

                                        <ValidationProvider
                                            v-slot="{ errors }"
                                            :name="field.meta | formalText"
                                            rules="required"
                                            :vid="field.meta">
                                            <v-text-field
                                                v-model="resourceObj[field.meta]"
                                                :error-messages="errors"
                                                :label="field.label">
                                            </v-text-field>
                                        </ValidationProvider>

                                    </div>
                                </div>


<v-sheet 
    class="mx-auto rounded-xl mb-6 mb-md-16 px-6 px-md-12 py-4 py-md-8" 
    elevation="0" 
    max-width="1088" 
    :style="`background-color: ${hexToRGBA(getActiveTheme.colors.primary, 0.1)}`">

    <div class="d-flex justify-space-between align-center mb-4">
        <div class="text-h5 font-weight-medium">Requirements</div>
        <div v-if="vb.smAndDown">
            <v-btn
                :x-small="vb.smAndDown"
                color="primary"
                class="px-10"
                rounded
                @click="editDialog = true"
                depressed>
                Edit
            </v-btn>
        </div>
    </div>

    <div class="d-flex justify-space-between flex-wrap">
        
        <template v-if="vb.mdAndUp">    
            <v-sheet class="pb-4" color="transparent" width="auto" >
                <div>Loan amount</div>
                <div class="font-weight-medium text-no-wrap font-size-18">
                    {{ editFormFields.purchases.attributes["required-loan-amount"] | currency }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="auto" >
                <div>Purchase price</div>
                <div class="font-weight-medium text-no-wrap font-size-18">
                    {{ editFormFields.purchases.attributes["purchase-price"] | currency }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="auto" >
                <div>Balloon {{ vb.mdAndUp ? 'payment' : ''}}</div>
                <div class="font-weight-medium text-no-wrap font-size-18">
                    {{ editFormFields.loans.attributes["requested-balloon"] | currency }}
                </div>
            </v-sheet>
            <v-sheet v-if="vb.smAndDown" class="pb-4" color="transparent" width="auto" >
                <div>Vehicle condition</div>
                <div class="font-weight-medium text-no-wrap font-size-18">
                    {{ editFormFields.purchases.attributes["condition"] }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="auto" >
                <div>Vehicle year</div>
                <div class="font-weight-medium text-no-wrap font-size-18">
                    {{ editFormFields.purchases.attributes["year-of-manufacture"] }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="auto" >
                <div>Deposit</div>
                <div class="font-weight-medium text-no-wrap font-size-18">
                    {{ editFormFields.purchases.attributes["cash-deposit"] | currency }}
                </div>
            </v-sheet>
        </template>    
        <template v-else>    
            <v-sheet v-if="vb.smAndDown" class="pb-4" color="transparent" width="33.33%" >
                <div class="font-size-10">Vehicle condition</div>
                <div :class="`font-weight-medium text-no-wrap font-size-16`">
                    {{ editFormFields.purchases.attributes["condition"] }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="33.33%" >
                <div class="font-size-10">Vehicle year</div>
                <div :class="`font-weight-medium text-no-wrap font-size-16`">
                    {{ editFormFields.purchases.attributes["year-of-manufacture"] }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="33.33%" >
                <div class="font-size-10">Balloon {{ vb.mdAndUp ? 'payment' : ''}}</div>
                <div :class="`font-weight-medium text-no-wrap font-size-16`">
                    {{ editFormFields.loans.attributes["requested-balloon"] | currency }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="33.33%" >
                <div class="font-size-10">Purchase price</div>
                <div :class="`font-weight-medium text-no-wrap font-size-16`">
                    {{ editFormFields.purchases.attributes["purchase-price"] | currency }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="33.33%" >
                <div class="font-size-10">Deposit</div>
                <div :class="`font-weight-medium text-no-wrap font-size-16`">
                    {{ editFormFields.purchases.attributes["cash-deposit"] | currency }}
                </div>
            </v-sheet>
            <v-sheet class="pb-4" color="transparent" width="33.33%" >
                <div class="font-size-10">Loan amount</div>
                <div :class="`font-weight-medium text-no-wrap font-size-16`">
                    {{ editFormFields.purchases.attributes["required-loan-amount"] | currency }}
                </div>
            </v-sheet>
        </template>    

        <v-sheet class="pb-4" v-if="vb.mdAndUp" color="transparent" :width="vb.mdAndUp ? 'auto' : '33.33%'">
            <v-btn
                color="primary"
                class="px-10"
                rounded
                @click="editDialog = true"
                depressed>
                Edit
            </v-btn>
        </v-sheet>
    </div>

</v-sheet>

<div class="text-center mb-4 mb-md-8">
    <h1 class="flex__step-header-title line-height-32">Your top{{ topLenders.length == 3 ? " three" : ' '}} loan matches</h1>
</div>

 <v-sheet
    class="mx-auto mb-16"
    elevation="0"
    max-width="1088"
  >

    <v-slide-group
      v-model="selectedTopLender"
      class="top-lenders-slide-group d-flex flex-wrap"
      center-active
      :show-arrows="false"

    >
      <v-slide-item
        v-for="lender in topLenders"
        :key="lender.name"
        v-slot="{ active, toggle }"
      >

        <v-card
          class="d-flex  flex-column ma-2 ma-sm-4 rounded-xl lender-item justify-space-between"
          outlined
          :ripple="false"
          width="100%"
          :min-width="topLoanMinWidth"
          :max-width="topLoanMaxWidth"
        >
                <v-card-title class="pa-0 ma-0 mb-n8 pa-2">
                    <v-spacer />
                    <v-btn
                        fab text                    
                        depressed
                        x-small
                        dark
                        @click="showInfoDialog(lender)"
                        color="secondary">
                        <v-icon size="25">
                            mdi-information-outline
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-title class="pa-0 justify-center">
                    <v-img 
                        max-width="120"
                        min-height="80"
                        class="flex-brand" 
                        :src="getLenderLogo(lender['logo-class'])" 
                        position="center left" 
                        cover>
                    </v-img>
                </v-card-title>
                <hr class="outline" :style="`background-color: ${hexToRGBA('#000000', 0.1)}`" />  

                <v-card-text class="py-6">
                    <div class="d-flex mb-6">
                        <div style="width: 50%">
                            <div class="px-2">Monthly repayment</div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">{{ lender['actual-payment']['monthly-payment'] | currency }}</div>
                        </div> 
                        <div>
                            <div class="px-2">Interest rate</div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">{{ (lender['actual-payment']['customer-rate'] ) | percentage }}</div>
                        </div> 
                    </div> 
                    <div class="d-flex">
                        <div style="width: 50%">
                            <div class="px-2">Comparison rate</div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">{{ lender['actual-payment']['comparison-rate'] | percentage }}</div>
                        </div> 
                        <div>
                            <div class="px-2">Loan Term</div>
                            <div class="px-2 body-1 font-weight-medium font-size-22">{{ lender['actual-payment']['term-in-months']  }} Months</div>
                        </div> 
                    </div> 
                </v-card-text>

                <v-card-actions
                    class="secondary"
                    @click="showPeopleModal(lender.name, lender)"
                >
                    <div class="white--text body-1 font-size-18 py-4">
                        Apply Now
                    </div>
                </v-card-actions>

        </v-card>



      </v-slide-item>
    </v-slide-group>

</v-sheet>

<div class="text-center mb-8">
    <h1 class="flex__step-header-title">Other Results</h1>
</div>

                                    
                                <v-data-table
                                    :loading="isDisabled"
                                    loading-text="Fetching your quotes..."
                                    no-data-text=""
                                    v-if="validComponentData"
                                    :headers="headers"
                                    :items="otherLenders"
                                    hide-default-footer
                                    :mobile-breakpoint="0"
                                    hide-default-header
                                    :style=" vb.mdAndUp ? 'width: 1088px;' : 'width: 100%;'"
                                    outlined
                                    class="v-data-table-2 mx-auto">

                                    <!-- Body -->
                                    <template v-slot:item.compare="{ item, index }">
                                        <v-card outlined class="card-row ma-2 pa-2 rounded-xl">

                                            <!-- # md and up design -->
                                            <div v-if="vb.mdAndUp" class="d-flex justify-space-between align-center">
                                                <div class="d-flex flex-column justify-start ml-2">
                                                    <div class="d-flex">
                                                        <div class="px-2">
                                                            <div v-if="hideLenderIndentity" class="title font-weight-bold pt-4">
                                                                {{ item['alias'] }}
                                                            </div>
                                                            <v-img 
                                                                v-else 
                                                                max-width="80"
                                                                class="flex-brand" 
                                                                :src="getLenderLogo(item['logo-class'])" 
                                                                position="center left">
                                                            </v-img>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column justify-start ml-4">
                                                    <p class="ma-0 caption ">Loan Term</p>
                                                    <p class="font-weight-medium td-inner ma-0">{{ item['actual-payment']['term-in-months']  }} Months</p>
                                                </div>

                                                <div class="d-flex flex-column justify-start ml-4">
                                                    <p class="my-0 caption">Interest Rate</p>
                                                    <p class="font-weight-medium td-inner ma-0">{{ (item['actual-payment']['customer-rate'] ) | percentage }}</p>
                                                </div>

                                                <div class="d-flex flex-column justify-start ml-4">
                                                    <p class="my-0 caption">Comparison Rate</p>
                                                    <p class="font-weight-medium td-inner ma-0">{{ item['actual-payment']['comparison-rate'] | percentage }}</p>
                                                </div>

                                                <div class="d-flex flex-column justify-start ml-4">
                                                    <p class="my-0 caption">Monthly repayment</p>
                                                    <p class="font-weight-medium td-inner ma-0">{{ item['actual-payment']['monthly-payment'] | currency }}</p>
                                                </div>

                                                <div class="inner end ma-0 mr-2 pa-0">
                                                    <v-btn class="mr-2 px-6" color="secondary" outlined @click="showInfoDialog(item)" small depressed> More Info</v-btn>
                                                    <v-btn 
                                                        :color="resourceObj['selected-lender-quote-algorithm-slug'] === item.slug ? 'secondary is-disabled' : 'secondary'"
                                                        :disabled="disabled"
                                                        class="px-6"
                                                        @click="showPeopleModal(item.name, item)"
                                                        small
                                                        depressed>{{ resourceObj['selected-lender-quote-algorithm-slug'] === item.slug ? 'Applied' : applyButtonLabel }}
                                                    </v-btn>
                                                </div>
                                            </div>

                                            <!-- # mobile resposive -->
                                            <div v-else>
                                                <div class="d-flex flex-column justify-center">
                                                    <div class="d-flex justify-center">
                                                        <div class="px-2">
                                                            <div v-if="hideLenderIndentity" class="title font-weight-bold pt-4">
                                                                {{ item['alias'] }}
                                                            </div>
                                                            <v-img 
                                                                v-else 
                                                                max-width="80"
                                                                class="flex-brand" 
                                                                :src="getLenderLogo(item['logo-class'])" 
                                                                position="center center" 
                                                                contain>
                                                            </v-img>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-space-between">
                                                    <div class="text-center" style="width: 50%">
                                                        <div class="d-inline-block text-left">
                                                            <p class="my-0 caption">Loan Term</p>
                                                            <p class="font-weight-bold">{{ item['actual-payment']['term-in-months']  }} Months</p>
                                                            <div class="mb-3"></div>
                                                            <p class="my-0 caption">Comparison Rate</p>
                                                            <p class="font-weight-bold">{{ item['actual-payment']['comparison-rate'] | percentage }}</p>
                                                        </div>
                                                    </div>

                                                    <div class="text-center" style="width: 50%">
                                                        <div class="d-inline-block text-left">
                                                            <p class="my-0 caption">Interest Rate</p>
                                                            <p class="font-weight-bold">{{ (item['actual-payment']['customer-rate'] ) | percentage }}</p>
                                                            <div class="mb-3"></div>
                                                            <p class="my-0 caption">Monthly repayment</p>
                                                            <p class="font-weight-bold">{{ item['actual-payment']['monthly-payment'] | currency }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-center">
                                                    <div class="inner end-mobile">
                                                        <v-btn class="mr-2 px-6" color="secondary" outlined @click="showInfoDialog(item)" large depressed> More Info </v-btn>
                                                        <v-btn 
                                                            :color="resourceObj['selected-lender-quote-algorithm-slug'] === item.slug ? 'secondary is-disabled' : 'secondary'"
                                                            class="px-6"
                                                            :disabled="disabled"
                                                            @click="showPeopleModal(item.name, item)"
                                                            large
                                                            depressed>{{ resourceObj['selected-lender-quote-algorithm-slug'] === item.slug ? 'Applied' : applyButtonLabel }}
                                                        </v-btn>

                                                    </div>

                                                </div>
                                                
                                            </div>

                                        </v-card>
                                    </template>

                                </v-data-table>
                                
                                <v-card v-else tile flat class="rounded-lg mx-auto" max-width="700">
                                    <v-card-text class="text-center pa-0 pa-md-66">
                                        <v-icon size="48" color="secondary">mdi-information</v-icon>
                                        <v-spacer class="mb-6"/>
                                        <div class="body_text_color--text">
                                            <div class="headline mb-6">Sorry, we are having trouble locating options based on the information provided.</div>
                                            <p class="subtitle-1">But, please continue and submit the application with detailed submission notes, and our team will look at options using the entire panel.</p>
                                        </div>
                                    </v-card-text>
                                </v-card>

                        </ValidationObserver>
                    </v-col>

                    <v-col cols="12" class="mt-6">
                        <v-sheet max-width="854" class="mx-auto">
                            <p class="caption text-center gray" v-text="getDisclaimer"></p>
                        </v-sheet>
                    </v-col>
                    
                </v-row>
            </v-container>


        </v-card-text>



        <!-- More Info Modal -->
        <v-dialog
        max-width="520"
        v-if="dialogData"
        v-model="dialog">

            <v-card flat>

                <!-- <v-toolbar flat class="pt-3 pr-6 mb-n8">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar> -->

                <v-card-title class="py-0 px-4 px-sm-8">
                    <div v-if="hideLenderIndentity" class="title font-weight-bold py-6 pl-4 mx-auto">
                        {{ dialogData['alias'] }}
                    </div>
                    <v-img
                        v-else
                        max-width="100"
                        class="mx-auto"
                        :src="getLenderLogo(dialogData['logo-class'])" 
                        position="center" 
                        contain>
                    </v-img>
                    <v-btn icon @click="dialog = false" fab class="ml-n6">
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="px-4 px-sm-8 pt-0 pb-10 text-center"> 

                    <v-container fluid pa-0>

                        <v-row class="mt-n4" :class="vb.mdAndUp ? 'mx-1' : 'mx-0'">

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 lightgray my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Requested Amount</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ required_amount }}</div> 
                                </div>
                            </v-col>
                            
                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Origination Fee</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['broker-fee'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 lightgray my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Bank Setup Fees</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['bank-fees'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Amount Financed</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['naf'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-6 my-1 lightgray">
                                <div class="font-weight-bold subtitle-1"></div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'"></div> 
                                </div>
                            </v-col>
                            
                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Loan Term</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['term-in-months'] }} Months</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 lightgray my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Weekly Payment</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ ((dialogData['actual-payment']['monthly-payment'] * 12) / 52) | currency }}</div> 
                                </div>
                            </v-col>

                        </v-row>

                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>


        <!-- Edit Modal -->
        <v-dialog
            max-width="520"
            persistent
            scrollable
            v-model="editDialog">

            <v-card flat :disabled="editDialogSaving">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="editDialogSaving"></v-progress-linear>
                <v-card-title class="justify-space-between">

                    <h3>Edit Assumptions</h3>
                    
                    <v-btn icon @click="editDialog = false" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="form-fields-sm"> 
                    <ValidationObserver ref="editFormObserver" tag="form">

                            <div 
                                class="my-0 py-0"
                                v-for="(field, key) in editFormFields.purchases.attributes" 
                                :key="key"
                            >
                                <ValidationProvider 
                                    v-slot="{ errors }" 
                                    :name="editFormFields.labels[key]" 
                                    :rules="'required'" 
                                    :vid="key">

                                   <ButtonGroup1 
                                        v-if="isBtnGroup(key)"
                                        v-model="editFormFields.purchases.attributes[key]"
                                        :error-messages="errors"
                                        :options="['New', 'Used']"
                                        class="mb-3"
                                        :label="editFormFields.labels[key]" />

                                    <v-text-field 
                                        v-else-if="key == 'required-loan-amount'"
                                        :type="'text'"
                                        readonly
                                        :label="editFormFields.labels[key]"
                                        :error-messages="errors"
                                        v-model="editFormFields.purchases.attributes[key]"
                                        color="secondary">
                                    </v-text-field>

                                    <v-text-field 
                                        v-else
                                        :type="'text'"
                                        :label="editFormFields.labels[key]"
                                        :error-messages="errors"
                                        v-model="editFormFields.purchases.attributes[key]"
                                        color="secondary">
                                    </v-text-field>

                                </ValidationProvider>
                            </div>

                            <div 
                                class="my-0 py-0"
                                v-for="(field, key) in editFormFields.loans.attributes" 
                                :key="key"
                            >
                                <ValidationProvider 
                                    v-slot="{ errors }" 
                                    :name="editFormFields.labels[key]" 
                                    :rules="'required'" 
                                    :vid="key">

                                    <v-select
                                        v-if="key == 'sale-type'"
                                        v-model="editFormFields.loans.attributes[key]"
                                        :items="['Dealer', 'Private Sale', 'Sale & Buyback', 'Refinance']"
                                        item-text="name"
                                        item-value="id"
                                        color="secondary"
                                        :error-messages="errors"
                                        :label="editFormFields.labels[key]">
                                    </v-select>

                                    <v-text-field 
                                        v-else
                                        :type="'text'"
                                        :label="editFormFields.labels[key]"
                                        :error-messages="errors"
                                        v-model="editFormFields.loans.attributes[key]"
                                        color="secondary">
                                    </v-text-field>

                                </ValidationProvider>
                            </div>
                    
               
                    </ValidationObserver>
                </v-card-text>

                <v-card-actions class="d-block text-center pt-4 pb-8">
                    <v-btn 
                        class="mx-auto" 
                        width="100%" 
                        max-width="225" 
                        color="secondary" 
                        @click="submitEditForm" 
                        depressed> Update 
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    <RequestCallback2
        :person="person"
        :show-dialog="callDialog"
        @save-person="setSelected(lenderName, lenderData)"
        @close-modal="callDialog = false" />

    <Portal to="component-header-call-back">
        <RequestCallback1 @save-person="handleContinue" />
    </Portal>
    
    </v-card>
</template>

<script>
    import formHelpers from '~/components/shared/form-helpers'

    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { isEmpty } from 'lodash'
    import baseComponents from '~/components/base'
    import _FORecordInfoTableVue from '../FinancialObjectsEditor1/_FORecordInfoTable.vue'

    export default {
        name: 'QuoteResult5',
        mixins: [subscriptions, formHelpers],
        components:{
            ...baseComponents,
        },
        data() {
            return {
                callDialog: false,
                lenderName: '',
                lenderData: {},
                person: null,

                required_amount: '',
                dialog: false,
                dialog2: false,
                dialogData: null,

                editDialog: false,
                editDialogSaving: false,
                
                quotes: {},
                
                lenders: [],
                selected: [],

                topLenders: [],
                selectedTopLender: [],

                otherLenders: [],

                resources: {                        
                    "type": "loans",
                    "id": null,
                    "attributes": {
                        "selected-lender-quote-algorithm-slug": null,

                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": null
                            },
                        }
                    }
                },
                editFormFields: {
                    loans: {
                        "type": "loans",
                        "attributes": {
                            'sale-type': null,
                            "requested-balloon": null,
                        }
                    },
                    purchases: {
                        "type": "purchases",
                        "attributes": {
                            "condition": null,
                            "year-of-manufacture": null,
                            "purchase-price": null,
                            "cash-deposit": null,
                            "required-loan-amount": null
                        },
                    },
                    validation: {
                        "condition": "required|string|in:New,Used",
                        'sale-type': "required",
                        "year-of-manufacture": "required|integer|digits_between:4,4",
                        "purchase-price": "between:0,99999999",
                        "cash-deposit": "between:0,99999999",
                        "required-loan-amount": "required",
                        "requested-balloon": "required"
                    },
                    labels: {
                        "condition": "Vehicle condition",
                        'sale-type': "Vendor Type",
                        "year-of-manufacture": "Vehicle year",
                        "purchase-price": "Purchase price",
                        "cash-deposit": "Deposit",
                        "required-loan-amount": "Loan amount",
                        "requested-balloon": "Balloon payment"
                    },
                    visible: {},
                    enabled: {},
                },

                headers: [
                    { text: '', sortable: false, value: 'compare', width: 'auto'},
                    { text: 'Lender', sortable: false, value: 'lender', width: '15%'},
                    { text: 'Repayments', sortable: false, value: 'repayments', width: '20%'},
                    { text: 'Loan Term', sortable: false, value: 'loan-term', width: '20%'},
                    { text: 'Comparison Rate', sortable: false, value: 'comparison-rate', width: '20%'},
                    { text: 'Customer Rate', sortable: false, value: 'customer-rate', width: '20%'},
                    { text: '', sortable: false, value: 'action'}
                ],
                resourceObj: {

                },
                fields: {
                    'selected-lender-quote-algorithm-slug': { label: 'Lender', meta: 'selected-lender-quote-algorithm-slug' }
                },
                disabled: false,
                settings: {
                    hideLenderNames: 0,
                    customerDiscountRate: 0,
                    originationFee: 1000
                },
                productAlias: [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'C' ],
                validComponentData: true,
            }
        },
        beforeCreate() {
            window.scrollTo(0, 0)
        },
        mounted(){

            // console.log(this.lenders)

            Object.keys(this.blockSettings).forEach(item => {

                if(this.settings.hasOwnProperty(item)){
                    this.settings[item] = this.blockSettings[item]
                }
            })

            // console.log(this.settings)

        },
        computed: {
            ...mapGetters('themes', [
                'getActiveTheme'
            ]),
            vb(){
                return this.$vuetify.breakpoint
            },
            topLoanMinWidth(){
                return this.vb.smAndUp ? 100 : 320
            },
            topLoanMaxWidth(){
                return this.vb.smAndUp ? 340 : 400
            },
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            getPersonId(){
                return this.appData.relationships.people.data[0].id
            },
            hideLenderIndentity(){
                return this.settings.hideLenderNames == 1
            },
            selectedCount(){

                return this.selected.length

            },
            selectedLenders(){

                let lenders = []


                this.lenders.forEach( lender => {

                    if( this.selected.includes(lender.name)) {

                        lenders.push(lender)
                    }

                })

                return lenders

            },
            hasLenders(){
                return this.lenders.length > 0
            },
            getDisclaimer() {

                let defaultText = "*Terms and conditions apply. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982."

                if (this.blockSettings.disclaimer) {

                    switch (this.blockSettings.disclaimer) {
                        case "assumed-parameters":
                            return "*Terms and conditions apply. Some parameters for the quote have been assumed, therefore the quote may change once accurate information has been received about these assumptions. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982."
                    
                        default:
                            return defaultText;
                    }

                }

                return defaultText
            },
            applyButtonLabel() {
                if (this.blockSettings.applyButtonLabel) {
                    return this.blockSettings.applyButtonLabel
                } else {
                    return 'Apply Now'
                }
            },
            getPurchasePrice(){
                return this.editFormFields.purchases.attributes["purchase-price"]
            },
            getDeposit(){
                return this.editFormFields.purchases.attributes["cash-deposit"]
            }
        },
        methods: {
            ...mapActions('flows', ['selectNextStepOL']),
            ...mapActions('resource-actions', [
                'getQuotes',
                'updateLoan',
                'getPerson',
                'getPurchases',
                'updatePurchases',
                'setApplicationNow'
            ]),
            ...mapMutations('subscriptions', [
                'setStepableDataWorkable'
            ]),
            async pull() {

                // Update Loan first with user testing values before getting the getQuotes

                let loan = null;
                let purchases = null;

                // get person
                if(this.getPersonId) {
                    let res = await this.getPerson(this.getPersonId)
                    if(res.status == 200) {
                        this.person = res.data.data
                    }
                }
                
                if (this.getLoanId) {

                    let payload = {
                        type: 'loans',
                        id: this.getLoanId,
                        attributes: {
                            "customer-discount-rate": this.settings.customerDiscountRate,
                            "requested-broker-fee": this.settings.originationFee
                        }
                    }

                    loan = await this.updateLoan(payload)
                    purchases = await this.getPurchases()

                    if(loan.status == 200 || loan.statusText == 'OK'){
                        this.editFormFields.loans.id = this.getLoanId
                        this.editFormFields.loans.attributes["requested-balloon"] = loan.data.data.attributes["requested-balloon"]
                        this.editFormFields.loans.attributes["sale-type"] = loan.data.data.attributes["sale-type"]
                    }

                    if(purchases.status == 200 || purchases.statusText == 'OK'){
        
                        let fields = [
                            "condition",
                            "year-of-manufacture",
                            "purchase-price",
                            "cash-deposit",
                            "required-loan-amount"
                        ]

                        this.editFormFields.purchases.id = purchases.data.data[0].id

                        fields.forEach( field => {
                            this.editFormFields.purchases.attributes[field] = purchases.data.data[0].attributes[field]
                        })
                    }

                }

                this.updateLenderResults()

            },
            async updateLenderResults(){

                let response = await this.getQuotes({                    
                    'type': 'applications',
                    'id': this.appId,
                    'attributes': {}
                })

                if ( response.length > 1 ) {

                    response.forEach( res => {
                        if (res.data.data.type === 'applications') {

                            let list = res?.data?.included?.[0]?.attributes?.results ? res?.data.included[0].attributes.results : {}
                            
                            if ( !isEmpty(list) ) {
                                list = JSON.parse( list )
                            }

                            this.quotes = list?.lenders
                            this.required_amount = list?.['required-amount']

                        } else {
                            this.resources = res.data.data
                            this.resources.relationships = this.removeEmptyRelationships(this.resources.relationships)
                        }
                    })
                }

                if (this.quotes){
                    Object.keys(this.quotes).forEach( (lender, index) => {
                        this.lenders.push(this.quotes[lender])
                        this.lenders[index]['slug'] = lender
                        this.lenders[index]['alias'] = `Product ${this.productAlias[index]}`
                    })
                }


                this.resourceObj = { 
                    'selected-lender-quote-algorithm-slug': this.resources.attributes['selected-lender-quote-algorithm-slug']
                }

                // sort lenders from lowest to highest monthly repayments

                if (this.lenders.length > 0){

                    this.lenders = this.sortLenders(this.lenders)
                    this.topLenders = this.lenders.slice(0, 3)


                    if(this.lenders.length > 3){
                        this.otherLenders = this.lenders.slice(3);
                    }

                }


                // console.log(this.lenders)
                this.validComponentData = this.lenders.length > 0 ? true : false
            },
            async push() {

                let requestPayload = Object.assign({}, this.resources)

                let res = await this.setApplicationNow({
                    payload: this.resources,
                    picked: this.resourceObj['selected-lender-quote-algorithm-slug']
                })
                
                const {valid, status} = await this.validateFormData()
                let data = this.$options.filters.toAppDataFormat(res)

                return  { valid, status, data } 
            },
            resetLenders(){
                this.lenders = []
                this.topLenders = []
                this.otherLenders = []
                this.quotes = {}
            },
            async submitEditForm(){
                
                const {editFormFields} = this

                this.editDialogSaving = true

                const valid = await this.$refs.editFormObserver.validate();

                if(valid) {

                    let loan = this.updateLoan({
                        ...editFormFields.loans
                    })

                    let purchase =  this.updatePurchases({
                        ...editFormFields.purchases
                    })

                    await Promise.all([loan, purchase])
                    
                    this.resetLenders()

                    await this.updateLenderResults()

                    // Close Edit Form
                    this.editDialogSaving = false
                    this.editDialog = false

                } else {

                    this.editDialogSaving = false
                }

            },
            sortLenders(lenders) {
                let data = []
                lenders.forEach(item => {
                    if(item.quotable == "Yes") {
                        data.push(item)
                    }
                })
                data.sort((a, b) => {
                    if (a['actual-payment']['monthly-payment'] && b['actual-payment']['monthly-payment'])
                        return a['actual-payment']['monthly-payment'] - b['actual-payment']['monthly-payment']
                    
                })
                return data;
            },

            handleContinue(){

                this.resourceObj = { 'selected-lender-quote-algorithm-slug': "No options available" }
                this.selectNextStepOL({goToNext: true, returnStatus: false, params: {key: "source", value: "request-a-call"}})
                
            },
            showPeopleModal(name = '', lender = {}) {
                this.lenderName = name
                this.lenderData = lender
                this.callDialog = true
            },
            setSelected(name = '', lender = {}) {

                this.resourceObj = { 'selected-lender-quote-algorithm-slug': lender.slug }

                this.populateLoanFields(lender)

                this.selectNextStepOL({goToNext: true, returnStatus: false, params: {key: "source", value: "apply-now"}})

            },

            populateLoanFields(item) {

                this.resources.attributes['quoted-balloon'] = item['actual-payment']['balloon']
                this.resources.attributes['quoted-bank-fees'] = item['actual-payment']['bank-fees']
                this.resources.attributes['quoted-base-rate'] = item['actual-payment']['base-rate']
                this.resources.attributes['quoted-broker-commission-inc-gst'] = item['actual-payment']['commission-inc-gst']
                this.resources.attributes['quoted-broker-fee'] = item['actual-payment']['broker-fee']
                this.resources.attributes['quoted-customer-rate'] = item['actual-payment']['customer-rate']
                this.resources.attributes['quoted-monthly-payments'] = item['actual-payment']['monthly-payment']
                this.resources.attributes['quoted-naf'] = item['actual-payment']['naf']
                this.resources.attributes['quoted-account-fee'] = item['actual-payment']['account-fee']
                this.resources.attributes['quoted-term-months'] = item['actual-payment']['term-in-months']
                this.resources.attributes['selected-lender-quote-algorithm-slug'] = item.slug

            },

            setSelect(name = '') {

                if (this.selected.includes(name)) {
                    this.selected = this.selected.filter(
                        selected => selected !== name
                    )
                } else {
                    this.selected.push(name)
                }

            },

            removeEmptyRelationships(relationships) {
                
                let temp = {}

                Object.keys(relationships).forEach( key => {
                    if (relationships[key].data?.length > 0 || relationships[key].data?.id !== undefined)
                        temp[key] = relationships[key]
                })

                return temp
            },
            showInfoDialog(data){
                // console.log(data)

                this.dialogData = data
                this.dialog = true

            },
            showCompareDialog(){
                
                this.dialog2 = true

            },
            getLenderLogo(name){

                let lender = require(`~/assets/images/logo/placeholder.png`)

                try {

                    lender = require(`~/assets/images/logo/${name}.png`)

                    return lender

                } catch (error) {

                    console.log('Ooops!..', error)

                    return lender

                }

            },
            hexToRGBA(hex, a = 1){
                return this.$options.filters.hexToRGBA(hex, a)
            },
            isBtnGroup(key){

                let keys = [
                    "condition"
                ]

                return keys.includes(key)

            },
        },
        watch: {
            getPurchasePrice(value){
                this.editFormFields.purchases.attributes["required-loan-amount"] = value - this.editFormFields.purchases.attributes["cash-deposit"]
            },
            getDeposit(value){
                this.editFormFields.purchases.attributes["required-loan-amount"] = this.editFormFields.purchases.attributes["purchase-price"] - value
            },
            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.setStepableDataWorkable(false)

                    }
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss">

    .flex__component-QuoteResult5 {

        .top-lenders-slide-group {

            .v-slide-group__wrapper .v-slide-group__content {
                justify-content: center !important;
            }

            .lender-item .v-card__actions {
                cursor: pointer;
                justify-content: center !important;
            }

        }


        .outline {
            border: none;
            height: 1px;
        }


        .flex-form {
            display: grid;
            grid-column-gap: 15px;
            grid-row-gap: 0;
            grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

            .flex-field {
                align-self: start;
            }
        }

        .inline-icons {
            display: flex;
        }

        .caption.text-center.gray {
            color: gray;
        }

        .lightgray {
            background-color: #fafafa;
        }

    }
  
</style> 