<template>
<div>

    <!-- :loading="isDisabled" -->
    <v-data-table
        loading-text="Fetching your quotes..."
        no-data-text=""
        v-if="validComponentData"
        :headers="headers"
        :items="filterData(data)"
        hide-default-footer
        :mobile-breakpoint="0"
        hide-default-header
        outlined
        class="v-data-table-2 mx-auto">

        <!-- Body -->
        <template v-slot:item.compare="{ item, index }">
            <v-card outlined class="card-row pa-2 pa-md-4 mb-4 rounded-xl">

                <div class="d-flex justify-space-between align-center">

                    <div :class="['d-flex flex-column justify-start', {'ml-2': vb.mdAndUp}]">
                        <div class="d-flex">
                            <div class="px-0 px-md-2 text-center">
                                <div v-if="hideLender" class="title mb-2 font-weight-bold">
                                    {{ item['alias'] }}
                                </div>
                                <v-img 
                                    v-else 
                                    :max-height="vb.mdAndUp ? 35 : 25"
                                    :max-width="vb.mdAndUp ? 100 : 82"
                                    class="flex-brand mb-2"
                                    style="margin: auto" 
                                    :src="getLenderLogo(item['logo-class'])" 
                                    position="center center" 
                                    contain>
                                </v-img>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column justify-start ml-0 ml-md-2 ml-md-4">
                        <div class="pb-4" v-if="vb.mdAndDown"></div>
                        <p class="ma-0 mb-2 caption">Repayment</p>
                        <p class="td-inner ma-0">
                            <div class="body-1 font-weight-medium font-size-20" style="letter-spacing: 0 !important;">
                                {{ getMonthlyAmount(item['actual-payment']['monthly-payment'], getRepaymentsTermField) | currency }}
                                <span class="caption" style="letter-spacing: 0 !important;">/{{mappedRepaymentsTermField}}</span>
                                <div class="caption font-weight-medium text-right" style="line-height: 1;letter-spacing: 0 !important;">
                                    Over {{ parseInt(item['actual-payment']['term-in-months']) / 12 }} Years
                                </div>
                            </div>
                        </p>
                    </div>

                    <div class="d-flex flex-column align-self-start justify-start ml-4" v-if="isMobile">
                        <p class="my-0  mb-2 caption">Interest Rate (p.a)</p>
                        <p class="td-inner ma-0">
                            <div class="body-1 font-weight-medium font-size-20 text-center">
                                {{ (item['actual-payment']['customer-rate'] ) | percentage }}
                            </div>
                        </p>
                    </div>

                    <div class="d-flex flex-column align-self-start justify-start ml-4" v-if="isMobile">
                        <p class="my-0  mb-2 caption">Comparison Rate (p.a)</p>
                        <p class="td-inner ma-0">
                            <div class="body-1 font-weight-medium font-size-20 text-center">
                                {{ (item['actual-payment']['comparison-rate'] ) | percentage }}
                            </div>
                        </p>
                    </div>

                    <div class="d-flex flex-column align-self-start justify-start ml-4" v-if="isTablet">
                        <p class="my-0  mb-2 caption">All Fee Included</p>
                        <p class="td-inner ma-0">
                            <div class="body-1 font-weight-medium font-size-20 text-center">
                                Yes
                            </div>
                        </p>
                    </div>

                    <div class="d-flex flex-column align-self-start justify-start ml-4" v-if="isTablet">
                        <p class="my-0  mb-2 caption">Balloon Payment</p>
                        <p class="td-inner ma-0">
                            <div class="body-1 font-weight-medium font-size-20 text-center">
                                {{ item['actual-payment']['balloon'] | currency }}
                            </div>
                        </p>
                    </div>

                    <div :class="vb.mdAndUp ? 'mr-2' : 'd-flex flex-column'">
                        <v-btn color="secondary" outlined depressed :class="vb.mdAndUp ? 'mr-2' : 'px-2 mb-2 font-size-12'" @click="addToCompare(item)" >
                            <v-icon :size=" vb.mdAndUp ? 25 : 18" color="primary">
                            {{ 
                                getLendersToCompareNames.includes(item.name) ? 
                                'mdi-check-circle-outline' :
                                'mdi-circle-outline'
                            }}
                            </v-icon>
                            {{ vb.mdAndUp ? 'Compare Lenders' : 'Compare' }}
                        </v-btn>
                        <v-btn
                            color="secondary" 
                            @click="showInfoDialog(item)" 
                            :class="vb.mdAndUp ? 'px-6' : 'px-2 font-size-12'"
                            depressed> 
                            More Details
                        </v-btn>
                    </div>
                </div>

            </v-card>
        </template>

    </v-data-table>
    
    <v-card v-else tile flat class="rounded-lg mx-auto" max-width="700">
        <v-card-text class="text-center pa-0 pa-md-66">
            <v-icon size="48" color="secondary">mdi-information</v-icon>
            <v-spacer class="mb-6"/>
            <div class="body_text_color--text">
                <div class="headline mb-6">Sorry, we are having trouble locating options based on the information provided.</div>
                <p class="subtitle-1">But, please continue and submit the application with detailed submission notes, and our team will look at options using the entire panel.</p>
            </div>
        </v-card-text>
    </v-card>


</div>
</template>

<script>

    import mixins from '~/components/v1/QuoteResult6/_mixins'
    import {store, actions} from '~/components/v1/QuoteResult6/__store'

    export default {
        mixins: [
            mixins
        ],
        props: {
            data: {
                type: Array,
                default: () => {[]}
            },
            selectedLender: {
                type: String,
                default: ''
            },
            hideLender: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                headers: [
                    { text: '', sortable: false, value: 'compare', width: 'auto'},
                    { text: 'Lender', sortable: false, value: 'lender', width: '15%'},
                    { text: 'Repayments', sortable: false, value: 'repayments', width: '20%'},
                    { text: 'Loan Term', sortable: false, value: 'loan-term', width: '20%'},
                    { text: 'Comparison Rate', sortable: false, value: 'comparison-rate', width: '20%'},
                    { text: 'Customer Rate', sortable: false, value: 'customer-rate', width: '20%'},
                    { text: '', sortable: false, value: 'action'}
                ],
            }
        },
        mounted(){

        },
        computed: {
            getLendersToCompareNames(){
                return store.lendersToCompare.map(lender => lender.name)
            },
            isTablet(){
                return this.vb.width > 1320
            },
            isMobile(){
                return this.vb.width > 1200 
            }
        },
        methods: {
            showInfoDialog(lender){
                this.$emit('show-info', lender)
            },
            showApplyModal(lender){
                this.$emit('show-apply', lender)
            },
            addToCompare(lender) {
                actions.setSelectedLendersToCompare(lender)
            },
            filterData(data) {
                let lenders = []

                data.forEach(element => {
                    if(element.quotable === 'Yes') {
                        lenders.push(element)
                    }
                })

                return lenders
            }
        },
        watch: {
   
        }
    }
</script>

<style lang="scss">

</style> 