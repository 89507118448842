<template>

    <v-card :disabled="isDisabled || blockPermissionsReadOnly" class="mx-auto" tile flat>

        <!-- Sidebar Utility -->
        <Portal to="component-side-utility">
            <v-card height="100%" tile flat>
                
                <v-navigation-drawer
                    v-model="drawer"
                    :mini-variant.sync="mini"
                    width="300"
                    permanent
                    floating
                    @transitionend="drawerAction">

                    <v-list-item class="px-2" click.stop="mini = !mini" :ripple="false">
                        <v-list-item-icon>
                            <v-btn icon :ripple="false">
                                <!-- <v-icon>mdi-{{ `chevron-${mini ? 'right' : 'left'}` }}</v-icon> -->
                                <v-icon>mdi-segment</v-icon>
                            </v-btn>
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-bold">App Details</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-card :height="10" class="mx-auto" tile flat />

                    <v-list dense :disabled="isDisabled">
                        <v-list-item-group v-model="list" color="primary">
                            <ListGroup 
                                v-for="(role, index) in buildRoleTree" 
                                :role="role" :mini="mini" :key="index" 
                                @add="onAdd" 
                                @select="onSelect" 
                                :read-only="blockPermissionsReadOnly"
                                :errors="getInactiveFormIds"
                                :wrap="textWrap"/>
                        </v-list-item-group>
                    </v-list>

                </v-navigation-drawer>

            </v-card>
        </Portal>

        <!-- Main Content -->

        <template v-if="selected">

            <v-card-text class="px-4 pt-0 px-md-10 pb-2 pb-md-2">
                <v-chip :filter="false" :ripple="false" class="light_gray_1 mb-1 mr-1 font-weight-bold" small v-for="role in getRolesEntity(selected)" :key="role">
                    {{ role }}
                </v-chip>
            </v-card-text>
            <v-card-title class="px-4 px-md-10 pt-0 pt-md-0 pb-2 pb-md-2">
                <span>{{ getFriendlyName(selected) }}</span>
            </v-card-title>
            <v-card-text class="px-4 pt-0 px-md-10 pb-0">
                {{ getRoleKind(selected) }}
            </v-card-text>

            <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-5">
                <v-container pa-0 fluid>
                    <v-row>
                        <v-col cols="12">

                            <ValidationObserver ref="observer">
                                <EntityEditor1 :key="selected.id" @change="handleEntityChange"  :loan-kind-id="selectedLoanTypeId" :data="selected" :entity-part-kind-id="selectedRoleId"/>
                            </ValidationObserver>

                            <!-- Other Hidden Forms for Validation -->
                            <template v-if="getSiblingsEntity().length">
                                <ValidationObserver ref="observer3" hidden>
                                    <div v-for="entity in getSiblingsEntity()" :key="entity.id">
                                        <h2>{{entity.type}}  {{entity.id}}</h2>
                                        <EntityEditor1 :key="entity.id" :id="entity.id" :loan-kind-id="getLoanKindId(entity)" :data="entity" @change="handleEntityChange"  :entity-part-kind-id="getEntityPartKindId(entity)"/>
                                    </div>
                                </ValidationObserver>
                            </template>

                            <v-alert dense outlined type="error" class="mt-8" v-if="hasError">
                                <div v-html="errorMsg"></div>
                            </v-alert>

                            <v-alert dense outlined type="error" class="mt-8" v-if="hasRoleErrors">
                                <div v-for="error in roleErrors" :key="error" v-html="error"></div>
                            </v-alert>

                            <div class="d-flex py-6 mt-4">
                                <v-spacer></v-spacer>
                                <ConfirmModal 
                                    v-if="primaryApplicant.id != selected.id"
                                    async
                                    :title="`Remove Role`"
                                    :processing="isDeleting"
                                    :message="buildDeleteMsg(selected)"
                                    confirmText="Remove"
                                    @confirm="confirmDelete(selected)">
                                        <template v-slot:activator="{on}">
                                            <v-btn color="light_gray_2" :min-width="140" large outlined elevation="0" nuxt  @click.stop="on" class="mr-4">Remove Role</v-btn>
                                        </template>
                                </ConfirmModal>
                                <v-btn color="secondary" :min-width="140" large depressed nuxt  @click="handleUpdate" :disabled="isDisabled">Save</v-btn>
                            </div>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

        </template>

        <ValidationObserver ref="observer2">
        <v-dialog
            v-model="dialog"
            persistent
            scrollable
            max-width="1124">
                <v-card :disabled="isSaving">

                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="isSaving"></v-progress-linear>

                    <v-card-title class="px-6 pt-4">

                        <v-subheader class="overline pl-0"  v-if="stepper == 0">
                        </v-subheader>

                        <v-tabs v-if="stepper == 1" class="role-tool-tabs" v-model="tabs" show-arrows height="60">
                            <v-tab>
                                <div class="body-1 font-weight-bold">
                                    Create New Entity
                                </div>
                            </v-tab>
                            <v-tab :disabled="false">
                                <div class="body-1 font-weight-bold">
                                    Select Existing Entity
                                </div>
                            </v-tab>
                        </v-tabs>

                        <v-spacer/>
                        <v-btn icon @click="handleClose">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text class="px-8 py-0 pb-8"> 
                        
                        <v-card tile flat class="mx-auto" width="800"> 

                            <v-card-title class="px-3 justify-center pb-2">
                                <span v-if="stepper == 0">Add a Role</span>
                                <span v-else-if="stepper == 1">Add an Entity</span>
                                <span v-else>Entity Name</span>
                            </v-card-title>
                            <v-card-text class="text-center pt-0 pb-8">
                                <div class="text-subtitle-1" v-if="stepper == 2">Please fill out the details below</div>
                                <div class="text-subtitle-1" v-else>Please select one that applies</div>
                            </v-card-text>

                            <v-carousel
                                light
                                :value="stepper"
                                :continuous="false"
                                :show-arrows="false"
                                hide-delimiters
                                touchless
                                hide-delimiter-background
                                height="auto">

                                <v-carousel-item v-for="(step, index) in steps" :key="step.id + '-' + index">

                                    <template  v-if="stepper == 0">

                                        <v-container class="light_gray_1 px-4 py-2 rounded-lg">
                                            <v-row>
                                                <v-col v-for="child in step.children"
                                                class="pa-2"
                                                :key="child.id" cols="12" :sm="step.children.length == 1 ? 12 : 6">
                                                    <v-card flat height="100%" 
                                                    @click="next(child)"
                                                    rounded="lg"
                                                    class="d-flex pa-4 align-center justify-center">
                                                        <div class="flex-options body-2 font-weight-medium">
                                                            {{ child.name }} {{ development ? `${child.id}` : ''}}
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="secondary" :width="86" small depressed>
                                                            Select
                                                        </v-btn>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>

                                    </template>

                                    <template  v-if="stepper == 1">
                    
                                        <template v-if="tabs == 0">
                                            <v-container class="light_gray_1 px-4 py-2 rounded-lg">
                                                <v-row>
                                                    <v-col v-for="child in step.children"
                                                    class="pa-2"
                                                    :key="child.id" cols="12" :sm="step.children.length == 1 ? 12 : 6">
                                                        <v-card flat height="100%" 
                                                        @click="next(child)"
                                                        rounded="lg"
                                                        class="d-flex pa-4 align-center justify-center">
                                                            <div class="flex-options body-2 font-weight-medium">
                                                                {{ child.name }} {{ development ? `${child.id}` : ''}}
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="secondary" :width="86" small depressed>
                                                                Select
                                                            </v-btn>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-container>

                                        </template>
                                        <template v-if="tabs == 1">
                                            <v-container class="light_gray_1 px-4 py-2 rounded-lg" v-if="relatedRoles.length">
                                                <v-row>
                                                    <v-col v-for="(role, index) in relatedRoles" 
                                                    class="pa-2"
                                                    :key="index" cols="12" :sm="relatedRoles.length == 1 ? 12 : 6">
                                                        <v-card flat height="100%" 
                                                        @click.stop="handleSelectRelatedRole(role)"
                                                        rounded="lg"
                                                        class="d-flex pa-4 align-center justify-center">
                                                            <div class="flex-options body-2 font-weight-medium">
                                                                {{getFriendlyName(role)}}
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="secondary" :width="86" small depressed>
                                                                Select
                                                            </v-btn>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <div class="body-1 my-4 body_text_color--text text-center" v-else>
                                                <span>No Existing Roles</span>
                                            </div>
                                        </template>

                                    </template>

                                    <template  v-if="stepper == 2">
                                        <div class="flex-form" v-if="getEntityType">
                                            <template v-for="(field, key) in fields[getEntityType]">
                                                <div class="flex-field" :key="key">
                                                    <ValidationProvider 
                                                        v-slot="{ errors }" 
                                                        :name="labels[key]" 
                                                        rules="required" 
                                                        :vid="`${key}`">
                                                        <v-text-field 
                                                            :type="'text'"
                                                            :label="labels[key]"
                                                            :error-messages="errors"
                                                            v-model="fields[getEntityType][key]"
                                                            color="secondary">
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </div>
                                            </template>
                                            <!-- Temp Spacer -->
                                            <div class="flex-field" v-for="index in 6" :key="index"></div>
                                        </div>
                                        <v-alert dense outlined type="error" class="mx-2" v-else>
                                            <div>Uh oh! Entity type is not set</div>
                                        </v-alert>
                                    </template>

                                </v-carousel-item>

                            </v-carousel>

                        </v-card>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">

                        <template v-if="isLastStep && stepper != 0">
                            <v-btn color="light_gray_2" :min-width="140" depressed nuxt outlined large @click="back">Back</v-btn> 
                            <v-spacer></v-spacer>
                            <v-btn v-if="stepper == 2" color="secondary" :min-width="140" large depressed nuxt  @click="handleCreate" :disabled="isSaving">Save</v-btn>
                        </template>

                        <template v-else>
                            <v-btn color="light_gray_2" :min-width="140" depressed nuxt outlined large @click="handleClose">Back</v-btn> 
                            <v-spacer></v-spacer>
                        </template>

                    </v-card-actions>

                </v-card>
        </v-dialog>
        </ValidationObserver>

        <!-- Dialog for guarantor -->
        <WarningDialog :modal="guarantorDialog" @next-step="nextStepGuarantor" @back-step="backStepGuarantor" />

    </v-card>
</template>

<script>

    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'  
    import resourceHelpers from '~/components/shared/resource-helpers'

    import ListGroup from '~/components/v1/RoleEditor1/_ListGroup'
    import WarningDialog from '~/components/v1/RoleEditor1/_WarningDialog'
    import {store, actions} from '~/components/v1/RoleEditor1/__store'
    import logic from '~/components/v1/RoleEditor1/role-logic'

    import EntityEditor1 from '~/components/v1/ApplicantEditor1/_EntityEditor1'
    import shared from '~/components/v1/RoleEditor1/__shared'
    import ConfirmModal from '~/components/base/ConfirmModal2'

    export default {
        mixins: [subscriptions, resourceHelpers, shared],
        components: {
            ListGroup,
            ConfirmModal,
            WarningDialog,
            EntityEditor1
        },
        data(){
            return {
                roleErrors: [],
                hasRoleErrors: '',

                guarantorDialog: false,

                hasError: false,
                inactiveFormsStatus: [],
                errorMsg: 'Please complete the required records.',

                list: 0,
                selected: null,

                dialog: false,
                tabs: 0,
                relatedRoles: [],

                drawer: true,
                mini: false,
                textWrap: 'text-wrap',

                fields: {
                    people: {
                        "first-name": "",
                        "last-name": "",
                    },
                    businesses: {
                        "business-legal-name": ""
                    }
                },
                labels: {
                    "first-name": "First Name",
                    "middle-name": "Middle Name",
                    "last-name": "Last Name",
                    "business-legal-name": "Business Legal Name",
                },
                
            }
        },
        computed: {
            ...mapGetters('privacy-disclosure', ['getCanSendPrivacy']),
            getInactiveFormIds(){
                const {inactiveFormsStatus} = this

                let ids = []

                inactiveFormsStatus.forEach(item => {
                    if(!item.valid){
                        ids.push(item.entity.id)
                    }
                })

                return ids

            },
            getLoanId(){
                try {
                    return this.appData.relationships.loan.data.id
                } catch (error) {
                    console.log('Ooops!..', error)
                }
            },
            getPartKindChildren(){

                let parts = this.kinds.find( part => part.name == 'Part')

                if(parts){
                    return parts.children
                }

                return null

            },
            getLoanKindChildren(){

                let parts = this.kinds.find( part => part.name == 'Loan')

                if(parts){
                    return parts.children
                }

                return null

            },
            getBusinessKindChildren(){

                let parts = this.kinds.find( part => part.name == 'Business')

                if(parts){
                    return parts.children
                }

                return null

            },
            getPersonKindChildren(){

                let parts = this.kinds.find( part => part.name == 'Person')

                if(parts){
                    return parts.children
                }

                return null

            },
            getEntityType(){

                if(store.entityKind){

                    if(store.entityKind.name == "Individual"){
                        return 'people'
                    } else {
                        return 'businesses'
                    }
                }

                return null
            },
            isLastStep(){

                if(store.currentStep) {
                    return store.currentStep.children.length == 0
                }

                return false
            },
            stepper(){
                return store.stepper
            },
            steps(){
                return store.steps
            },
            currentStep(){
                return store.currentStep
            },
            isSaving(){
                return store.saving
            },
            isDeleting(){
                return store.deleting
            },
            primaryApplicantKind(){
                return store.primaryApplicantKind
            },

            entityKind(){
                return store.entityKind
            },
            entityPartKind(){
                return store.entityPartKind
            },
            parentEntity(){
                return store.parentEntity
            },
            people(){
                return store.resources.people
            },
            businesses(){
                return store.resources.businesses
            },

            combinedEntities(){
                return [...store.resources.businesses, ... store.resources.people]
            },
            primaryApplicant(){
                return store.primaryApplicant
            },
            selectedLoanTypeId(){

                let {selected} = this

                return selected?.relationships?.kind?.data?.id

            },
            selectedRoleId(){

                let {selected} = this

                if(!selected) return ''

                let part = store.resources.parts.find(part => {
                    return part.relationships.child.data.id == this.selected.id
                })

                if(part){
                    return part.relationships.kind.data.id
                }

                return ''

            },
            buildRoleTree(){

                const {primaryApplicant, primaryApplicantKind} = this

                if(!primaryApplicant) return []
                if(!primaryApplicantKind) return []

                let roleTree = []
                let children = []

                // Add Guarantor Button
                let guarantor = this.buildAddGuarantorButton(primaryApplicantKind)
                
                if(guarantor){ 
                    guarantor.parent = primaryApplicant
                    children.push(guarantor) 
                }

                // Add Role Button
                let add = this.buildAddRoleChildButton(primaryApplicantKind)
                add.parent = primaryApplicant
                
                // remove guarantor
                let newParts2 = add.parts.filter(part => part.name != 'Guarantor' )
                add.parts = newParts2
                if(add){ children.push(add) }


                let childParts = primaryApplicant.relationships['child-parts']['data']
                let roles = this.buildChildrenTree(childParts)
                children = children.concat(roles)

                roleTree.push({
                    ...primaryApplicant,
                    icon: (primaryApplicant.type == 'people') ? 'mdi-account' : 'mdi-city', 
                    children
                })

                return roleTree
            },
            
        },
        mounted(){

            let todo = `
                TODO:
                ✔️ Add Button
                ✔️ Update tree on role create
                ✔️ Add loading state
                ✔️ Auto select newly created
                ✔️ Sleect existing
                - fix confirmDelete
            `

            // console.log(todo)

            // Reset initially
            actions.initialize()

        },
        methods: {
            getSiblingsEntity(){

                const {selected} = this
                let items = this.flattenRoleTree()
                let collection = []

                items.forEach( (item, index) => {

                    if(item.hasOwnProperty('attributes') && selected.id != item.id){
                        collection.push(item)
                    }   

                });

                return collection

            },
            getLoanKindId(entity){

                return entity?.relationships?.kind?.data?.id

            },
            getEntityPartKindId(entity){

                if(!entity) return ''

                let part = store.resources.parts.find(part => {
                    return part.relationships.child.data.id == entity.id
                })

                if(part){
                    return part.relationships.kind.data.id
                }

                return ''
            },
            flattenRoleTree(){
                
                const {buildRoleTree} = this

                let collection = []

                let getChildren = (children) => {

                    children.forEach(child => {

                        collection.push(child)

                        if(child.children && child.children.length > 0) {

                            getChildren(child.children)

                        }

                    })

                }

                getChildren(buildRoleTree)

                return collection

            },
            buildAddGuarantorButton(kind){

                let guarantor = this.buildAddRoleChildButton(kind)

                guarantor.name = 'Third-Party Guarantor'
                // remove other except guarantor
                let newParts1 = guarantor.parts.filter(part => part.name == 'Guarantor' )

                if(newParts1.length == 0){
                    return null
                }

                guarantor.parts = newParts1

                return guarantor

            },
            buildAddRoleChildButton(kind){

                const {getBusinessKindChildren, getPersonKindChildren, getPartKindChildren} = this

                if(!kind) return null;
                
                let roleTreeLogic = logic[kind?.attributes?.name]

                if(!roleTreeLogic) return null;

                let requiredParts = []

                // Customize Parts

                Object.keys(roleTreeLogic).forEach( role => {

                    let part = getPartKindChildren.find(p => p.name == role)

                    if(part){

                        let options = roleTreeLogic[role]['options']
                        let clonedPart = {
                            ...part
                        }

                        if(options){

                            let partOptions = []

                            options.forEach(option => {

                                let entityKind = [...getBusinessKindChildren,  ...getPersonKindChildren].find(p => p.name == option)

                                if(entityKind){
                                    partOptions.push(entityKind)
                                }

                            })

                            clonedPart.children = partOptions

                        }

                        requiredParts.push(clonedPart)
                    }

                })

                if(requiredParts.length == 0){
                    return null
                }

                return {
                    type: 'add',
                    name: 'Add Role',
                    icon: 'mdi-plus',
                    parts: requiredParts
                }

            },
            buildChildrenTree(childParts){

                const {combinedEntities, combinedEntitiesParts} = this

                let children = []

                childParts.forEach( child1 => {

                    combinedEntitiesParts.forEach( part => {

                        if(child1.id == part.id){

                            let parent = null
                            
                            // Find parent 
                            combinedEntities.forEach( entity => {
                                if( part.relationships?.parent?.data?.id == entity.id){
                                    // parent = entity.attributes['business-legal-name']
                                    parent = entity
                                }
                            })

                            combinedEntities.forEach( entity => {

                                if( part.relationships?.child?.data?.id == entity.id){

                                    entity.children = []
                                    entity.parent = parent

                                    entity.icon = (entity.type == 'people') ? 'mdi-account' : 'mdi-city'

                                    let grandChildren = entity.relationships['child-parts']

                                    let parentPart = this.kindsUnformat.find(kind => kind.id == part.relationships?.kind?.data?.id)

                                    // Exclude Roles
                                    let excludes = [
                                        'Beneficial Owner',
                                        'Guarantor'
                                    ] 
                                    
                                    if(entity.type == 'businesses' && !excludes.includes(parentPart.attributes.name)){ 
                                        
                                        let entityKind = this.kindsUnformat.find(kind => kind.id == entity.relationships?.kind?.data?.id)

                                        let add = this.buildAddRoleChildButton(entityKind)

                                        add.parent = entity // add parent entity

                                        // remove guarantor
                                        let newParts = add.parts.filter(part => part.name != 'Guarantor' )
                                        add.parts = newParts

                                        if(add){ entity.children.push(add) }

                                    } 

                                    if(grandChildren && grandChildren.data.length > 0){

                                        let roles = this.buildChildrenTree(grandChildren.data)

                                        entity.children = entity.children.concat(roles)
                                    }

                                    // Spreaded to remove reactivity
                                    children.push({
                                        ...entity
                                    })

                                }

                            })

                        }

                    })

                })

                return children
            },
            ...mapActions('resource-actions', [
                'getLoan',
                'updateLoan',
                'getPeople',
                'createPerson',
                'updatePerson',
                'createBusiness',
                'updateBusiness',
                'getBusinesses',
                'createPart',
                'deletePart',
            ]),
            ...mapActions('kinds', [
                'getKindById',
            ]),
            ...mapActions('privacy-disclosure', [
                'getPrivacyDisclosure',
                'checkPrivacyDisclosure',
                'resetPrivacyAndApplicant',
                'checkDiffSetActiveApplicant',
            ]),
            ...mapMutations('privacy-disclosure', [
                'setActiveApplicant',
                'setDisablePrivacyButton',
            ]),
            async pull(){

                const {getLoanId} = this

                try {

                    this.checkPrivacyDisclosure({disabler: false})

                    let loan = await this.getLoan(getLoanId)

                    actions.setResource(loan.data.data)

                    await this.updatePrimaryApplicant()

                    let tree = this.buildRoleTree
            
                    this.list = 0
                    this.selected = tree[0]


                } catch (error) {

                    console.log(error)
                }

            },
            async push(){

                // AutoSave 
                let {valid, status} = await this.handleUpdate()
                
                this.hasError = !valid

                // Validate sibling before going to next/prev steps
                
                if( valid ){

                    this.inactiveFormsStatus = await this.validateSiblings()

                    this.inactiveFormsStatus.forEach(item => {

                        if(!item.valid) {
                            valid = false
                            status = 'dirty'
                            this.hasError = true
                        }

                    })

                }

                // validate role tree
                this.roleErrors = []

                this.checkRoleErrors(this.buildRoleTree)

                if (this.roleErrors.length > 0) {
                    this.hasRoleErrors = true
                    valid = false
                    status = 'dirty'
                } else {
                    this.hasRoleErrors = false
                }

                // TODO: Role Validation
                return {valid, status, data:{}}

            },
            async validateSiblings(){

                // Validate sibling forms "observer3" before going to next/prev steps

                const observer3 = this.$refs.observer3
                let entities = this.getSiblingsEntity()

                let validations = []
                let collection = []

                if(observer3?.observers) {

                    observer3.observers.forEach( (observer) => {

                        entities.forEach(entity => {

                            if(entity.id == observer.id){
                                collection.push({ entity })
                                validations.push(observer.validate())
                            }

                        })

                    })


                    let validationResponse = await Promise.all(validations)

                    collection.forEach( (o, i) => {
                        collection[i]['valid'] = validationResponse[i]
                    })

                }

                // console.log(collection)

                return collection

            },
            drawerAction(){
                this.textWrap = this.mini ? '' : 'text-wrap'
            },
            async onAdd(data){

                await this.handleUpdate()

                this.resetPrivacyAndApplicant()

                if(data.length == 0) return

                let step = {
                    name: this.primaryApplicantKind.attributes.name,
                    id: this.primaryApplicantKind.id,
                    children: data.parts
                }

                actions.setParentEntity(data.parent)
                actions.setupStep(step)

                // if guarantor pop up warning else next step
                if(data.name == "Third-Party Guarantor"){

                    this.guarantorDialog = true

                    let guarantor = data.parts.find(part => part.name === 'Guarantor')

                    if(guarantor?.children){
                        this.relatedRoles = await this.getRelatedRoles(guarantor?.children)
                    }

                    this.preSelectKind(step)
                    
                } else {

                    this.dialog = true

                }

            },
            /**
             * if guarantor
             */
            nextStepGuarantor() {
                this.guarantorDialog = false
                this.dialog = true
            },

            backStepGuarantor() {
                this.guarantorDialog = false
                actions.resetSteps()
            },
            /**
             * pre select if only one option
             */
            preSelectKind(data){

                if(data.children.length == 1){
                    actions.setEntityPartKind(data.children[0])
                    actions.setupStep(data.children[0])

                    actions.setStepper(1)
                    actions.setCurrentStep(data.children[0])
                }

            },
            async onSelect(data){

                // AutoSave 
                await this.handleUpdate()

                if(data?.type == 'people' && this.getCanSendPrivacy){

                    this.setDisablePrivacyButton(false)
                    
                    await this.getPrivacyDisclosure({
                        id: data.id,
                        type: data.type,
                        attributes: {...data.attributes}
                    })

                } else {

                    this.setDisablePrivacyButton(true)
                }

                this.selected = data

            },
            buildDeleteMsg(data){

                let name = ''
                let parent = ''

                let role = this.getRolesEntity(data)

                role = role ? role.join('') : '';

                if(data.parent){
                    parent = this.getFriendlyName(data.parent) 
                }

                name = this.getFriendlyName(data) 

                return `Remove ${name} ${role} from ${parent}`


            },
            async confirmDelete(data){

                // Detach entity from parent entity
                
                let parent = data.parent

                // console.log('data',data)
                // console.log('parent',parent)

                let parentChildParts = parent.relationships['child-parts']
                let parentChildPartsIds = []

                if(parentChildParts && parentChildParts.data.length > 0){
                    parentChildParts.data.forEach(part => {
                        parentChildPartsIds.push(part.id)
                    })
                }

                let entityParentParts = data.relationships['parent-parts']
                let entityParentPartId = null

                if(entityParentParts && entityParentParts.data.length > 0){

                    entityParentParts.data.forEach(part => {
                        if( parentChildPartsIds.includes(part.id) ){

                            entityParentPartId = part.id

                        }

                    })
                }

                actions.setDeleting(true)

                let req1 = this.deletePart(entityParentPartId)

                await Promise.all([req1])

                await this.updatePrimaryApplicant()

                this.resetPrivacyAndApplicant()

                // Move selected
                this.gotoPrev()

                actions.setDeleting(false)

                this.setDisablePrivacyButton(true)

            },
            /**
             *  confirmDelete2 : 
             *  Not in use delete if you want
             *  Keeping it here for reference. 
             * 
             */
            async confirmDelete2(data){

                // Detach entity from parent entity
                
                let parent = data.parent

                // console.log('data',data)
                // console.log('parent',parent)

                let parentChildParts = parent.relationships['child-parts']
                let parentChildPartsIds = []

                if(parentChildParts && parentChildParts.data.length > 0){
                    parentChildParts.data.forEach(part => {
                        parentChildPartsIds.push(part.id)
                    })
                }

                let entityParentParts = data.relationships['parent-parts']
                let entityParentPartId = null

                if(entityParentParts && entityParentParts.data.length > 0){

                    entityParentParts.data.forEach(part => {
                        if( parentChildPartsIds.includes(part.id) ){

                            entityParentPartId = part.id

                        }

                    })
                }

                actions.setDeleting(true)
                
                let payload1 = {
                    type: parent.type,
                    id: parent.id,
                    relationships: {
                        "child-parts": {
                            data: [
                                {
                                    id: entityParentPartId,
                                    type: 'parts'
                                }
                            ],
                            meta: {
                                strategy: 'detach'
                            }
                        }
                    }
                }

                let payload2 = {
                    type: parent.type,
                    id: parent.id,
                    relationships: {
                        "parent-parts": {
                            data: [
                                {
                                    id: entityParentPartId,
                                    type: 'parts'
                                }
                            ],
                            meta: {
                                strategy: 'detach'
                            }
                        }
                    }
                }


                let req1 = null
                let req2 = null

                if(parent.type == 'businesses'){
                    req1 = this.updateBusiness(payload1)
                    req2 = this.updateBusiness(payload2)
                }

                if(parent.type == 'people'){
                    req1 = this.updatePerson(payload1)
                    req2 = this.updatePerson(payload2)
                }

                await Promise.all([req1, req2])

                await this.updatePrimaryApplicant()

                // Move selected
                this.gotoPrev()

                actions.setDeleting(false)

            },
            async handleUpdate(){

                try {

                    this.hasError = false
                    this.hasRoleErrors = false

                    this.setGlobalMode('disabled')

                    const observer = this.$refs.observer

                    const valid = await this.$refs.observer.validate()

                    let status = valid ? 'valid' : 'dirty'

                    let currentObserver = observer.observers[0]

                    let entity = this.selected

                    let payload = {
                        type: entity.type,
                        id: entity.id,
                        attributes: this.excludeInvalids(entity.attributes, currentObserver.fields)
                    }

                    let res = null

                    if(entity.type == 'businesses'){
                        res = await this.updateBusiness(payload)
                    }

                    if(entity.type == 'people'){
                        res = await this.updatePerson(payload)
                    }

                    actions.updateResources(res)

                    if(res.status !== 200) valid = false

                    this.setGlobalMode('enabled')

                    return {valid, status}

                } catch (err) {}
                
            },
            async handleCreate(){

                const {entityKind, entityPartKind, parentEntity} = this

                // console.log('parentEntity', parentEntity)
                actions.setSaving(true)

                const observer = this.$refs.observer2

                const valid = await this.$refs.observer2.validate()

                if(!valid) {
                    actions.setSaving(false)
                    return
                }

                try {

                    let part = {
                        "type": "parts",
                        "attributes": {
                            "priority": null
                        },
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": `${this.appId}`
                                }
                            },
                            "parent": {
                                "data": {
                                    "type": parentEntity.type,
                                    "id": parentEntity.id
                                }
                            },
                            "kind": {
                                "data": {
                                    "type": "kinds",
                                    "id": `${entityPartKind.id}`
                                }
                            }
                        }
                    }

                    let partRes = await this.createPart(part)

                    let personPayload = {
                        "type": "people",
                        "attributes": {
                            ...this.fields.people
                        },
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": this.appId
                                }
                            },
                            "parent-parts": {
                                "data": [
                                    {
                                        "type": "parts",
                                        "id": partRes.data.data.id
                                    }
                                ]
                            },
                            "kind": {
                                "data": {
                                    "type": "kinds",
                                    "id": entityKind.id
                                }
                            }
                        }
                    }

                    let businessPayload = {
                        "type": "businesses",
                        "attributes": {
                            ...this.fields.businesses
                        },
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": this.appId
                                }
                            },
                            "parent-parts": {
                                "data": [
                                    {
                                        "type": "parts",
                                        "id": partRes.data.data.id
                                    }
                                ]
                            },
                            "kind": {
                                "data": {
                                    "type": "kinds",
                                    "id": entityKind.id
                                }
                            }
                        }
                    }

                    let entityRes = null

                    if(entityKind.name == "Individual"){

                        entityRes = await this.createPerson(personPayload)

                        if(entityRes.status == 201 && this.getCanSendPrivacy) {

                            this.setDisablePrivacyButton(false)

                            await this.getPrivacyDisclosure({
                                id: entityRes.data.data.id,
                                type: entityRes.data.data.type,
                                attributes: {...entityRes.data.data.attributes}
                            })

                        } 

                    } else {

                        entityRes = await this.createBusiness(businessPayload)
                        this.setDisablePrivacyButton(true)

                    }

                    await this.updatePrimaryApplicant()

                    this.handleClose()

                    actions.setSaving(false)

                    this.gotoEntity(entityRes.data.data)


                } catch (error) {
    
                    console.log('Ooops!..', error)

                    this.handleClose()

                    actions.setSaving(false)

                }

            },
            gotoEntity(res){

                let items = this.flattenRoleTree()

                items.forEach( (item, index) => {

                    if(res.id == item.id){

                        this.list = index
                        this.selected = items[index]

                        return
                    }   

                });

            },
            gotoPrev(){

                let tree = this.buildRoleTree
        
                this.list = 0
                this.selected = tree[0]

                // const {list, buildRoleTree} = this
                
                // let items = this.flattenRoleTree()
                // let prev = parseInt(list) - 1

                // this.list = prev
                // this.selected = buildRoleTree[prev]
                
            },
            handleClose(){
                
                this.dialog = false

                // Reset
                this.tabs = 0 
                this.relatedRoles = []

                // Reset Fields
                this.fields = {
                    people: {
                        "first-name": "",
                        "last-name": "",
                    },
                    businesses: {
                        "business-legal-name": ""
                    }
                }

                actions.resetSteps()

            },
            back(){

                let step = this.stepper
                actions.setStepper( step-1 )

            },
            async next(payload){

                if(this.stepper == 0){ // Select Entity

                    actions.setEntityPartKind(payload)

                    actions.setSaving(true)

                    this.relatedRoles = await this.getRelatedRoles(payload.children)

                    actions.nextStep(payload)
                    
                    actions.setSaving(false)
                }

                if(this.stepper == 1){ // Select Loan Type

                    actions.setEntityKind(payload)

                    actions.setSaving(true)

                    await this.handleCreate()

                    // actions.nextStep(payload)
                    
                    actions.setSaving(false)

                }


                if(this.stepper == 2){ // Entity Form

                    // await this.handleCreate(payload)

                    return
                }

            },
            getEntityKindByName(name){

                const {getBusinessKindChildren, getPersonKindChildren} = this

                return [...getBusinessKindChildren, ...getPersonKindChildren].find( entity => {
                    return entity.name == name
                })

            },
            async updatePrimaryApplicant(){

                let people = this.getPeople({"include" : 'parent-parts'})
                let businesses = this.getBusinesses({"include" : 'parent-parts,child-parts'})

                const collection = await Promise.all([people, businesses])

                people = collection[0]
                businesses = collection[1]

                actions.setResource(people.data.data)
                actions.setResource(businesses.data.data)

                let entities = {
                    data: [
                        ...people.data.data,
                        ...businesses.data.data
                    ],
                    included: [
                        ...(people.data.included ? people.data.included : []),
                        ...(businesses.data.included ? businesses.data.included : [])
                    ]
                }

                let parts = []
                let partIds = []
                
                entities.included.forEach( part => {
                    if(part.type == "parts" && !partIds.includes(part.id)){
                            parts.push(part)
                            partIds.push(part.id)
                    }
                })

                actions.setResource(parts)

                let primary = this.selectPrimaryApplicant(entities)

                actions.setPrimaryApplicant(primary)

                let applicantKind = await this.getKindById(this.primaryApplicant.relationships.kind.data.id)

                actions.setPrimaryApplicantKind(applicantKind)

                // console.log('::: applicant kind: ', this.primaryApplicantKind.attributes.name)


            },
            async getRelatedRoles(roles){

                const {parentEntity, primaryApplicant} = this

                let items = []

                try {

                    let individual = []
                    let rest = []

                    roles.forEach(role => {

                        if(role.name == "Individual"){
                            individual.push(role.id)
                        } else {
                            rest.push(role.id)
                        }

                    })
                    
                    let all = []

                    if(individual.length){
                        let peoplePayload = individual.map( v => `kind=${v}`)
                        let res1 = this.getPeople({"filter[]" : `[${peoplePayload.join(',')}]`})
                        all.push(res1)
                    }

                    if(rest.length){
                        let businessPayload = rest.map( v => `kind=${v}`)
                        let res2 = this.getBusinesses({"filter[]" : `[${businessPayload.join(',')}]`})
                        all.push(res2)
                    }

                    const collection = await Promise.all(all)

                    collection.forEach( data => {
                        items = items.concat(data.data.data)
                    })

                    let filteredItems = []

                    if(parentEntity){

                        let childParts = parentEntity.relationships['child-parts']
                        let partsIds = []

                        if(childParts.data.length){

                            childParts.data.forEach(part => {
                                partsIds.push(part.id)
                            })

                        }

                        items.forEach( item => {

                            let parentParts = item.relationships['parent-parts']
                            let parentPartsData = parentParts.data
                            let itemKindId = item.relationships?.kind?.data?.id

                            // console.log(parentPartsData)

                            if(parentPartsData){

                                let isIncluded = false;

                                parentPartsData.forEach(ppd => {
                                    if(partsIds.includes(ppd.id)){
                                        isIncluded = true
                                    }
                                })

                                /**
                                 * NOTE: Excluding businesses entity for the meantime 
                                 * There is no way to know if business' children
                                 * has to be excluded from a copy.
                                 * 
                                 */
                                if(item.type == 'businesses'){
                                    // isIncluded = true  
                                }

                                // Exclude Parents and PrimaryApplicant
                                if(item.id == parentEntity.id || item.id == primaryApplicant.id){
                                    isIncluded = true
                                }

                                // Exclude existing Partners
                                let itemKind = this.kindsUnformat.find(kind => kind.id == itemKindId)

                                if(itemKind.attributes.name == 'Partnership'){
                                    isIncluded = true
                                }

                                if(!isIncluded){
                                    filteredItems.push(item)
                                }

                            }

                        })
                    }

                    return filteredItems

                } catch (error) {

                    console.log('Ooops!..', error)

                    return items
                }

            },
            async handleSelectRelatedRole(data){

                const {entityPartKind, parentEntity} = this

                actions.setSaving(true)

                try {

                    let partPayload = {
                        "type": "parts",
                        "attributes": {
                            "priority": null
                        },
                        "relationships": {
                            "application": {
                                "data": {
                                    "type": "applications",
                                    "id": `${this.appId}`
                                }
                            },
                            "parent": {
                                "data": {
                                    "type": parentEntity.type,
                                    "id": parentEntity.id
                                }
                            },
                            "kind": {
                                "data": {
                                    "type": "kinds",
                                    "id": `${entityPartKind.id}`
                                }
                            }
                        }
                    }

                    let partRes = await this.createPart(partPayload)

                    let payload = {
                        "type": data.type,
                        "id": data.id,
                        "relationships":{
                            "parent-parts": {
                                "data": [
                                    {
                                        "type": "parts",
                                        "id": partRes.data.data.id
                                    }
                                ],
                                "meta": {
                                    "strategy": "attach"  
                                }
                            },
                        }
                    }

                    let res = null

                    if(data.type == 'businesses'){
                        res = await this.updateBusiness(payload)
                    }

                    if(data.type == 'people'){
                        res = await this.updatePerson(payload)
                    }

                    actions.updateResources(res)

                    await this.updatePrimaryApplicant()

                    this.handleClose()

                    actions.setSaving(false)

                    this.gotoEntity(res.data.data)


                } catch (error) {
    
                    console.log('Ooops!..', error)

                    this.handleClose()

                    actions.setSaving(false)

                }

            },
            getValidationRole(roles = []) {

                roles.map(role => {

                    if (role?.children && role.children.length > 0) {

                        if (this.getRoleKind(role) == 'Partnership') {
                            
                            let counter = 0

                            // loop and identified if child is a role kind
                            role.children.forEach(child => {
                                if (this.getRoleKind(child)) {
                                    counter++
                                }
                            })

                            // if partner has less than 2 role kind put validation error
                            if (counter < 2) {
                                role['validation-errors'] = 'Partnership must have at least 2 partners.'
                            }

                        } else if (this.getRoleKind(role) == 'Company') {
                            // counter for beneficial owner
                            // let counter1 = 0
                            // counter for director
                            let counter2 = 0

                            role.children.forEach(child => {

                                // check if it is a role or a btn
                                if (child?.children) {
                                    // if (this.getRolesEntity(child)[0] == 'Beneficial Owner') {
                                    //     counter1++
                                    // }
                                    if (this.getRolesEntity(child)[0] == 'Director') {
                                        counter2++
                                    }
                                }
                                
                            })

                            if (counter2 < 1) {
                                role['validation-errors'] = 'Company must have at least 1 Director.'
                            }

                        } else if (this.getRoleKind(role) == 'Trust') {
                            // counter for beneficial owner
                            // let counter1 = 0
                            // counter for trustee
                            let counter2 = 0

                            role.children.forEach(child => {

                                // check if it is a role or a btn
                                if (child?.children) {
                                    if (this.getRolesEntity(child)[0] == 'Trustee') {
                                        counter2++
                                    }
                                }
                                
                            })

                            if (counter2 == 0) {
                                role['validation-errors'] = 'Trust must have at least 1 Trustee.'
                            }
                            
                        }

                        // recursive when a role has a children
                        role.children = this.getValidationRole(role.children)
                    }

                })

                return roles
            },
            checkRoleErrors(roles) {
                
                roles.forEach(role => {
                    if (role['validation-errors']) {
                        this.roleErrors.push(role['validation-errors'])
                    }
                    if(role?.children) {
                        this.checkRoleErrors(role.children)
                    }
                })

            },
            handleEntityChange(entity){

                if(entity?.id == this.selected?.id) {
                    this.checkDiffSetActiveApplicant({
                        id: entity.id,
                        type: entity.type,
                        attributes: {...entity.attributes}
                    })
                }
                
            }
        },
        watch: {
            buildRoleTree(val) {
                // get validation role
                val = this.getValidationRole(val)
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-1 {
        font-weight: bold;
        align-self: center;
        line-height: 1.2;
        font-size: 1rem;
    }
</style>
