<template>

    <v-card flat tile :disabled="isDisabled">
        <v-card-title :class="['px-4 px-md-10 pt-0', {'d-flex flex-column text-center': vb.smAndDown}]">

            <v-container fluid class="pa-0">
                <v-row>
                    <v-col cols="12">
                        <p class="flex__step-header-title">{{ heading }}</p>
                    </v-col>
                </v-row>
            </v-container>
                
            <v-spacer v-if="vb.mdAndUp" />

            <SendPrivacy
                v-if="showPrivacyButton"
                class="mr-2" />

            <InviteApplicantToEdit
                v-if="showInviteApplicantToEdit"
                :is-flow="true"
                label="Invite Applicant"
                label-type="button" />

            <PortalTarget name="step-header-buttons" class="d-flex mt-3 ml-2"></PortalTarget>

        </v-card-title>
    </v-card>

</template>

<script>

    import block from '~/components/shared/block' 
    import SendPrivacy from '~/components/base/SendPrivacy'
    import InviteApplicantToEdit from '~/components/base/InviteApplicantEditor'
    import { mapState, mapGetters, mapActions } from 'vuex';

    export default {
        mixins: [
            block, 
        ],
        name: 'step-header',
        components: { InviteApplicantToEdit, SendPrivacy },
        props: {

        },
        computed: {
            ...mapState({
                stepableMode: state => state.subscriptions.stepableMode,
                steps: state => state.flows.steps,
                activeStep: state => parseInt(state.flows.activeStep),
                appData: state => state.flows.appData,
            }),
            ...mapGetters('auth-actions', [
                'getUserSetting'
            ]),
            ...mapGetters('slug', [
                'getTenant',
            ]),
            ...mapGetters('flows', [
                'getActiveStepData'
            ]),
            ...mapGetters('privacy-disclosure', [
                'getShowPrivacy'
            ]),
            showPrivacyButton() {
                
                if(!this.getShowPrivacy) return false;
                // if(this.appData.attributes['hybrid-tnf'] == 1) return false;

                if(this.getTenant == "nodifi") {
                    return false
                }

                return ['Applicants', 'Roles'].includes(this.getActiveStepData.name)
            },
            showInviteApplicantToEdit(){

                const {getUserSetting, blockSettings} = this
                
                if(getUserSetting) {

                    if(getUserSetting.b2b == 0) {
                        return false;
                    }
                }

                // console.log(blockSettings)

                if(blockSettings.showInviteApplicantButton == 0) {
                    return false
                }

                return true
            },
            isDisabled(){
                return this.stepableMode == 'disabled'
            },
            heading(){

                const {steps, activeStep} = this

                if( steps[activeStep - 1] && steps[activeStep - 1].name ) {
                    return steps[activeStep - 1].name
                }

                return ''
            },
            vb() {
                return this.$vuetify.breakpoint
            }

        },
        
    }
</script>