<template>
    <v-dialog
        v-model="dialog"
        width="800"
        scrollable
        persistent>
   

        <v-card flat class="is-there-mortgage relative" :disabled="isLoading">
            <v-progress-linear color="primary" indeterminate stream absolute top v-if="isLoading"></v-progress-linear>

            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="back" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-title class="px-8 py-4 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>

            <v-card-text :class="vb.mdAndUp ? 'px-16' : 'px-4'">
                <div>
                    <v-row class="rounded-lg light_gray_1 pa-2 ma-2 mx-auto" justify="center" :style="vb.mdAndUp ? 'width: 55%;' : 'width: 100%;'">
                        <v-col cols="12" class="pa-2">
                            <v-card
                                flat
                                height="100%" 
                                @click="nextStep('Yes')"
                                rounded="lg"
                                class="d-flex pa-4 align-center justify-center">
                                <div class="flex-options body-2 font-weight-medium">
                                    Yes, there is a Mortgage
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn color="secondary" :width="86" small depressed>
                                    Select
                                </v-btn>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <v-card
                                flat
                                height="100%" 
                                @click="nextStep('No')"
                                rounded="lg"
                                class="d-flex pa-4 align-center justify-center">
                                <div class="flex-options body-2 font-weight-medium">
                                    No, it is Owned Outright
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn color="secondary" :width="86" small depressed>
                                    Select
                                </v-btn>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <v-row class="d-flex justify-center py-8">
                    <v-btn class="px-10 " :disabled="isLoading" width="140" height="50" depressed large @click="back">Back</v-btn>
                </v-row>
            </v-card-text>
          
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import shared from './__shared'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        address: {
            type:Object,
            default: () => {}
        }
    },
    watch: {
        "step": {
            handler (val) {
                val == 'mortgage' ? this.dialog = true : this.dialog = false
            }
        },
        "address": {
            handler (val) {
                if (val.hasOwnProperty('occupancy')){
                    this.occupancySituation =  val.occupancy.attributes['occupancy-situation']
                } else {
                    this.occupancySituation = ""
                }
            }
        }
    },
    mixins: [shared],
    computed : {
        ...mapState('flows',['calculation'])
    },
    methods: {
        ...mapActions('resource-actions',['createAddress','createOccupancy','createAsset','createExpense']),
        async nextStep(data) {
            // mutations.setIsCurrentLive(data)
            // this.$emit('input',{model:'occupancy-situation',value:data})
            // this.$emit('setCurrentLive',data)
            this.isLoading = true
            let expenstAttr = {
                    'expense-group': 'Property Investment'
            }
            if(data == 'Yes') {
                mutations.setExpenseAttributes({'kinds':  {data: { "type" : "kinds", "id": "11-6" }}})
                let expenseRelation = {
                    'kinds':  {data: { "type" : "kinds", "id": "11-6" }}
                }
                mutations.setExpenseRelationships(expenseRelation)
                this.$emit('step','finance-details')
                let payload = {'end-date': null}
                mutations.setOccupancies(payload)
            } else {
                
                let expenseRelation = {
                    'kinds':  {data: { "type" : "kinds", "id": "11-7" }}
                }
                mutations.setExpenseAttributes(expenstAttr)
                mutations.setExpenseRelationships(expenseRelation)
                
                if(store.isLivedTogether == 'Yes'){
                    if(store.selectedOwner.id.length > 1){

                        let payload = {
                            'occupancy-situation': this.getOccupancySituation(),
                            'residential-situation': 'Own Outright',
                            'end-date': null
                        }

                        if(this.occupancyResource1.hasOwnProperty('id')) {
                           delete  this.occupancyResource1.id 
                           delete  this.occupancyResource2.id 
                        }

                        mutations.setOccupancies(payload)
                        this.occupancyResource1.attributes['end-date'] = null
                        this.occupancyResource2.attributes['end-date'] = null
                        let occupancy1 = await this.createOccupancy(this.occupancyResource1)
                        let occupancy2 = await this.createOccupancy(this.occupancyResource2)
                        let addressResource = store.addressResource

                        if(addressResource.hasOwnProperty('id')){
                            delete addressResource.id
                        }

                        addressResource.relationships.occupancies = {data: [{id:occupancy1.data.data.id, type:'occupancies'},{id:occupancy2.data.data.id, type:'occupancies'}]}
                        let address = await this.createAddress(addressResource)
                        let assetAttr = this.getAssetResource({data: { id: address.data.data.id,type: 'addresses'} }, {data: [{id: store.selectedOwner.id[0],type: 'people'},{id: store.selectedOwner.id[1],type: 'people'}]} )
                        let asset = await this.createAsset(assetAttr)
                        let expenseAttr = this.getExpensesResource( {data: [{id: store.selectedOwner.id[0],type: 'people'},{id: store.selectedOwner.id[1],type: 'people'}]}, {data: [{id: asset.data.data.id,type:'assets'}]})
                        
                        await this.createExpense(expenseAttr)

                        let addressWithOccupancy = {address: address.data.data, occupancy: occupancy1.data.data}
                        this.$emit('inputAddress',addressWithOccupancy)
                        this.$emit('step',null)

                    } else {
                        let occresource1 = null
                        let occresource2 = null
                        let occupancy1= null
                        let occupancy2= null

                        if(this.occupancyResource1.hasOwnProperty('id')) {
                           delete  this.occupancyResource1.id 
                           delete  this.occupancyResource2.id 
                        }

                        let payload = {
                            'occupancy-situation': this.getOccupancySituation(),
                            'residential-situation': 'Own Outright',
                            'end-date': null
                        }

                        mutations.setOccupancies(payload)

                        if(store.selectedOwner.id[0] == this.calculation['primary-applicant-id']){

                            occresource1 = this.occupancyResource1
                            occresource1.attributes['occupancy-situation'] = "Current"
                            occresource1.attributes['residential-situation'] = "Own Outright"
                            occresource1.attributes['end-date'] = null
                            occupancy1 = await this.createOccupancy(occresource1)
                            occresource2 = this.occupancyResource2
                            occresource2.attributes['occupancy-situation'] = "Current"
                            occresource2.attributes['residential-situation'] = "Boarder"
                            occresource2.attributes['end-date'] = null
                            occupancy2 = await this.createOccupancy(occresource2)
                            
                        } else {

                            occresource1 = this.occupancyResource1
                            occresource1.attributes['occupancy-situation'] = "Current"
                            occresource1.attributes['residential-situation'] = "Boarder"
                            occresource1.attributes['end-date'] = null
                            occupancy1 = await this.createOccupancy(occresource1)
                            occresource2 = this.occupancyResource2
                            occresource2.attributes['occupancy-situation'] = "Current"
                            occresource2.attributes['residential-situation'] = "Own Outright"
                            occresource2.attributes['end-date'] = null
                            occupancy2 = await this.createOccupancy(occresource2)
                            
                        }

                        let addressResource = store.addressResource

                        if(addressResource.hasOwnProperty('id')){
                            delete addressResource.id
                        }

                        addressResource.relationships.occupancies = {data: [{id:occupancy1.data.data.id, type:'occupancies'},{id:occupancy2.data.data.id, type:'occupancies'}]}
                        let address = await this.createAddress(addressResource)
                        let assetAttr = this.getAssetResource({data: { id: address.data.data.id,type: 'addresses'} }, {data: [{id: store.selectedOwner.id[0],type: 'people'}]} )
                        let asset = await this.createAsset(assetAttr)
                        let expenseAttr = this.getExpensesResource( {data: [{id: store.selectedOwner.id[0],type: 'people'}]}, {data: [{id: asset.data.data.id,type:'assets'}]})
                        await this.createExpense(expenseAttr)

                        if(this.calculation['primary-applicant-id'] == this.entityId){
                            let addressWithOccupancy = {address: address.data.data, occupancy: occupancy1.data.data}
                            this.$emit('inputAddress',addressWithOccupancy)
                        } else {
                            let addressWithOccupancy = {address: address.data.data, occupancy: occupancy2.data.data}
                            this.$emit('inputAddress',addressWithOccupancy)
                        }

                        this.$emit('step',null)
                        
                    }
                    
                } else {

                   if(store.selectedOwner.id.length > 1) {
                       await this.createOccupancies()
                   } else {
                       await this.createOneOccupancy()
                   }

                }
            }

            await this.updateData()
            
            this.isLoading = false
        },
        back(){
            this.$emit('step','asset-value')
        },
        async createOccupancies() {
            let payload = {
                'occupancy-situation': this.getOccupancySituation(),
                'residential-situation': 'Own Outright',
                'end-date': this.getEndDate()
            }

            if(this.occupancyResource1.hasOwnProperty('id')) {
                delete  this.occupancyResource1.id 
                delete  this.occupancyResource2.id 
            }

            mutations.setOccupancies(payload)

            let occupancy1 = await this.createOccupancy(this.occupancyResource1)
            let occupancy2 = null

            if(occupancy1.status === 201) {
                occupancy2 = await this.createOccupancy(this.occupancyResource2)
            }

            if(occupancy2 && occupancy2.status === 201) {
                let addressResource = store.addressResource

                if(addressResource.hasOwnProperty('id')){
                    delete addressResource.id
                }

                addressResource.relationships.occupancies = {data: [{id:occupancy1.data.data.id, type:'occupancies'},{id:occupancy2.data.data.id, type:'occupancies'}]}
                let address = await this.createAddress(addressResource)
                let assetAttr = this.getAssetResource({data: { id: address.data.data.id,type: 'addresses'} }, {data: [{id: store.selectedOwner.id[0],type: 'people'},{id: store.selectedOwner.id[1],type: 'people'}]} )
                let asset = await this.createAsset(assetAttr)
                let expenseAttr = this.getExpensesResource( {data: [{id: store.selectedOwner.id[0],type: 'people'},{id: store.selectedOwner.id[1],type: 'people'}]}, {data: [{id: asset.data.data.id,type:'assets'}]})
                
                await this.createExpense(expenseAttr)

                let addressWithOccupancy = {address: address.data.data, occupancy: occupancy1.data.data}
                this.$emit('inputAddress',addressWithOccupancy)
                this.$emit('step',null)
            }
        },
        async createOneOccupancy() {
            let payload = {
                'occupancy-situation': this.getOccupancySituation(),
                'residential-situation': 'Own Outright',
                'end-date': this.getEndDate()
            }

            mutations.setOccupancies(payload)

            if(this.occupancyResource1.hasOwnProperty('id')) {
                delete  this.occupancyResource1.id 
                delete  this.occupancyResource2.id 
            }

            let occResource = this.occupancyResource1
            occResource.relationships.person = {
                data: {
                    id:store.selectedOwner.id[0],
                    type: 'people'
                },
                meta: {
                    strategy: 'replace'  
                }
            }

            let occupancy = await this.createOccupancy(occResource)
            let addressResource = store.addressResource
            if(addressResource.hasOwnProperty('id')){
                    delete addressResource.id
            }

            addressResource.relationships.occupancies = {data: [{id:occupancy.data.data.id, type:'occupancies'}]}
            let address = await this.createAddress(addressResource)

            let assetAttr = this.getAssetResource({data: { id: address.data.data.id,type: 'addresses'} }, {data: [{id: store.selectedOwner.id[0],type: 'people'}]} )
            let asset = await this.createAsset(assetAttr)

            let expenseAttr = this.getExpensesResource( {data: [{id: store.selectedOwner.id[0],type: 'people'}]}, {data: [{id: asset.data.data.id,type:'assets'}]})
            
            let addressWithOccupancy = {address: address.data.data, occupancy: occupancy.data.data}

            this.$emit('inputAddress',addressWithOccupancy)

            await this.createExpense(expenseAttr)

            this.$emit('step',null)
        }
    },
    data() {
        return {
            dialog : false,
            fields : [
                
            ],
            isLoading: false,
            occupancySituation : ''
        }
    }
}
</script>
<style lang="scss" >
    
    .is-there-mortgage {
        .custom-btn {
            text-align: center;
            padding: 50px;
            border-radius: 20px;
            font-size: 15px;
            border: 1px solid;
            cursor: pointer;
            width: 250px !important;
            height: 150px !important;
        }
        .custom-btn:hover{
            background: #E7FAFC;
        }
        .custom-disable {
            pointer-events:none !important ;
        }
        .custom-outline1{
            border-color: var(--v-secondary-base);
        }
        .bg-light-blue{
            background: #E7FAFC;
        }
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>