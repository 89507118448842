import { mapState, mapGetters } from 'vuex'
import {store, actions} from '~/components/v1/submission/WestpacEntities1/__store'


export default {  
    computed: {
        ...mapGetters('kinds', ['kinds', 'kindsUnformat']),
        ...mapGetters('themes', [
            'getActiveTheme'
        ]),
        ...mapState('slug', ['flowSlug']),
        ...mapState({
            appId: state => state.flows.appId,
            loanData: state => state.flows.loanData,
        }),
        ...mapGetters('flows', [
            'getCalculation',
        ]),
        getLoanId(){
            return this.appData.relationships.loan.data.id
        },
        carousel: {
            get(){
                return store.carousel
            },
            set(value){
                actions.setCarousel(value)
            }
        },
        entityRole: {
            get(){
                return store.roleKind
            },
            set(value){
                actions.setRoleKind(value)
            }
        },
        steps() {
            return store.steps
        },
        currentStep() {
            return this.steps[this.carousel]
        },
        isFirstStep() {
            return this.carousel === 0
        },
        isLastStep() {
            return this.carousel === this.steps.length - 1
        },
        lenderLogo() {
            return this.flowSlug.includes('westpac') ? require(`~/assets/images/logo/cropped/lender-logo-westpac.png`) : require(`~/assets/images/logo/cropped/lender-logo-capital.png`)
        },
        isCommercialPartnerShip(){
            return this.getCalculation['application-type'] == "Commercial - Partnership"
        }
    },
    methods: {

        filterResourceFields(source, target){

            // console.log(source)
            // console.log(target)

            let newResource = {
                    type: "",
                    id: null,
                    attributes: {},
                    relationships: {}
            }

            Object.keys(source.attributes).forEach( field => {
                newResource.attributes[field] = target.attributes[field]
            })

            newResource.type = target.type
            newResource.id = target.id

            if(target.hasOwnProperty('relationships')) {

                Object.keys(target.relationships).forEach(item => {

                    if( target.relationships[item].hasOwnProperty('data') ) {
                        newResource.relationships[item] = target.relationships[item]
                    }
                })

            }
            return newResource
        },
        getEntityByRoles(data){

            if(!data.included){
                return [];
            }

            let parts = data.included.filter(part => {
                return part.type == 'parts'
            })

            let roleIds = [];	
            let rolesObj = {}
            
            parts.forEach(part => {
                
                if (!part.relationships.kind.data) {	
                    // person must have part with kind
                    return []
                }
                
                this.getApplicantKinds.forEach(kind => {

                    if(kind.id == part.relationships.kind.data.id) {

                        roleIds.push(part.id);
                        rolesObj[part.id] = kind.name

                    }

                })
            })

            if(roleIds.length == 0) {
                return []
            } 

            let entities = [] 
            
            data.data.forEach( entity => {

                let parentParts = entity.relationships['parent-parts'].data

                entity.roles = []

                if( parentParts.length ){

                    let included = false;

                    parentParts.forEach( part => {

                        if(roleIds.includes(part.id) ) {

                            // Add extra data to entity
                            entity.roles.push(rolesObj[part.id])

                            // Include entity once
                            if(!included){

                                // Remove Company Partners & Partnership 
                                if(this.isCommercialPartnerShip && entity.roles.includes('Applicant')) {
                                    return
                                }
                                if(entity.type == 'businesses' && entity.roles.includes('Partner')) {
                                    return
                                }

                                entities.push(entity)
                                included = true
                            }

                        }

                    })

                }

            })

            return entities 

        },
        hexToRGBA(hex, a = 1){
            return this.$options.filters.hexToRGBA(hex, a)
        },
        getFriendlyName(role){

            let fullname = ''

            if(role && role.type == 'businesses') {

                fullname += role.attributes['business-legal-name'] ? role.attributes['business-legal-name'] : 'Business'

            }

            if(role && role.type == 'people'){

                fullname += role.attributes['first-name'] ? role.attributes['first-name'] + ' ' : ''

                fullname += role.attributes['last-name'] ? role.attributes['last-name'] : ''

                if(!role.attributes['first-name'] && !role.attributes['last-name']){
                    fullname = 'Person'
                }
            }

            return fullname

        },
        getRoleKind(role){

            let roleKind = null
            let roleKindName = ''

            if( role.hasOwnProperty('relationships') ){

                roleKind = this.kindsUnformat.find(kind => kind.id == role.relationships?.kind?.data?.id)

                if(roleKind){
                    roleKindName = roleKind.attributes.name 
                }
            }

            return roleKindName

        },
        getFullAddress(address){

            if(!address) return "";

            if(!address.hasOwnProperty('attributes')) return "";
            
            return  `${address.attributes["street-number"] == null ? '' : address.attributes["street-number"]} ${address.attributes["street-name"] == null ? '' : address.attributes["street-name"]} ${address.attributes["street-type"] == null ? '' : address.attributes["street-type"]}, ${address.attributes["suburb"] == null ? '' : address.attributes["suburb"]} ${address.attributes["state"] == null ? '' : address.attributes["state"]} ${address.attributes["postcode"] == null ? '' : address.attributes["postcode"]}`
        },
    },
}