<template>
    <v-card class="mx-auto" :disabled="isDisabled" tile flat>

        <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
            <v-container pa-0 fluid>
                <v-row>
                    <v-col cols="12">
                        <ValidationObserver ref="observer" tag="form">
                            <div v-show="false" class="flex-form">
                                <div
                                    v-for="(field, fkey) in fields"
                                    :key="fkey" 
                                    class="flex-field">

                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="field.meta | formalText"
                                        rules="required"
                                        :vid="field.meta">
                                        <v-text-field
                                            v-model="resourceObj[field.meta]"
                                            :error-messages="errors"
                                            :label="field.label">
                                        </v-text-field>
                                    </ValidationProvider>

                                </div>
                            </div>
                            <div class="flex-field">

                                <v-data-table
                                    :loading="isDisabled"
                                    loading-text="Please wait..."
                                    v-if="validComponentData"
                                    :headers="headers"
                                    :items="lenders"
                                    hide-default-footer
                                    :mobile-breakpoint="0"
                                    hide-default-header
                                    :style=" vb.mdAndUp ? 'width: 870px;' : 'width: 100%;'"
                                    class="elevation-0 v-data-table-2 mx-auto">

                                    <!-- Body -->
                                    <template v-slot:item.compare="{ item, index }">

                                        <v-card class="card-row ma-2 pa-2 rounded-xl">

                                            <!-- # Header side of table -->
                                            <div class="px-2">
                                                <div class="d-flex justify-space-between">
                                                    <div class="d-flex">
                                                        <div class="px-2">
                                                            <div v-if="hideLenderIndentity" class="title font-weight-bold pt-4">
                                                                {{ item['alias'] }}
                                                            </div>
                                                            <v-img 
                                                                v-else
                                                                max-width="80"
                                                                class="flex-brand" 
                                                                :src="getLenderLogo(item['logo-class'])" 
                                                                position="center left">
                                                            </v-img>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="pt-4 pr-6 d-flex">
                                                        <p class="td-inner ">{{ item['actual-payment']['monthly-payment'] | currency }}</p>
                                                        <span class="body-2 mx-1"> / month</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- # line break -->
                                            <hr color="#efecec">

                                            <!-- # md and up design -->
                                            <div v-if="vb.mdAndUp" class="d-flex justify-space-between">

                                                <div class="d-flex flex-column justify-start ml-4 mt-4">
                                                    <p class="my-0 caption ">Loan Term</p>
                                                    <p class="font-weight-bold td-inner">{{ item['actual-payment']['term-in-months']  }} Months</p>
                                                </div>

                                                <div class="d-flex flex-column justify-start ml-4 mt-4">
                                                    <p class="my-0 caption">Interest Rate</p>
                                                    <p class="font-weight-bold td-inner">{{ (item['actual-payment']['customer-rate'] ) | percentage }}</p>
                                                </div>

                                                <div class="d-flex flex-column justify-start ml-4 mt-4">
                                                    <p class="my-0 caption">Comparison Rate</p>
                                                    <p class="font-weight-bold td-inner">{{ item['actual-payment']['comparison-rate'] | percentage }}</p>
                                                </div>

                                                <div class="inner end">
                                                    <v-btn class="mr-2" color="primary" :outlined="selected.indexOf(item.name) == -1" @click="setSelect(item.name)" large depressed> Select </v-btn>
                                                    <v-btn class="mr-2" color="secondary" outlined @click="showInfoDialog(item)" large depressed> More Info</v-btn>
                                                    <v-btn 
                                                        :color="resourceObj['selected-lender-quote-algorithm-slug'] === item.slug ? 'secondary is-disabled' : 'secondary'"
                                                        :disabled="disabled"
                                                        @click="setSelected(item.slug, item)"
                                                        large
                                                        depressed>{{ resourceObj['selected-lender-quote-algorithm-slug'] === item.slug ? 'Applied' : applyButtonLabel }}
                                                    </v-btn>
                                                </div>
                                                
                                            </div>

                                            <!-- # mobile resposive -->
                                            <div v-else>

                                                <div class="d-flex justify-space-between">
                                                    <div class="d-flex flex-column justify-start ml-4 mt-4">
                                                        <p class="my-0 caption">Loan Term</p>
                                                        <p class="font-weight-bold">{{ item['actual-payment']['term-in-months']  }} Months</p>
                                                    </div>

                                                    <div class="d-flex flex-column justify-start ml-4 mt-4">
                                                        <p class="my-0 caption">Interest Rate</p>
                                                        <p class="font-weight-bold">{{ (item['actual-payment']['customer-rate'] ) | percentage }}</p>
                                                    </div>

                                                    <div class="d-flex flex-column justify-start mx-2 mt-4">
                                                        <p class="my-0 caption">Comparison Rate</p>
                                                        <p class="font-weight-bold">{{ item['actual-payment']['comparison-rate'] | percentage }}</p>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-center mt-n5">

                                                    <div class="inner end-mobile">

                                                        <v-btn class="mr-2" color="primary" :outlined="selected.indexOf(item.name) == -1" @click="setSelect(item.name)" large depressed> Select </v-btn>
                                                        <v-btn class="mr-2" color="secondary" outlined @click="showInfoDialog(item)" large depressed> Info </v-btn>
                                                        <v-btn 
                                                            :color="resourceObj['selected-lender-quote-algorithm-slug'] === item.slug ? 'secondary is-disabled' : 'secondary'"
                                                            :disabled="disabled"
                                                            @click="setSelected(item.slug, item)"
                                                            large
                                                            depressed>{{ resourceObj['selected-lender-quote-algorithm-slug'] === item.slug ? 'Applied' : applyButtonLabel }}
                                                        </v-btn>

                                                    </div>

                                                </div>
                                                
                                            </div>

                                        </v-card>
                                        <br>
                                        
                                    </template>

                                </v-data-table>
                                
                                <v-card v-else tile flat class="rounded-lg mx-auto" max-width="700">
                                    <v-card-text class="text-center pa-0 pa-md-66">
                                        <v-icon size="48" color="secondary">mdi-information</v-icon>
                                        <v-spacer class="mb-6"/>
                                        <div class="body_text_color--text">
                                            <div class="headline mb-6">Sorry, we are having trouble locating options based on the information provided.</div>
                                            <p class="subtitle-1">But, please continue and submit the application with detailed submission notes, and our team will look at options using the entire panel.</p>
                                        </div>
                                    </v-card-text>
                                </v-card>

                            </div>
                        </ValidationObserver>
                    </v-col>
                    <v-col cols="12">
                        
                        <v-sheet max-width="854" class="mx-auto">
                            <div class="text-center">

                                <template v-if="hasLenders">
                                    <v-btn @click="showCompareDialog" color="primary" large depressed :disabled="selectedCount < 2 || selectedCount > 3">Compare Selected Products</v-btn>
                                </template>
                                <template v-else>
                                   <v-btn @click="handleContinue" color="primary" large depressed><div class="px-4">Continue</div></v-btn>
                                </template>

                            </div>
                        </v-sheet>

                    </v-col>
                    <v-col cols="12" class="mt-6">
                        
                        <v-sheet max-width="854" class="mx-auto">
                            <p class="caption text-center gray" v-text="getDisclaimer"></p>
                        </v-sheet>

                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <!-- More Info Modal -->
        <v-dialog
        max-width="520"
        v-if="dialogData"
        v-model="dialog">

            <v-card flat>

                <!-- <v-toolbar flat class="pt-3 pr-6 mb-n8">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar> -->

                <v-card-title class="py-0 px-4 px-sm-8">
                    <div v-if="hideLenderIndentity" class="title font-weight-bold py-6 pl-4 mx-auto">
                        {{ dialogData['alias'] }}
                    </div>
                    <v-img
                        v-else
                        max-width="100"
                        class="mx-auto"
                        :src="getLenderLogo(dialogData['logo-class'])" 
                        position="center" 
                        contain>
                    </v-img>
                    <v-btn icon @click="dialog = false" fab class="ml-n6">
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="px-4 px-sm-8 pt-0 pb-10 text-center"> 

                    <v-container fluid pa-0>

                        <v-row class="mt-n4" :class="vb.mdAndUp ? 'mx-1' : 'mx-0'">

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 lightgray my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Requested Amount</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ required_amount }}</div> 
                                </div>
                            </v-col>
                            
                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Origination Fee</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['broker-fee'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 lightgray my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Bank Setup Fees</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['bank-fees'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Amount Financed</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['naf'] | currency }}</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-6 my-1 lightgray">
                                <div class="font-weight-bold subtitle-1"></div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'"></div> 
                                </div>
                            </v-col>
                            
                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Loan Term</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ dialogData['actual-payment']['term-in-months'] }} Months</div> 
                                </div>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-space-between rounded-lg px-4 px-md-6 py-4 lightgray my-1">
                                <div class="font-weight-bold" :class="vb.mdAndUp ? 'subtitle-1' : 'caption'">Weekly Payment</div> 
                                <div style="width:40%;">
                                    <div :class="vb.mdAndUp ? 'body-1' : 'caption'">{{ ((dialogData['actual-payment']['monthly-payment'] * 12) / 52) | currency }}</div> 
                                </div>
                            </v-col>

                        </v-row>

                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>

        <!-- Compare Modal -->
        <v-dialog
            max-width="950"
            persistent
            scrollable
            v-model="dialog2">

            <!-- # md and up -->
            <v-card v-if="vb.mdAndUp" flat :disabled="isDisabled">

                <v-toolbar flat class="pt-3 pr-6">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog2 = false" fab>
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-title class="d-flex justify-start px-4 px-md-6 py-0">
                        <v-row class="mx-0 px-0" v-if="hideLenderIndentity">
                            <v-col class="pa-0 d-flex align-center" :md="selectedCount == 3 ? '3' : '4'"><h3 class="font-weight-bold">Compare Products</h3></v-col>
                            <v-col class="mx-0 px-0" :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="title text-center font-weight-bold">
                                    {{ lender.alias }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0 px-0 mt-n10 mb-n4" v-else>
                            <v-col class="pa-0 d-flex align-center" :md="selectedCount == 3 ? '3' : '4'"><h3 class="font-weight-bold">Compare Products</h3></v-col>
                            <v-col class="mx-0 px-0" :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div>
                                    <v-img 
                                        height="90"
                                        max-width="120"
                                        class="mx-auto"
                                        :src="getLenderLogo(lender['logo-class'])"
                                        contain>
                                    </v-img>
                                </div>
                            </v-col>
                        </v-row>
                </v-card-title>

                <v-card-text class="px-4 pt-0 pb-10"> 

                    <div class="d-flex justify-start rounded-xl px-4 px-md-6 py-0 lightgray"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Requested Amount</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ required_amount }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start rounded-xl mt-1 px-4 px-md-6 py-0"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Origination Fee</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ lender['actual-payment']['broker-fee'] | currency }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start rounded-xl mt-1 px-4 px-md-6 py-0 lightgray"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Bank Setup Fees</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ lender['actual-payment']['bank-fees'] | currency }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start rounded-xl mt-1 px-4 px-md-6 py-0"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Amount Financed</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ lender['actual-payment']['naf'] | currency }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="d-flex justify-start rounded-xl mt-1 px-4 py-3 lightgray"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2"></div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start rounded-xl mt-1 px-4 px-md-6 py-0"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Loan Term</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ lender['actual-payment']['term-in-months'] }} Months
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start rounded-xl mt-1 px-4 px-md-6 py-0 lightgray"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2">Weekly Payment</div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    {{ ((lender['actual-payment']['monthly-payment'] * 12) / 52) | currency }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex justify-start rounded-xl mt-1 px-4 px-md-6"> 
                        <v-row>
                            <v-col :md="selectedCount == 3 ? '3' : '4'"><div class="font-weight-bold subtitle-2"></div> </v-col>
                            <v-col :md="selectedCount == 3 ? '3' : '4'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <v-btn 
                                        :color="resourceObj['selected-lender-quote-algorithm-slug'] === lender.name ? 'secondary is-disabled' : 'secondary'"
                                        :disabled="disabled"
                                        @click="setSelected(lender.name, lender)"
                                        small
                                        depressed>{{ resourceObj['selected-lender-quote-algorithm-slug'] === lender.name ? 'Applied' : applyButtonLabel }}
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row class="px-2">
                        <v-col cols="12" class="mt-2">
                        
                            <v-sheet max-width="854" class="mx-auto">
                                <p class="caption text-center gray ma-0" v-text="getDisclaimer"></p>
                            </v-sheet>

                        </v-col>
                    </v-row>
                    
                </v-card-text>

            </v-card>

            <!-- # mobile responsive -->
            <v-card v-else flat>

                <v-card-title class="px-6 pt-4 pb-0 mb-n10 pt-6">
                    <v-spacer/>
                    <h4 class="font-weight-bold ml-8">Compare Lenders</h4>
                    <v-spacer/>
                    <v-btn icon @click="dialog2 = false">
                        <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <div class="d-flex mx-0 px-0 mt-4">
                    <v-row class="mx-0 px-0 mt-4 mb-0 pb-0">
                        <v-col class="mx-0 px-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                            <div v-if="hideLenderIndentity" class="title text-center font-weight-bold">
                                {{ lender.alias }}
                            </div>
                            <div v-else>
                                <v-img 
                                    height="60"
                                    max-width="80"
                                    class="mx-auto"
                                    :src="getLenderLogo(lender['logo-class'])"
                                    contain>
                                </v-img>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <v-card-text class="px-4"> 
                    <div class="d-flex flex-column text-center rounded-xl pa-2 lightgray"> 
                        <v-row class="mt-2">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Requested Amount</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ required_amount }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center rounded-xl pa-2"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Origination Fee</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ lender['actual-payment']['broker-fee'] | currency }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center rounded-xl pa-2 lightgray"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Bank Setup Fees</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ lender['actual-payment']['bank-fees'] | currency }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center rounded-xl pa-2"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Amount Financed</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ lender['actual-payment']['naf'] | currency }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center rounded-xl pa-2 lightgray py-8"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2"></v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center rounded-xl pa-2"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Loan Term</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ lender['actual-payment']['term-in-months'] }} Months</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center rounded-xl pa-2 lightgray"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2">Weekly Payment</v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>{{ ((lender['actual-payment']['monthly-payment'] * 12 ) / 52) | currency }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="d-flex flex-column text-center rounded-xl pa-2"> 
                        <v-row class="mt-2 pt-1">
                            <v-col cols="12" class="pt-0 font-weight-bold subtitle-2"></v-col> 
                            <v-col class="mx-0 px-0 py-0 my-0" :md="selectedCount == 3 ? '4' : '6'" v-for="lender in selectedLenders" :key="lender.name">
                                <div class="text-center body-2">
                                    <p>
                                        <v-btn 
                                            :color="resourceObj['selected-lender-quote-algorithm-slug'] === lender.slug ? 'secondary is-disabled' : 'secondary'"
                                            :disabled="disabled"
                                            @click="setSelected(lender.name, lender)"
                                            small
                                            depressed>{{ resourceObj['selected-lender-quote-algorithm-slug'] === lender.slug ? 'Applied' : applyButtonLabel }}
                                        </v-btn>
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row class="px-2">
                        <v-col cols="12" class="mt-4">
                        
                            <v-sheet max-width="854" class="mx-auto">
                                <p class="caption text-center gray" v-text="getDisclaimer"></p>
                            </v-sheet>

                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>

        </v-dialog>

    </v-card>
</template>

<script>

    import subscriptions from '~/components/shared/subscriptions'
    import { mapActions, mapGetters, mapMutations,mapState } from 'vuex'
    import { isEmpty } from 'lodash'

    export default {
        name: 'QuoteResult2',
        mixins: [subscriptions],
        data() {
            return {
                required_amount: '',
                dialog: false,
                dialog2: false,
                selected: [],
                dialogData: null,
                validComponentData: true,
                lenders: [],
                quotes: {},
                resources: {                        
                    "type": "loans",
                    "id": null,
                    "attributes": {
                        "selected-lender-quote-algorithm-slug": null
                    },
                    "relationships": {
                        "application": {
                            "data": {
                                "type": "applications",
                                "id": null
                            },
                        }
                    }
                },
                headers: [
                    { text: '', sortable: false, value: 'compare', width: 'auto'},
                    // { text: 'Lender', sortable: false, value: 'lender', width: '15%'},
                    // { text: 'Repayments', sortable: false, value: 'repayments', width: '20%'},
                    // { text: 'Loan Term', sortable: false, value: 'loan-term', width: '20%'},
                    // { text: 'Comparison Rate', sortable: false, value: 'comparison-rate', width: '20%'},
                    // { text: 'Customer Rate', sortable: false, value: 'customer-rate', width: '20%'},
                    // { text: '', sortable: false, value: 'action'}
                ],
                resourceObj: {

                },
                fields: {
                    'selected-lender-quote-algorithm-slug': { label: 'Lender', meta: 'selected-lender-quote-algorithm-slug' }
                },
                disabled: false,
                settings: {
                    hideLenderNames: 0,
                    customerDiscountRate: 0,
                    originationFee: 1000
                },
                productAlias: [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'C' ]
            }
        },
        beforeCreate() {
            window.scrollTo(0, 0)
        },
        mounted(){

            // console.log(this.lenders)

            Object.keys(this.blockSettings).forEach(item => {

                if(this.settings.hasOwnProperty(item)){
                    this.settings[item] = this.blockSettings[item]
                }
            })

            // console.log(this.settings)

        },
        computed: {
            ...mapState({
                appGuid: state => state.flows.appGuid,
                flowData: state => state.flows.flowData,
            }),
            vb(){
                return this.$vuetify.breakpoint
            },
            getLoanId(){
                return this.appData.relationships.loan.data.id
            },
            hideLenderIndentity(){
                return this.settings.hideLenderNames == 1
            },
            selectedCount(){

                return this.selected.length

            },
            selectedLenders(){

                let lenders = []


                this.lenders.forEach( lender => {

                    if( this.selected.includes(lender.name)) {

                        lenders.push(lender)
                    }

                })

                return lenders

            },
            hasLenders(){
                return this.lenders.length > 0
            },
            getDisclaimer() {

                let defaultText = "*Terms and conditions apply. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982."

                if (this.blockSettings.disclaimer) {

                    switch (this.blockSettings.disclaimer) {
                        case "assumed-parameters":
                            return "*Terms and conditions apply. Some parameters for the quote have been assumed, therefore the quote may change once accurate information has been received about these assumptions. Finance for credit approved purchaser's only. The finance offer is for amounts and terms shown above. The comparison rate is based on the amounts and terms above at the annual percentage rate shown above. The amount of credit provided to you and the term of the loan may be different to this WARNING. The comparison rate only applies to the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Credit Asssistance is provided through Online Loans ABN 58 610 067 715 and/or its panel of lenders. Australian Credit Licence Number 484982."
                    
                        default:
                            return defaultText;
                    }

                }

                return defaultText
            },
            applyButtonLabel() {
                if (this.blockSettings.applyButtonLabel) {
                    return this.blockSettings.applyButtonLabel
                } else {
                    return 'Apply'
                }
            }
        },
        methods: {
            ...mapActions('flows', ['selectNextStep']),
            ...mapActions('resource-actions', [
                'getQuotes',
                'updateLoan',
                'setApplicationNow',
                'runAssisterSubmit'
            ]),
            ...mapMutations('subscriptions', [
                'setStepableDataWorkable'
            ]),
            async pull() {

                // Update Loan first with user testing values before getting the getQuotes
                
                if (this.getLoanId) {

                    let payload = {
                        type: 'loans',
                        id: this.getLoanId,
                        attributes: {
                            "customer-discount-rate": this.settings.customerDiscountRate,
                            "requested-broker-fee": this.settings.originationFee
                        }
                    }

                    await this.updateLoan(payload)
                }

                let response = await this.getQuotes({                    
                    'type': 'applications',
                    'id': this.appId,
                    'attributes': {}
                })

                if ( response.length > 1 ) {

                    response.forEach( res => {
                        if (res.data.data.type === 'applications') {

                            let list = res?.data?.included?.[0]?.attributes?.results ? res?.data.included[0].attributes.results : {}
                            
                            if ( !isEmpty(list) ) 
                                list = JSON.parse( list )

                            this.quotes = list?.lenders
                            this.required_amount = list?.['required-amount']

                        } else {
                            this.resources = res.data.data
                            this.resources.relationships = this.removeEmptyRelationships(this.resources.relationships)
                        }
                    })
                }

                if (this.quotes)
                    Object.keys(this.quotes).forEach( (lender, index) => {
                        this.lenders.push(this.quotes[lender])
                        this.lenders[index]['slug'] = lender
                        this.lenders[index]['alias'] = `Product ${this.productAlias[index]}`
                    })

                this.resourceObj = { 
                    'selected-lender-quote-algorithm-slug': this.resources.attributes['selected-lender-quote-algorithm-slug']
                }

                // sort lenders from lowest to highest monthly repayments
                if (this.lenders.length > 0)
                    this.lenders = this.sortLenders(this.lenders)

                // console.log(this.lenders)
                this.validComponentData = this.lenders.length > 0 ? true : false
            },

            async push() {

                let requestPayload = Object.assign({}, this.resources)

                let res = await this.setApplicationNow({
                    payload: this.resources,
                    picked: this.resourceObj['selected-lender-quote-algorithm-slug']
                })
                
                await this.assisterSubmit()
                
                const {valid, status} = await this.validateFormData()
                let data = this.$options.filters.toAppDataFormat(res)

                return  { valid, status, data } 
            },
            async assisterSubmit() {

                let { appGuid, flowData } = this

                try {

                    let payload = {
                        'application': appGuid,
                        'flow': flowData.attributes.slug
                    }

                    await this.runAssisterSubmit(payload)

                } catch (err) {
                    console.log('Uh oh! ', err)
                }

            },
            sortLenders(lenders) {
                let data = []
                lenders.forEach(item => {
                    if(item.quotable == "Yes") {
                        data.push(item)
                    }
                })
                data.sort((a, b) => {
                    if (a['actual-payment']['monthly-payment'] && b['actual-payment']['monthly-payment'])
                        return a['actual-payment']['monthly-payment'] - b['actual-payment']['monthly-payment']
                    
                })

                return data;
            },

            handleContinue(){

                this.resourceObj = { 'selected-lender-quote-algorithm-slug': "No options available" }
                this.selectNextStep()
                
            },
            setSelected(name = '', lender = {}) {

                this.resourceObj = { 'selected-lender-quote-algorithm-slug': name }

                this.populateLoanFields(lender)

                this.selectNextStep()

            },

            populateLoanFields(item) {

                this.resources.attributes['quoted-balloon'] = item['actual-payment']['balloon']
                this.resources.attributes['quoted-bank-fees'] = item['actual-payment']['bank-fees']
                this.resources.attributes['quoted-base-rate'] = item['actual-payment']['base-rate']
                this.resources.attributes['quoted-broker-commission-inc-gst'] = item['actual-payment']['commission-inc-gst']
                this.resources.attributes['quoted-broker-fee'] = item['actual-payment']['broker-fee']
                this.resources.attributes['quoted-customer-rate'] = item['actual-payment']['customer-rate']
                this.resources.attributes['quoted-monthly-payments'] = item['actual-payment']['monthly-payment']
                this.resources.attributes['quoted-naf'] = item['actual-payment']['naf']
                this.resources.attributes['quoted-account-fee'] = item['actual-payment']['account-fee']
                this.resources.attributes['quoted-term-months'] = item['actual-payment']['term-in-months']
                this.resources.attributes['selected-lender-quote-algorithm-slug'] = item.slug

            },

            setSelect(name = '') {

                if (this.selected.includes(name)) {
                    this.selected = this.selected.filter(
                        selected => selected !== name
                    )
                } else {
                    this.selected.push(name)
                }

            },

            removeEmptyRelationships(relationships) {
                
                let temp = {}

                Object.keys(relationships).forEach( key => {
                    if (relationships[key].data?.length > 0 || relationships[key].data?.id !== undefined)
                        temp[key] = relationships[key]
                })

                return temp
            },
            showInfoDialog(data){
                // console.log(data)

                this.dialogData = data
                this.dialog = true

            },
            showCompareDialog(){
                
                this.dialog2 = true

            },
            getLenderLogo(name){

                let lender = require(`~/assets/images/logo/placeholder.png`)

                try {

                    lender = require(`~/assets/images/logo/${name}.png`)

                    return lender

                } catch (error) {

                    console.log('Ooops!..', error)

                    return lender

                }

            }
        },
        watch: {

            "validComponentData": {
                handler(val) {
                    if ( !this.validComponentData ) {

                        this.setStepableDataWorkable(false)

                    }
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>

    .flex-form {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 0;
        grid-template-columns:  repeat(auto-fit, minmax(260px, 1fr));

        .flex-field {
            align-self: start;
        }
    }

    .inline-icons {
        display: flex;
    }

    .caption.text-center.gray {
        color: gray;
    }

    .lightgray {
        background-color: #fafafa;
    }

</style> 