<template>
    <v-dialog
        v-if="!isNodifiConsumer"
        v-model="showDialog"
        persistent
        width="1000">
        <v-card :disabled="processing">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="processing"></v-progress-linear>
            
            <v-card-title class="px-8 pt-8">
                <div class="v-card__title sm pa-0 mx-auto">
                    {{ title }}
                </div>
            </v-card-title>

            <v-card-text class="px-8 py0 body-1">
                <v-radio-group v-model="compliance" row background-color="white">
                    <div class="justify-center d-flex">
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-card
                                    :ripple="false"
                                    elevation="0"
                                    class="pa-8 rounded-lg ma-2 fill-height"
                                    :class="compliance == 'letNodifiHandleIt' ? 'secondary lighten-5' : 'light_gray_1'"
                                    @click="selectCompliance('letNodifiHandleIt')" >
                                    <div class="d-flex">
                                        <v-radio
                                            value="letNodifiHandleIt"
                                            color="secondary"
                                        ></v-radio>
                                        <div class="text-h6 font-weight-bold">
                                            Let {{ getTenantAssister }} handle it
                                        </div>
                                    </div>
                                    <div class="pl-12 pt-3">

                                        <template v-if="getTenant == 'nodifi'">
                                            <div class="body-2">Select this option if you'd like to refer a Hybrid Full Application to {{ getTenantAssister }}, who will then handle all customer communication and compliance. You will be paid a referral fee.</div>
                                        </template>  
                                        <template v-else>
                                            <div class="body-1 font-weight-bold mb-2">Hybrid Full Application</div>
                                            <div class="body-2">Select this option if you'd like to refer a Hybrid Full Application to {{ getTenantAssister }}, who will then handle all customer communication and compliance. You will be paid a referral fee.</div>
                                    </template>  

                                    </div>
                                </v-card>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <v-card
                                    flat
                                    :ripple="false"
                                    class="pa-8 rounded-lg ma-2 fill-height"
                                    :class="compliance == 'iWillHanldeIt' ? 'secondary lighten-5' : 'light_gray_1'"
                                    elevation="0"
                                    @click="selectCompliance('iWillHanldeIt')">
                                    <div class="d-flex">
                                        <v-radio
                                            value="iWillHanldeIt"
                                            color="secondary"
                                        ></v-radio>
                                        <div class="d-flex flex-column">
                                            <div class="text-h6 font-weight-bold">
                                                I will handle it
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pl-12 pt-3">


                                        <template v-if="getTenant == 'nodifi'">
                                            <div class="body-2">Select this option if you'd like to submit to {{ getTenantAssister }} as a Co-Pilot Full Application. This allows you to maintain 100% control of customer communication and loan setup details while using the Loan Market Asset Finance support team for guidance and processing support.</div>
                                        </template>  
                                        <template v-else>
                                            <div class="body-1 font-weight-bold mb-2">Consumer - BID Document Generation</div>
                                            <div class="body-2 mb-6">If this is a consumer application, select this option if you'd like to submit directly to a lender, and use {{ getTenantAssister }} to print BID Documentation.</div>

                                            <div class="body-1 font-weight-bold mb-2">Commercial - Co-Pilot Full Application</div>
                                            <div class="body-2">If this is a commercial application, select this option if you'd like to submit to {{ getTenantAssister }} as a Co-Pilot Full Application. This allows you to maintain 100% control of the customer communication and loan setup details, while using the {{ getTenantAssister }} support team for guidance and processing support.</div>
                                        </template>  
                                        
                                    </div>
                                </v-card>
                            </v-col>

                        </v-row>
                        
                    </div>
                </v-radio-group>
            </v-card-text>

            <v-card-actions class="px-9 pb-8">
                <v-spacer></v-spacer>

                <v-btn
                    color="secondary"
                    large depressed
                    :disabled="!compliance"
                    min-width="140"
                    @click="handleClick">
                        Next
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import {mapGetters } from 'vuex'

    export default {
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Customer Communication and Compliance'
            },
            processing: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                compliance: null,
                isHybrid: null
            }
        },

        computed: {
            ...mapGetters('slug', [
                'getTenant',
                'getTenantAssister',
            ]),
            ...mapGetters('flows', [
                'getCalculation',
            ]),
            showDialog() {
                return this.showModal
            },
            isNodifiConsumer(){
                return this.getCalculation?.['application-type'].includes('Consumer') && ["nodifi", "lmgaf1"].includes(this.getTenant)
            }
        },
        mounted(){

            if(this.isNodifiConsumer) {
                this.$emit('handle-next', 1) // Force Hybrid if Nodifi Consumer
            }

        },
        methods: {

            selectCompliance(data) {

                this.compliance = data

                if(data == 'letNodifiHandleIt') {
                    this.isHybrid = 1
                } else {
                    this.isHybrid = 0
                }

            },

            handleClick() {

                if(this.isHybrid != null) {
                    this.$emit('handle-next', this.isHybrid)
                }

            },

        },
    }
</script>

<style lang="scss" scoped>

</style>