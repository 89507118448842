<template>
    <v-card flat color="rgba(255, 0, 0, 0.0)" :disabled="isDisabled">
        <div class="d-flex flex-column justify-center align-center flex-box pt-6 pb-0 mr-4">
            <template v-if="getLeftDrawerVariant">
                <div class="mx-auto text-center">
                    <v-icon size="36" color="white">{{ getAppTypeFE.icon }}</v-icon>
                </div>
            </template>
            <template v-else>
                <div class="mx-auto text-center mb-5">
                    <v-icon size="56" color="white" class="mb-4">{{ getAppTypeFE.icon }}</v-icon>                        
                    <div>
                        
                        <div 
                            v-if="!showConvertAppButton"
                            v-html="getAppTypeFE.markup" 
                            class="font-weight-bold white--text flex-tile-text">
                        </div>

                        <v-menu 
                            :close-on-click="false"
                            :close-on-content-click="false"
                            v-else
                            v-model="menu"
                            bottom offset-x
                            content-class="context-menu convert-menu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    @click.native="toggleMenu"
                                    color="light_gray_1"
                                    icon="mdi-pencil"
                                    overlap
                                    v-bind="attrs"
                                    v-on="on">                            
                                    <div 
                                        v-html="getAppTypeFE.markup" 
                                        class="font-weight-bold white--text flex-tile-text">
                                    </div>                            
                                </v-badge>
                            </template>

                            <template v-slot:default>
                                <v-card>
                                    <v-card-title class="headline white lighten-2">
                                        Convert Application
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="menu=false">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>

                                    <v-list class="white">
                                        <v-list-item>
                                            <v-list-item-title class="overline light_gray_2--text px-3">Loan Type</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item class="ml-3">
                                            <ButtonGroup1 
                                                :modelValue="loanType"
                                                @input="changeLoanTypeClick"
                                                label="" 
                                                :options="loanTypeOptions"/>
                                        </v-list-item>
                                    </v-list>

                                    <v-list class="white" flat>
                                        <v-list-item>
                                            <v-list-item-title class="overline light_gray_2--text px-3">Application Type</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item class="mb-5">
                                            <v-row no-gutters>
                                                <v-col sm="6">
                                                    <div class="body-2 mb-3">{{ menuBadgeItemsConsumer.title }}</div>
                                                    <v-list>
                                                        <v-list-item-group v-model="chosenConsumer" class="application-menu-type" active-class="secondary white--text">
                                                            <v-list-item v-for="(item, index) in menuBadgeItemsConsumer.children" :key="index" :value="index"  @click="selectItem('consumer', item)">
                                                                <v-list-item-title class="body-2">
                                                                    <v-icon class="pr-4" color="light_gray_2">{{ item.icon }}</v-icon>
                                                                    <span class="font-weight-medium item-title">{{ item.title }}</span>
                                                                </v-list-item-title>
                                                            </v-list-item>
                                                        </v-list-item-group>
                                                    </v-list>
                                                </v-col>

                                                <v-col sm="6">
                                                    <v-list-item-group v-model="chosenCommercial" class="application-menu-type" active-class="secondary white--text">
                                                        <div class="body-2 mb-3">{{ menuBadgeItemsCommerical.title }}</div>
                                                        <v-list-item v-for="(item, index) in menuBadgeItemsCommerical.children" :key="index" @click="selectCommercial(item)">
                                                            <v-list-item-title class="body-2">
                                                                <v-icon class="pr-4" color="light_gray_1">{{ item.icon }}</v-icon>
                                                                <span class="font-weight-medium item-title">{{ item.title }}</span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-col>
                                            </v-row>
                                        </v-list-item>

                                        <div class="mt-5 mb-5 text-center">
                                            <v-btn
                                            color="secondary" 
                                            large 
                                            :disabled="selectedConversion == ''"
                                            depressed 
                                            :loading="saving"
                                            @click="convertApp(selectedConversion)">
                                                <div class="px-2">
                                                    Convert
                                                </div>
                                            </v-btn>
                                        </div>
                                    </v-list>
                                </v-card>
                            </template>
                        </v-menu>

                    </div>                        
                </div>
            </template>
            
        </div>

        <ConfirmChangeDialog 
            v-if="commercialSelected"
            :show="commercialSelected"
            @close-dialog="closeConfirmDialog"
            @confirm-change="confirmChange"
        />

        <AppConvertDialog 
            v-if="dialog"
            @closed-action="closeAction"
            :conversion="selectedConversion"
            :loanType="selectedLoanType"            
            :dialog="dialog" />
        
    </v-card>
</template>

<script>

    import { mapGetters, mapState } from 'vuex';
    import subscriptions from '~/components/shared/subscriptions'
    import AppConvertDialog from '~/components/base/general/AppConvertDialog'
    import ConfirmChangeDialog from '~/components/base/general/ConfirmChangeDialog'
    import menuItems from '~/components/base/general/badge-menu-items'
    import ButtonGroup1 from '~/components/base/ButtonGroup1'

    export default {
        mixins: [
          subscriptions, 
        ],        
        components: {
            AppConvertDialog,
            ButtonGroup1,
            ConfirmChangeDialog
        },
        computed: {
            ...mapState('auth-actions', [
                'userSettings'
            ]),
            ...mapState('flows', [
                'loanData'
            ]),
            ...mapState({
                stepableMode: state => state.subscriptions.stepableMode,
            }),
            ...mapGetters('flows', ['getAppTypeFE']),
            ...mapGetters({
                getLeftDrawerVariant: 'setup/getLeftDrawerVariant' ,
            }),
            isDisabled(){
                return this.stepableMode == 'disabled'
            },
            showConvertAppButton(){
                return this.blockSettings?.showConvertAppButton == 1
            },
            menuBadgeItemsCommerical() {
                return menuItems.filter(item => item.title == 'Commercial')[0];
            },
            menuBadgeItemsConsumer() {
                return menuItems.filter(item => item.title == 'Consumer')[0];
            },
            currentLoanType() {
                return this.loanData.attributes['loan-type'] == 'Asset Finance' ? 'Secured' : 'Unsecured';
            }
        },

        methods: {
            changeLoanTypeClick(param) {
                this.loanType = param;
                if(this.chosenConsumer != undefined) {
                    this.selectedConversion = this.menuBadgeItemsConsumer['children'][this.chosenConsumer].title;
                    this.chosenCommercial = undefined;
                }

                if(this.chosenCommercial != undefined) {
                    this.selectedConversion = this.menuBadgeItemsCommerical['children'][this.chosenCommercial].title;
                    this.chosenConsumer = undefined;
                }
            },
            changeLoanType(param) {
                this.loanType = param;
            },
            confirmChange() {
                this.commercialSelected = false;
                this.selectedConversion = this.selectedCommercialItem.title;
            },
            closeConfirmDialog() {
                this.commercialSelected = false
                this.setItems()
            },
            selectCommercial(item) {
                this.commercialSelected = true;
                this.selectItem('commercial', item);
                this.selectedCommercialItem = item;
            },
            setItems() {
                this.changeLoanType(this.currentLoanType);
                this.selectedLoanType = this.loanType;
                this.selectedConversion = ''
                this.chosenConsumer = '';
                this.chosenCommercial = '';
                let x;
                let y;
                this.menuBadgeItemsConsumer.children.forEach((item, index) => {
                    if(item.title == this.getAppTypeFE.type) {
                        x = index
                    }

                    return x;
                });

                this.menuBadgeItemsCommerical.children.forEach((item, index) => {
                    if(item.title == this.getAppTypeFE.type) {
                        y = index
                    }

                    return y;
                });

                this.chosenConsumer = x;
                this.chosenCommercial = y;
            },

            closeAction() {
                this.dialog = false;
                this.selectedConversion = ''
                // this.setItems()
            },
            selectItem(type, item) {
                this.selectedConversion = ''
                if(type == 'commercial') {
                    this.chosenConsumer = undefined
                    this.selectedConversion = item.title
                }

                if(type == 'consumer') {
                    this.chosenCommercial = undefined
                    this.selectedConversion = item.title
                }
            },

            createNew() {
                console.log('Should create new application from the current source...')
            },

            convertApp(type = '') {
                console.log(`Should convert the current application to ${type}`)

                if(this.selectedConversion == this.getAppTypeFE.type && this.currentLoanType == this.loanType) {
                    return;
                }

                this.selectedConversion = type
                this.dialog = true
                this.menu = false
            },

            toggleMenu(obj) {
                this.menu = !this.menu
                this.setItems();
            },
        },

        data() {
            return {
                menu: false,
                dialog: false,
                commercialSelected: false,
                selectedConversion: '',
                selectedCommercialItem: '',
                loanType: 'Unsecured',
                selectedLoanType: '',
                chosenConsumer: '',
                chosenCommercial: '',
                loanTypeOptions: [
                    'Secured',
                    'Unsecured'
                ]
            }
        },
        watch: {
            loanType(newVal, oldVal) {
                if(newVal == 'Secured') {
                    this.selectedLoanType = 'Asset Finance'
                } else {
                    this.selectedLoanType = 'Unsecured'
                }
            }
        }
    }
</script>

<style lang="scss">
.application-menu-type .v-list-item--active .v-list-item__title .item-title {
  color: #fff !important;
}
</style>