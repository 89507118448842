<template>
    <v-dialog
        persistent
        scrollable
        height="600"
        :max-width="isBidUser && !isCommercial ? modalSize : 400"
        v-model="dialog">

        <v-card flat tile :disabled="saving" class="pa-4">

            <v-progress-linear color="secondary" indeterminate stream absolute top v-if="saving"></v-progress-linear>

            <v-card-title class="d-flex py-0 my-0">
                <div class="v-card__title pa-0 sm">
                    {{title}}
                </div>
                <v-spacer />
                <div>
                    <v-img 
                        v-if="selectedLender.details"
                        max-width="100"
                        height="100"
                        :src="getLenderLogo(selectedLender.details['logo-class'])" 
                        position="center left" 
                        contain>
                    </v-img>
                </div>
            </v-card-title>

            <v-card-text v-if="activeStep == 2" class="body-1">
                {{ subTitle }}
            </v-card-text>

            <v-card-text v-if="isBidUser && !isCommercial">
                <v-stepper v-model="activeStep" alt-labels>
                    <v-stepper-items>
                        <v-stepper-content class="pa-0" step="1">
                            <ValidationObserver 
                                ref="observer" 
                                tag="form"
                            >   
                                <v-row>
                                    <v-col :cols="vb.smAndUp ? isUnsecured ? 6 : 4 : 12" 
                                        v-for="(field, key) in resources['bid-lenders'].attributes"
                                        :key="key"
                                        class="py-0"
                                    >
                                        <ValidationProvider
                                            v-if="isVisible(key)"
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getRules(key)"
                                            :vid="key">

                                            <v-select
                                                v-if="isFieldType('picklist', key)"
                                                v-model="resources['bid-lenders'].attributes[key]"
                                                :items="options[key]"
                                                item-text="key"
                                                item-value="value"
                                                :error-messages="errors"
                                                color="secondary"
                                                :suffix="suffix[key]"
                                                :label="labels[key]"
                                            ></v-select>

                                            <template v-else>
                                                <v-text-field 
                                                    v-if="key == 'base-rate' || key == 'customer-rate'"
                                                    v-model="resources['bid-lenders'].attributes[key]"
                                                    :label="labels[key]"
                                                    :error-messages="errors"
                                                    suffix="%"
                                                    flat>
                                                </v-text-field>
                                                <NodifiMoney
                                                    v-else
                                                    v-model="resources['bid-lenders'].attributes[key]"
                                                    :label="labels[key]"
                                                    :errors="errors" /> 
                                            </template>                                    

                                        </ValidationProvider>
                                        
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-stepper-content>
                        <v-stepper-content class="pa-0" step="2">
                            
                            <ValidationObserver 
                                ref="observer2" 
                                tag="form"
                            >   
                                <v-row>
                                    <v-col cols="12" 
                                        v-for="(field, key) in resources['bid'].attributes"
                                        :key="key"
                                        class="py-0"
                                    >

                                        <ValidationProvider
                                            v-if="isVisible(key)"
                                            v-slot="{ errors }"
                                            :name="labels[key]"
                                            :rules="getRules(key)"
                                            :vid="key">

                                            <v-textarea
                                                v-model="resources['bid'].attributes[key]"
                                                rows="3"
                                                auto-grow
                                                :error-messages="errors"
                                                :label="labels[key]"></v-textarea>                                 

                                        </ValidationProvider>
                                        
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
                
            </v-card-text>

            <v-card-actions class="pa-4 ma-2">

                <template v-if="isBidUser && !isCommercial">
                    <v-btn v-if="activeStep == 1" @click="closeModal" :min-width="140" large depressed outlined color="secondary">
                        Back
                    </v-btn>
                    <v-btn v-else @click="activeStep--, modalSize = 1100" :min-width="140" large depressed outlined color="secondary">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="activeStep == 1" @click="selectNextStep" :min-width="140" large depressed class="secondary">
                        Next
                    </v-btn>
                    <v-btn v-else @click="saveLender" :min-width="140" large depressed class="secondary">
                        Next
                    </v-btn>
                </template>

                <template v-else>
                    <v-btn @click="closeModal" :min-width="140" large depressed outlined color="secondary">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="selectLender" :min-width="140" large depressed class="secondary">
                        Next
                    </v-btn>
                </template>
                
                
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import subscriptions from '~/components/shared/subscriptions'
import helpers from '~/plugins/helpers'
import { store, mutations } from './__store'
import { isEmpty } from 'lodash'
import shared from './__shared'

export default {
    name: 'QuoteToolLenderModal',
    mixins: [ subscriptions, helpers, shared ],
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        selectedLender: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: 'Lender'
        },
        subTitle: {
            type: String,
            default: 'Please provide the rationale for why this is the alternative recommendation'
        },
        requiredLoanAmount: {
            type: [String, Number],
            default: ""
        },
        assisters: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            saving: false,
            activeStep: 1,
            modalSize: 1100,
            resources: {
                "loans": {
                    "attributes": {
                        "quoted-balloon": "",
                        "quoted-bank-fees": "",
                        "quoted-base-rate": "",
                        "quoted-broker-commission-inc-gst": "",
                        "quoted-broker-fee": "",
                        "quoted-customer-rate": "",
                        "quoted-monthly-payments": "",
                        "quoted-naf": "",
                        "quoted-account-fee": "",
                        "quoted-term-months": "",
                        "selected-lender-quote-algorithm-slug": "",
                    }
                },
                "bid-lenders": {
                    type: "bid-lenders",
                    attributes: {
                        "loan-term": '',
                        "payment-terms": '',
                        "balloon-amount": '',
                        "establishment-fee": '',
                        "ppsr-fee": '',
                        "origination-fee": '',
                        "base-rate": '',
                        "customer-rate": '',
                        "total-loan-amount": '',
                        "brokerage-commission": '',
                        "total-commission": '',
                        "monthly-account-fee": '',
                        "monthly-payment": '',
                        "lender-name": '',
                        "lender-type": '',
                        'required-loan-amount': '',
                        "risk-fee": '',
                    },
                    relationships: {
                        "bid": {
                            "data": {
                                "type": "bids",
                                "id": null
                            }
                        }
                    }
                },
                'bid': {
                    id: null,
                    type: 'bids',
                    attributes: {
                        'recommendation-cost': '',
                        'recommendation-priorities': '',
                        'recommendation-needs-objectives': '',
                        'recommendation-circumstances': '',
                        'recommendation-other': '',
                        'alternative-summary': ''
                    },
                    relationships: {
                        application: {
                            data: {
                                type: 'applications',
                                id: null
                            }
                        }
                    }
                },

            },
            labels: {
                "loan-term": 'Loan term',
                "payment-terms": 'Payment Terms',
                "balloon-amount": 'Balloon Payment',
                "establishment-fee": 'Establishment Fee',
                "risk-fee": 'Risk Fee',
                "ppsr-fee": 'PPSR Fee',
                "origination-fee": 'Origination Fee',
                "base-rate": 'Base Rate',
                "customer-rate": 'Customer Rate',
                "total-loan-amount": 'Amount Financed',
                "brokerage-commission": 'Brokerage Commission',
                "total-commission": 'Total Commission',
                "monthly-account-fee": 'Monthly Fee',
                "monthly-payment": 'Monthly Payment',
                "recommendation-cost": "Cost",
                "recommendation-priorities": "Customer Stated Priorities",
                "recommendation-needs-objectives": "Customer stated needs and objectives",
                "recommendation-circumstances": "Customer personal circumstances",
                "recommendation-other": "Other rationale or comments",
                "alternative-summary": "Alternative Summary",
            },
            validation_rules: {
                attributes: {
                    "balloon-amount": 'required',
                    "establishment-fee": 'required',
                    "risk-fee": 'required',
                    "ppsr-fee": 'required',
                    "origination-fee": 'required',
                    "base-rate": 'required',
                    "customer-rate": 'required',
                    "total-loan-amount": 'required',
                    "brokerage-commission": 'required',
                    "total-commission": 'required',
                    "monthly-account-fee": 'required',
                    "monthly-payment": 'required',
                    "loan-term": "required|in:12,24,36,48,60,72,84",
                    "payment-terms": "required|in:In Arrears,In Advance",
                    "recommendation-cost": "required",
                    "recommendation-priorities": "required",
                    "recommendation-needs-objectives": "required",
                    "recommendation-circumstances": "required",
                    "recommendation-other": "required",
                    "alternative-summary": "required",
                    "lender-name": "",
                    "lender-type": ""
                }
            },
            options: {
                "loan-term": [
                    { key: "12", value: "12" },
                    { key: "24", value: "24" },
                    { key: "36", value: "36" },
                    { key: "48", value: "48" },
                    { key: "60", value: "60" },
                    { key: "72", value: "72" },
                    { key: "84", value: "84" }
                ],
                "payment-terms": [ "In Arrears", "In Advance" ]
            },
            visible: {
                "lender-name": false,
                "risk-fee": false,
                "lender-type": false,
                "required-loan-amount": false
            },
            suffix: {
                "loan-term": 'months',
                "payment-terms": ''
            },
        }
    },
    computed: {
         ...mapState('auth-actions', [
            'appOwnerSettings'
        ]),
        ...mapState('flows', [
            'appTypeFE'
        ]),
        selectedLenderObj() {
            return store.selectedLenderObj
        },
        isBidUser() {
            return this.appOwnerSettings.bid ? true : false
            // return true
        },
        isUnsecured() {
            return store.isUnsecured
        },
        vb() {
            return this.$vuetify.breakpoint
        },
        hasPrimary() {
            return isEmpty(this.selectedLenderObj.primary['bid-lender']?.id) ? false : true
        },

        hasSecondary() {
            return isEmpty(this.selectedLenderObj.secondary['bid-lender']?.id) ? false : true
        },

        isCommercial() {
            return this.appTypeFE.type.indexOf('Commercial') >= 0
        },
        getLoanId(){
            return this.appData?.relationships?.loan?.data?.id
        },
        getLendersSlugAlgorithms(){
            return store.lenderSlugAlgorithms
        },
    },
    async beforeMount() {
        if(store.isUnsecured) {
            this.resources['bid-lenders'].attributes = {
                "loan-term": '',
                "customer-rate": '',
                "establishment-fee": '',
                "risk-fee": '',
                "origination-fee": '',
                "brokerage-commission": '',
                "total-loan-amount": '',
                "total-commission": '',
                "monthly-account-fee": '',
                "monthly-payment": '',
                "lender-name": '',
                "lender-type": ''
            }
        }
    },
    watch: {
        selectedLender(val) {

            /**
             * Both BID or Non-BID will get quoted-* fields populated.
             */

            this.resources.loans.attributes['quoted-payment-terms'] = val['payment-terms']
            this.resources.loans.attributes['quoted-balloon'] = val.details['actual-payment']['balloon']
            this.resources.loans.attributes['quoted-bank-fees'] = val.details['actual-payment']['bank-fees']
            this.resources.loans.attributes['quoted-base-rate'] = val.details['actual-payment']['base-rate']
            this.resources.loans.attributes['quoted-broker-commission-inc-gst'] = val.details['actual-payment']['commission-inc-gst']
            this.resources.loans.attributes['quoted-broker-fee'] = val.details['actual-payment']['broker-fee']
            this.resources.loans.attributes['quoted-customer-rate'] = val.details['actual-payment']['customer-rate']
            this.resources.loans.attributes['quoted-monthly-payments'] = val.details['actual-payment']['monthly-payment']
            this.resources.loans.attributes['quoted-naf'] = val.details['actual-payment']['naf']
            this.resources.loans.attributes['quoted-account-fee'] = val.details['actual-payment']['account-fee']
            this.resources.loans.attributes['quoted-term-months'] = val.details['actual-payment']['term-in-months']

            if(this.isBidUser) {

                let totalCommision = (parseFloat(val.details['actual-payment']['commission-inc-gst']) + parseFloat(val.details['actual-payment']['broker-fee'])).toFixed(2)
                let brokerageCommission = val.details['actual-payment']['commission-inc-gst'].toString()

                if(store.isUnsecured) {
                    this.resources['bid-lenders'].attributes['loan-term'] = val.details['actual-payment']['term-in-months'].toString()
                    this.resources['bid-lenders'].attributes['establishment-fee'] = val.details['actual-payment']['bank-fees']
                    this.resources['bid-lenders'].attributes['risk-fee'] = 0
                    this.resources['bid-lenders'].attributes['origination-fee'] = val.details['actual-payment']['broker-fee']
                    this.resources['bid-lenders'].attributes['customer-rate'] = val.details['actual-payment']['customer-rate']
                    this.resources['bid-lenders'].attributes['total-loan-amount'] = val.details['actual-payment']['naf']
                    this.resources['bid-lenders'].attributes['brokerage-commission'] = brokerageCommission
                    this.resources['bid-lenders'].attributes['total-commission'] = totalCommision
                    this.resources['bid-lenders'].attributes['monthly-account-fee'] = val.details['variables']['monthly-account-fee']
                    this.resources['bid-lenders'].attributes['monthly-payment'] = val.details['actual-payment']['monthly-payment']
                    this.resources['bid-lenders'].attributes['lender-name'] = val.details['name']
                    this.resources['bid-lenders'].attributes['required-loan-amount'] = this.requiredLoanAmount
                } else {
                    this.resources['bid-lenders'].attributes['loan-term'] = val.details['actual-payment']['term-in-months'].toString()
                    this.resources['bid-lenders'].attributes['payment-terms'] = val['payment-terms']
                    this.resources['bid-lenders'].attributes['balloon-amount'] = val.details['actual-payment']['balloon']
                    this.resources['bid-lenders'].attributes['establishment-fee'] = val.details['actual-payment']['bank-fees']
                    this.resources['bid-lenders'].attributes['risk-fee'] = 0
                    this.resources['bid-lenders'].attributes['ppsr-fee'] = val.details['variables']['ppsr']
                    this.resources['bid-lenders'].attributes['origination-fee'] = val.details['actual-payment']['broker-fee']
                    this.resources['bid-lenders'].attributes['base-rate'] = val.details['actual-payment']['base-rate']
                    this.resources['bid-lenders'].attributes['customer-rate'] = val.details['actual-payment']['customer-rate']
                    this.resources['bid-lenders'].attributes['total-loan-amount'] = val.details['actual-payment']['naf']
                    this.resources['bid-lenders'].attributes['brokerage-commission'] = brokerageCommission
                    this.resources['bid-lenders'].attributes['total-commission'] = totalCommision
                    this.resources['bid-lenders'].attributes['monthly-account-fee'] = val.details['variables']['monthly-account-fee']
                    this.resources['bid-lenders'].attributes['monthly-payment'] = val.details['actual-payment']['monthly-payment']
                    this.resources['bid-lenders'].attributes['lender-name'] = val.details['name']
                    this.resources['bid-lenders'].attributes['required-loan-amount'] = this.requiredLoanAmount
                }

                if(store.bidData) {
                    Object.keys(this.resources.bid.attributes).forEach(attr => {
                        Object.keys(store.bidData.attributes).forEach(bid => {
                            if(attr == bid) this.resources.bid.attributes[attr] = store.bidData.attributes[bid]
                        })
                    })
                }
                
            }
            
        }
    },
    methods: {
        ...mapActions('resource-actions', [
            'deleteBidLender',
            'createBidLender',
            'updateBidLender',
            'deleteBid',
            'createBid',
            'updateBid',
            'updateApplication',
            'createAssisterSubmissions',
            'updateLoan'
        ]),
        async saveLender() {

            const valid = await this.$refs.observer2.validate()

            if(valid) {
                let bidResponse = null
                this.saving = true
                if(store?.bidData?.id) {
                    bidResponse = await this.updateBid(this.resourceObjHelper('update', store.bidData, this.resources.bid))
                } else {
                    this.resources.bid.relationships.application.data.id = this.appId
                    delete this.resources.bid.id
                    bidResponse = await this.createBid(this.resourceObjHelper('create', store.bidData, this.resources.bid))
                }

                if(bidResponse.status === 200 || bidResponse.status === 201) {
                    let bid = bidResponse.data.data
                    this.resources['bid-lenders'].relationships.bid.data.id = bidResponse.data.data.id

                    let params = this.resourceObjHelper('create', {}, this.resources['bid-lenders'])

                    if(this.title.includes('Primary')) {
                        params.attributes['lender-type'] = 'Primary'
                    } else {
                        params.attributes['lender-type'] = 'Alternative'
                    }

                    delete params.id

                    let bidLenderPayload = await this.createBidLender( params )

                    if ( bidLenderPayload.status === 201 ) {

                        if(this.title.includes('Primary')) {

                            mutations.setPrimary(
                                {
                                    'bid': bid,
                                    'bid-lender': bidLenderPayload.data.data
                                }
                            )
                            
                            mutations.setSelectedPrimaryLender(this.selectedLender.details['logo-class'])


                            let isLenderKnown = !!this.getLendersSlugAlgorithms.find((l) => l?.["lender-slug-algorithm"].includes(this.selectedLender.name))

                            if(isLenderKnown){
                                this.resources.loans.attributes["selected-lender-quote-algorithm-slug"] = this.selectedLender.name
                            }

                            // update loans
                            let payload = {
                                id: this.getLoanId,
                                type: 'loans',
                                attributes: {
                                    ...this.resources.loans.attributes
                                },
                                relationships: {
                                    application: {
                                        data: { type: "applications", id: this.appId }
                                    }
                                }
                            }

                            // console.log('isLenderKnown', isLenderKnown)
                            // console.log('payload', payload)

                            const res = await this.updateLoan(payload)

                            if(res.status === 200) {
                                this.$emit('save-primary-lender')
                            }

                        } else {
                            mutations.setSecondary(
                                {
                                    'bid': bid,
                                    'bid-lender': bidLenderPayload.data.data
                                }
                            )
                        }

                        mutations.setBidData(bid)
                        this.activeStep = 1
                        this.modalSize = 1100

                    }
                }

                this.saving = false
                this.closeModal()
            }

        },
        async assistersSubmissions() {
            
            let assisterSubmissions = {
                type: "assister-submissions",
                attributes: {},
                relationships: {
                    "application": {
                        "data": {
                            "type": "applications", 
                            "id": this.appId
                        }
                    },
                    "assister": {
                        "data": {
                            "type": "assisters", 
                            "id": this.assisters.length > 0 ? this.assisters[0].id : "1"  // default to first assister for the meantime
                        }
                    }
                }
            }

            await this.createAssisterSubmissions(assisterSubmissions)

        },
        async selectNextStep() {

            const valid = await this.$refs.observer.validate()

            if(valid) {
                if(this.hasPrimary) {
                    this.visible['recommendation-cost'] = false
                    this.visible['recommendation-priorities'] = false
                    this.visible['recommendation-needs-objectives'] = false
                    this.visible['recommendation-circumstances'] = false
                    this.visible['recommendation-other'] = false
                    this.visible['alternative-summary'] = true
                } else {
                    this.visible['recommendation-cost'] = true
                    this.visible['recommendation-priorities'] = true
                    this.visible['recommendation-needs-objectives'] = true
                    this.visible['recommendation-circumstances'] = true
                    this.visible['recommendation-other'] = true
                    this.visible['alternative-summary'] = false
                }
                this.activeStep = 2
                this.modalSize = 1100
            }
            
        },
        closeModal() {
            this.$emit('close-modal')
        },
        selectLender() {
            this.resources.loans.attributes['selected-lender-quote-algorithm-slug'] = this.selectedLender.name
            this.$emit('select-lender', {loans: this.resources.loans, lender: this.selectedLender.name})
        },
        isVisible(key){
                
            const {visible} = this

            if(visible.hasOwnProperty(key)){
                return visible[key]
            }

            return true

        },
        getLenderLogo(name){

            let lender = require(`~/assets/images/logo/placeholder.png`)

            if(!name)
                return lender

            try {

                lender = require(`~/assets/images/logo/${name}.png`)
                return lender

            } catch (error) {

                console.log('Ooops!..', error)
                return lender

            }

        },
    }
}
</script>
