<template>
    <v-dialog
        v-model="dialog"
        width="700"
        scrollable
        persistent>
   

        <v-card flat class="ol-residential-history-address-form" :disabled="isSaving">
            <v-progress-linear color="primary" indeterminate stream absolute top v-if="isSaving"></v-progress-linear>
            <v-card-title class="pa-0 ma-0 pr-5 pt-3 mb-n5">
                <v-spacer></v-spacer>
                <v-btn icon rounded small @click="close" depressed>
                    <v-icon color="primary" size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="px-8 py-6 text-center">
                <div class="sm pa-0 text-center" style="width:100%">
                   {{title}}
                </div>
            </v-card-title>
            <v-card-text class="mt-n6" :class="vb.mdAndUp ? 'px-16 ' : 'px-4'" >
                <ValidationObserver ref="observer1" tag="form">
                    <!-- <ValidationProvider>
                        <v-select v-model="selected" placeholder="Select existing Adress" :items="addressItems"  @change="selectedAddress"></v-select>
                    </ValidationProvider> -->
                    <ValidationProvider v-slot="{ errors }" name="Reference Address" rules="required">
                        <google-address-tool
                            name="address"
                            :errors="errors"
                            v-model="fullAddress" 
                            @change="handleAddressToolChange"
                        ></google-address-tool>
                    </ValidationProvider>
                    <v-row v-if="false">
                        <v-col :lg="field.size" :md="field.size" :sm="field.size" v-for="(field, key) in fields" :key="key">
                            <ValidationProvider v-slot="{ errors }"  :name="field.name"  rules="required">
                                <v-text-field :error-messages="errors" :label="field.name" v-model="resources.refAddress.attributes[field.model]" >
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    </ValidationObserver>
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-if="actionType == 'edit'">
                <v-btn class="px-10 mx-4 secondary" :loading="isSaving" width="140" height="50" depressed large @click="update">update</v-btn>
            </v-card-text>
            <v-card-text class="d-flex justify-center pb-8" v-else>
                <v-btn class="px-10 " width="140" height="50" depressed large @click="back">Back</v-btn>
                <v-btn class="px-10 mx-4 secondary" width="140" height="50" depressed large @click="nextStep" :loading="isSaving">Next</v-btn>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>
<script>
import {store,mutations} from './__store'
import { mapState, mapActions, mapGetters } from 'vuex'
import resourceHelpers from '~/components/shared/resource-helpers'
import subscriptions from '~/components/shared/subscriptions'
import GoogleAddressTool from '~/components/base/GoogleAddressTool'
import formHelpers from '~/components/shared/form-helpers.js'
import validation from '~/components/shared/validation'
import shared from './__shared'
export default {
    components: {
       GoogleAddressTool
    },
    mixins: [subscriptions,resourceHelpers,formHelpers,validation,shared],
    props : {
        title: {
            type: String,
            default: 'Title'
        },
        step: {
            type: String,
            default: ''
        },
        addresses: {
            type:Array,
            default: () => [] 
        },
        address:{
            Object,
            default: () => {}
        },
        actionType: {
            type:String,
            default: ''
        },
        occupancySituation : {
            type: String,
            default: 'Previous'
        }
    },
    computed: {
        ...mapState({
            appId: state => state.flows.appId,
        }),
        ...mapState('flows',['calculation'])
    },
    watch: {
        "step": {
            handler (val) {
                val == 'reference-expense' ? this.dialog = true : this.dialog = false
            }
        },
        "addresses": {
            handler (val) {

                this.getCompleteAddress(val)
            }
        },
       
        'actionType': {
            handler (val) {
                return val
            }
        }
    },
    methods: {
        ...mapActions('resource-actions',['createAddress','createOccupancy','createAsset','createExpense','createPart','createReference']),
        async nextStep(data) {
           let valid = await this.$refs.observer1.validate();
           if(valid){
               this.isSaving = true
                if(store.isLivedTogether == 'Yes'){
                    await this.ifLiveTogetherSameperiodPostMethod()
                    
                } else {
                    await this.ifNotLiveTogetherPostMethod()
                }
                this.isSaving = false
                this.$emit('step',null)
           }  
        },
        back() {
            this.$emit('back','landlord-reference-details')
        },
        close(){
           this.$emit('back',null)
        },
        update () {},
        async ifLiveTogetherSameperiodPostMethod () {
            if(this.occupancyResource1.hasOwnProperty('id')) {
                delete  this.occupancyResource1.id 
                delete  this.occupancyResource2.id 
            }
            this.occupancyResource1.attributes['end-date'] = null
            this.occupancyResource2.attributes['end-date'] = null
            let occupancy1 = await this.createOccupancy(this.occupancyResource1)
            let occupancy2 = await this.createOccupancy(this.occupancyResource2)
            let addressResource = store.addressResource
            if(addressResource.hasOwnProperty('id')){
                delete addressResource.id
            }
            addressResource.relationships.occupancies = {data: [{id:occupancy1.data.data.id, type:'occupancies'},{id:occupancy2.data.data.id, type:'occupancies'}]}
            let address = await this.createAddress(addressResource)
            let addressRelationships = {"address": {data: {id: address.data.data.id, type: 'addresses'}}}
            mutations.setExpenseRelationships(addressRelationships)
            let expenseAttr = this.getExpensesResource2( {data: [{id: this.entities[0].id,type: 'people'},{id: this.entities[1].id,type: 'people'}]})
            let expense = await this.createExpense(expenseAttr)
            let parts = await this.createPart(this.getPartsResource())
            let referenceRelationships = {
                'parent': {data: [{id: parts.data.data.id, type: 'parts'}]},
                'kinds': {data: {id: '6-4', type: 'kinds'}}
                }
            mutations.setReferenceRelationships(referenceRelationships)
            let reference = await this.createReference(this.getReferenceResource())
            let referenceAddressRelationships = {'references': { data: [{id: reference.data.data.id, type: 'references'}]}}
            mutations.setReferenceAddressRelationships(referenceAddressRelationships)
            await this.createAddress(this.getReferenceAddressResource())
            let addressWithOccupancy = {address: address.data.data, occupancy: occupancy1.data.data}
            this.$emit('inputAddress',addressWithOccupancy)
        },
        async ifNotLiveTogetherPostMethod () {
            if(store.selectedOwner?.id && store.selectedOwner.id.length > 1) {
                if(this.occupancyResource1.hasOwnProperty('id')) {
                    delete  this.occupancyResource1.id 
                    delete  this.occupancyResource2.id 
                }
                this.occupancyResource1.attributes['end-date'] = null
                this.occupancyResource2.attributes['end-date'] = null
                let occResource = this.calculation['primary-applicant-id'] == this.entityId  ?  this.occupancyResource1 : this.occupancyResource2
                occResource.person = {data: {id:this.entityId, type: 'people'}}
                let occupancy = await this.createOccupancy(occResource)
                let addressResource = store.addressResource
                if(addressResource.hasOwnProperty('id')){
                    delete addressResource.id
                }
                addressResource.relationships.occupancies = {data: [{id:occupancy.data.data.id, type:'occupancies'}]}
                let address = await this.createAddress(addressResource)
                let addressRelationships = {"address": {data: {id: address.data.data.id, type: 'addresses'}}}
                mutations.setExpenseRelationships(addressRelationships)
                let expenseAttr = this.getExpensesResource2( {data: [{id: this.entityId,type: 'people'}]})
                let expense = await this.createExpense(expenseAttr)
                let parts = await this.createPart(this.getPartsResource())
                let referenceRelationships = {
                    'parent': {data: [{id: parts.data.data.id, type: 'parts'}]},
                    'kinds': {data: {id: '6-4', type: 'kinds'}}
                    }
                mutations.setReferenceRelationships(referenceRelationships)
                let reference = await this.createReference(this.getReferenceResource())
                let referenceAddressRelationships = {'references': { data: [{id: reference.data.data.id, type: 'references'}]}}
                mutations.setReferenceAddressRelationships(referenceAddressRelationships)
                await this.createAddress(this.getReferenceAddressResource())
                let addressWithOccupancy = {address: address.data.data, occupancy: occupancy.data.data}
                this.$emit('inputAddress',addressWithOccupancy)
                this.$emit('step',null)
            } else {
                if(this.occupancyResource1.hasOwnProperty('id')) {
                    delete  this.occupancyResource1.id 
                }
                this.occupancyResource1.attributes['end-date'] = null
                let occResource = this.occupancyResource1

                occResource.person = {data: {id:this.entityId, type: 'people'}}
                let occupancy = await this.createOccupancy(occResource)
                let addressResource = store.addressResource
                if(addressResource.hasOwnProperty('id')){
                    delete addressResource.id
                }
                addressResource.relationships.occupancies = {data: [{id:occupancy.data.data.id, type:'occupancies'}]}
                let address = await this.createAddress(addressResource)
                let addressRelationships = {"address": {data: {id: address.data.data.id, type: 'addresses'}}}
                mutations.setExpenseRelationships(addressRelationships)
                let expenseAttr = this.getExpensesResource2( {data: [{id: this.entityId,type: 'people'}]})
                let expense = await this.createExpense(expenseAttr)
                let parts = await this.createPart(this.getPartsResource())
                let referenceRelationships = {
                    'parent': {data: [{id: parts.data.data.id, type: 'parts'}]},
                    'kinds': {data: {id: '6-4', type: 'kinds'}}
                    }
                mutations.setReferenceRelationships(referenceRelationships)
                let reference = await this.createReference(this.getReferenceResource())
                let referenceAddressRelationships = {'references': { data: [{id: reference.data.data.id, type: 'references'}]}}
                mutations.setReferenceAddressRelationships(referenceAddressRelationships)
                await this.createAddress(this.getReferenceAddressResource())
                let addressWithOccupancy = {address: address.data.data, occupancy: occupancy.data.data}
                this.$emit('inputAddress',addressWithOccupancy)
                this.$emit('step',null)
            }
            
        },
        async selectedAddress () {
            let data = await this.getAddress(this.selected)
            let obj = data.data.data.attributes
            this.resources.refAddress.attributes['street-number'] = obj['street-number']
            this.resources.refAddress.attributes['street-name'] =  obj['street-number']
            this.resources.refAddress.attributes['suburb'] =  obj['street-number']
            this.resources.refAddress.attributes['state'] =  obj['street-number']
            this.resources.refAddress.attributes['postcode'] =  obj['street-number']
            let payload = {
                'street-number':  obj['street-number'],
                'street-name':  obj['street-name'],
                'suburb':  obj['suburb'],
                'state':  obj['state'],
                'postcode': obj['postcode'],
            }
            mutations.setReferenceAddressAttributes(payload)
        },
        getCompleteAddress (items) {
            if(items.length > 0) {
                items.forEach(data => {
                    let item  = this.getFullAddress(data.address)
                    
                    this.addressItems.push({value: data.address.id, text:item})
                })
               
            }
        },
        handleAddressToolChange(address, obj){
            this.resources.refAddress.attributes['street-number'] = obj['street-number']
            this.resources.refAddress.attributes['street-name'] = obj['street-name']
            this.resources.refAddress.attributes['suburb'] = obj['suburb']
            this.resources.refAddress.attributes['state'] = obj['state']
            this.resources.refAddress.attributes['postcode'] = obj['postcode']
            let payload = {
                'street-number':  obj['street-number'],
                'street-name':  obj['street-name'],
                'suburb':  obj['suburb'],
                'state':  obj['state'],
                'postcode': obj['postcode'],
            }
            mutations.setReferenceAddressAttributes(payload)
        },
    },
    data() {
        return {
            dialog:false,
            form: {

            },
            isSaving: false,
           selected: '',
            fullAddress: '',
         
            fields: [
                {name: 'Street Number', model: 'street-number',size: 4,require: ''},
                {name: 'Street', model: 'street-name',size: 8,require: ''},
                {name: 'Suburb', model: 'suburb',size: 4,require: ''},
                {name: 'state', model: 'state',size: 4,require: ''},
                {name: 'postcode', model: 'postcode',size: 4,require: 'required'},
            ],
            addressItems: [{value:"",text:""}],
            resources: {
                    people: {
                        type: 'people',
                        attributes: {}
                    },
                    business: {
                        type: 'businesses',
                        attributes: {}
                    },
                    refAddress: {
                        type: 'addresses',
                        attributes: {
                            "street-number": null,
                            "street-name": null,
                            "street-type": null,
                            "suburb": null,
                            "state": null,
                            "postcode": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id: null } 
                            }
                        }
                    },
                    occupancy: {
                        type: 'occupancies',
                        attributes: {
                            "occupancy-situation": null,
                            "residential-situation": null,
                            "start-date": null,
                            "end-date": null
                        },
                        relationships: {
                            application: {
                                data: { type: "applications", id:  null} 
                            }
                        }
                    },
            },
        }
    }
}
</script>
<style lang="scss" >
    .ol-residential-history-address-form {
        .close-icon {
            position: absolute;
            right: 40px;
            top: 30px;
            // background: var(--v-secondary-lighten2);
            color: var(--v-secondary-base)!important;
            padding: 6px;
            border-radius: 36px !important;
            i {
                color: var(--v-secondary-base) !important;
            }
        }
    }
</style>