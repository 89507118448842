<template>
    <div>
        <v-card :disabled="isDisabled" class="mx-auto" tile flat>
            <v-card-text class="pa-4 pt-0 pa-md-10 pt-md-0">
                <v-container pa-0 fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :loading="false"
                                loading-text="Please wait..."
                                :items="entities"
                                hide-default-footer
                                :mobile-breakpoint="0"
                                hide-default-header
                                class="v-data-table-6 mx-auto">

                                <!-- Body -->
                                <template v-if="vb.mdAndUp"  v-slot:item="{ item, index }">
                                    <v-card tile flat class="mb-6 pa-2 rounded-xl" color="light_gray_1">

                                        <div class="d-flex justify-space-between align-center py-2 px-4 flex-table-row">

                                        <div class="d-flex flex-column">
                                                <div class="mr-6">
                                                    <v-icon size="32">{{item.type === 'people' ? 'mdi-account' : 'mdi-city' }}</v-icon>
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column">
                                                <div class="mr-6 body-1 font-weight-medium">
                                                    {{ getFriendlyName(item) }}
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column">
                                                <div>

                                                    <v-chip :filter="false" :ripple="false"
                                                        :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`"
                                                        class="px-5 font-weight-medium">
                                                        {{ item.roles.length > 1 ? `${item.roles[0]} +${item.roles.length - 1}`: item.roles.length == 1 ? item.roles[0] : '' }}
                                                    </v-chip>

                                                </div>
                                            </div>

                                            <v-spacer></v-spacer>

                                            <div class="d-flex justify-end">
                                                <v-btn
                                                    depressed
                                                    small
                                                    @click="handleAddDetails(item)"
                                                    :color="getCompleted.includes(item.id) ? 'success' : 'secondary'">
                                                    {{ getCompleted.includes(item.id) ? 'Completed' : 'Add Details' }}
                                                </v-btn>
                                            </div>
                                        </div>

                                    </v-card>
                                </template>
                                <!-- mobile version -->
                                <template v-else v-slot:item="{ item, index }">
                                    <v-card tile flat class="mb-6 pa-2 rounded-lg" color="light_gray_1">
                                        <v-card-text class="d-flex pb-1">
                                            <v-icon size="32" class="mt-n2">mdi-account</v-icon>
                                            <div class="body-1 font-weight-medium pl-1">
                                                {{ getFriendlyName(item) }}
                                            </div>
                                        </v-card-text>
                                        <v-card-text class="pt-0">
                                            <v-chip :filter="false" :ripple="false"
                                                :style="`background-color: ${hexToRGBA(getActiveTheme.colors.secondary, 0.2)}`"
                                                class="px-5 font-weight-medium">
                                                {{ item.roles.length > 1 ? `${item.roles[0]} +${item.roles.length - 1}`: item.roles.length == 1 ? item.roles[0] : '' }}
                                            </v-chip>
                                        </v-card-text>
                                        <v-card-actions>

                                            <v-spacer></v-spacer>

                                            <v-btn
                                                depressed
                                                small
                                                @click="handleAddDetails(item)"
                                                :color="getCompleted.includes(item.id) ? 'success' : 'secondary'">
                                                {{ getCompleted.includes(item.id) ? 'Completed' : 'Add Details' }}
                                            </v-btn>

                                        </v-card-actions>
                                    </v-card>
                                </template>

                            </v-data-table>

                            <v-alert dense outlined type="error" v-if="errors.length > 0">
                                <div v-for="msg in errors" v-html="msg"></div>
                            </v-alert>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <!-- Modal -->
        <v-dialog
            max-width="844"
            persistent
            scrollable
            v-model="requirementsDialog">

            <v-card flat :disabled="requirementsDialogLoading">
                <v-progress-linear color="secondary" indeterminate stream absolute top v-if="requirementsDialogLoading"></v-progress-linear>

                    <v-card-title class="justify-end">
                        <v-btn icon
                            max-width="40"
                            max-height="40"
                            @click="closeModal" fab>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text class="px-0 py-0 pb-8">
                        <v-carousel
                            v-if="steps.length"
                            light
                            v-model="carousel"
                            :continuous="false"
                            :show-arrows="false"
                            hide-delimiters
                            touchless
                            height="auto"
                            hide-delimiter-background>
                            <v-carousel-item v-for="(step, index) in steps" :key="index">
                                    <v-card flat>

                                        <v-card-title class="d-flex py-0" :class="vb.mdAndUp ? 'px-8' : 'px-4'">
                                            <div>
                                                <h4 :class="vb.mdAndUp ? 'font-size-26' : 'font-size-18'">{{step.title}} | {{ getFriendlyName(selectedEntity) }}</h4>
                                                <p :class="vb.mdAndUp ? 'body-1' : 'caption'">{{step.subtitle}}</p>
                                            </div>
                                            <v-spacer />
                                            <div>
                                                <v-icon @click="showHelperModal">mdi-help-circle-outline</v-icon>
                                            </div>
                                        </v-card-title>

                                        <v-card-text class="px-0">
                                            <template v-if="step['component-type'] === 'person-information'">
                                                <PersonEntityInformation
                                                    :ref="step['form-ref']"
                                                    :data="step.data"
                                                    :updater="requirementsDialog"
                                                    @loading="handleLoading"
                                                    @submit="handleSubmitEntityPersonInformation" />
                                            </template>

                                            <template v-if="step['component-type'] === 'business-information'">
                                                <BusinessEntityInformation
                                                    :ref="step['form-ref']"
                                                    :data="step.data"
                                                    :updater="requirementsDialog"
                                                    @loading="handleLoading"
                                                    @submit="handleSubmitEntityBusinessInformation" />
                                            </template>
                                            <template v-if="step['component-type'] === 'address'">

                                                <PersonEntityAddress
                                                    v-if="selectedEntity && selectedEntity.type == 'people'"
                                                    :ref="step['form-ref']"
                                                    :data="step.data"
                                                    :updater="requirementsDialog"
                                                    @loading="handleLoading"
                                                    @submit="handleSubmitEntityAddress" />
                                                <BusinessEntityAddress
                                                    v-else
                                                    :ref="step['form-ref']"
                                                    :data="step.data"
                                                    :updater="requirementsDialog"
                                                    @loading="handleLoading"
                                                    @submit="handleSubmitEntityAddress" />

                                            </template>

                                        </v-card-text>

                                    </v-card>
                            
                            </v-carousel-item>

                        </v-carousel>
                        <div v-else class="error--text font-size-20 px-8 font-weight-bold">
                            No Records Found for this Entity.
                        </div>
                    </v-card-text>


                    <v-card-actions class="pa-4 pa-md-6 flex-wrap controls">

                        <v-btn
                            color="light_gray_2"
                            class="flex-back-button"
                            :min-width="140" large outlined
                            @click="handleBack"
                            elevation="0" nuxt > Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" :min-width="140"
                            large elevation="0"
                            :disabled="steps.length === 0"
                            @click="handleNext"
                            nuxt> {{ isLastStep ? "Save" : "Next" }}
                        </v-btn>
                    </v-card-actions>


            </v-card>
        </v-dialog>

        <HelperModal v-if="vb.smAndDown" />

    </div>
</template>

<script>

    import { mapActions, mapState, mapMutations } from 'vuex'
    import subscriptions from '~/components/shared/subscriptions'
    import shared from '~/components/v1/submission/WestpacEntities1/__shared'
    import stepsLayout from '~/components/v1/submission/WestpacEntities1/__steps_layout'

    import { actions } from '~/components/v1/submission/WestpacEntities1/__store'

    import PersonEntityInformation from '~/components/v1/submission/WestpacEntities1/_PersonEntityInformation'
    import BusinessEntityInformation from '~/components/v1/submission/WestpacEntities1/_BusinessEntityInformation'
    import PersonEntityAddress from '~/components/v1/submission/WestpacEntities1/_PersonEntityAddress'
    import BusinessEntityAddress from '~/components/v1/submission/WestpacEntities1/_BusinessEntityAddress'

    import HelperModal from '~/components/base/LenderHelperDialog'
    import { add, isEmpty } from 'lodash'

    export default {
        components: {
            PersonEntityInformation,
            BusinessEntityInformation,
            PersonEntityAddress,
            BusinessEntityAddress,
            HelperModal
        },

        mixins: [subscriptions, shared],

        data() {
            return {
                requirementsDialog: false,
                requirementsDialogLoading: false,

                people: [],
                businesses: [],
                incomes: [],
                addresses: [],
                occupancies: [],

                selectedData: {
                    income: null,
                    address: null,
                    occupancy: null,
                    business: null
                },

                selectedEntity: null,

                errors: [],

            }
        },

        computed: {
            vb(){
                return this.$vuetify.breakpoint
            },
            getApplicantKinds(){

                let parts = this.kinds.find( kind => {
                    if(kind.name == 'Part'){
                        return kind
                    }
                })

                return parts.children
            },
            formUpdater(){

                return this.requirementsDialog

            },
            getCompleted(){

                const {people, businesses} = this

                let completed = []

                people.forEach(person => {
                    
                    const completedIncomeRecord = this.checkPersonIncomeRecord(person)
                    const completedAddressRecord = this.checkPersonAddressRecord(person)

                    if(completedIncomeRecord && completedAddressRecord) {
                        completed.push(person.id)
                    }

                });

                businesses.forEach(business => {

                    const completedBusinessRecord = this.checkBusinessRecordComplete(business)
                    const completedAddressRecord = this.checkBusinessAddressRecord(business)

                    if(completedBusinessRecord && completedAddressRecord) {
                        completed.push(business.id)
                    }

                });

                return completed

            },
            entities() {
                return [
                    ...this.businesses,
                    ...this.people,
                ]
            }
        },

        methods: {
            ...mapActions('resource-actions', [
                'getLoan',
                'getPeople',
                'getBusinesses',
                'getIncomes',
                'getExpenses',
                'updateLoan',
                'updatePerson',
                'updateIncome',
                'getAddresses',
                'getOccupancies',
                'updateAddress',
                'updateBusiness',
                'createIncome',
                'createOccupancy',
                'createAddress'
            ]),
            ...mapMutations('dialogs', [
                'toggleLenderSubmissionHelperDialog'
            ]),
            async pull() {

                let promise1 = this.getLoan(this.getLoanId, {"include" : 'parent-parts'})
                let promise2 = this.getPeople({"include" : 'parent-parts'})
                let promise3 = this.getBusinesses({"include" : 'parent-parts'})
                let promise4 = this.getIncomes()
                let promise5 = this.getAddresses()
                let promise6 = this.getOccupancies()

                const res = await Promise.all([
                    promise1,
                    promise2,
                    promise3,
                    promise4,
                    promise5,
                    promise6
                ])

                promise1 = res[0]
                promise2 = res[1]
                promise3 = res[2]
                promise4 = res[3]
                promise5 = res[4]
                promise6 = res[5]

                if(promise1.data?.data?.relationships?.kind?.data?.id){
                    this.loanKindId = promise1.data.data.relationships.kind.data.id
                }

                this.people = this.getEntityByRoles(promise2.data)
                this.businesses = this.getEntityByRoles(promise3.data)
                this.incomes = promise4?.data?.data ? promise4.data.data : []
                this.addresses = promise5?.data?.data ? promise5.data.data : []
                this.occupancies = promise6?.data?.data ? promise6.data.data : []

                if(promise2.data.included){
                    this.parts = promise2.data.included.filter( part => {
                        return part.type == "parts"
                    })
                }

            },
            async push() {

                this.checkCompleted()

                let valid = this.errors.length == 0

                let status = valid ?  'valid' : 'dirty'

                return {valid, status, data:{}}

            },

            checkCompleted(){

                this.errors = []

                if(this.getCompleted.length != this.entities.length) {
                    this.errors.push('Please complete all requirements')
                }

            },

            showHelperModal() {
                this.toggleLenderSubmissionHelperDialog(true)
            },

            async handleAddDetails(data){

                this.selectedEntity = data

                if(data.roles[0] === 'Applicant') {
                    this.entityRole = this.getRoleKind(data)
                } else {
                    this.entityRole = data.roles[0]
                }

                await this.populateData(data)

                this.requirementsDialog = true

            },

            checkBusinessRecordComplete(business) {

                let completeBusinessRecord = true

                if(business.roles.length && business.roles[0] === 'Applicant') {
                    if(this.getRoleKind(business) === 'Trust' || this.getRoleKind(business) === 'Trustee') {
                        if(isEmpty(business.attributes.lender.westpac['industry']) || isEmpty(business.attributes.lender.westpac['trustType'])) {
                            completeBusinessRecord = false
                        }
                    } else {
                        if(isEmpty(business.attributes.lender.westpac['industry']) || isEmpty(business.attributes.lender.westpac['companyType'])) {
                            completeBusinessRecord = true
                        }
                    }
                } else {
                    if(business.roles[0] === 'Trust' || business.roles[0] === 'Trustee') {
                        if(isEmpty(business.attributes.lender.westpac['industry']) || isEmpty(business.attributes.lender.westpac['trustType'])) {
                            completeBusinessRecord = false
                        }
                    } else {
                        if(isEmpty(business.attributes.lender.westpac['industry']) || isEmpty(business.attributes.lender.westpac['companyType'])) {
                            completeBusinessRecord = true
                        }
                    }
                }

                return completeBusinessRecord
            },

            checkPersonIncomeRecord(person) {
                let { incomes } = this
                let completedPersonRecord = true

                if( person.relationships.incomes.data.length > 0 ) {

                    person.relationships.incomes.data.forEach( data => {

                        incomes.forEach( income => {
                            // check for income
                            if(income.id == data.id) {
                                if ( isEmpty(income.attributes['lender']['westpac']['currentOccupation']) ) {
                                    completedPersonRecord = false
                                }
                            }

                        })

                    })

                }

                if(person.roles[0] === 'Beneficial Owner' && person.relationships.incomes.data.length === 0) {
                    completedPersonRecord = false
                }

                return completedPersonRecord

            },

            checkPersonAddressRecord(person) {
                let { occupancies, addresses } = this
                let completePersonRecord = true

                if(occupancies.length) {
                    occupancies.forEach(occupancy => {
                        person.relationships.occupancies.data.forEach(dat => {
                            if(dat.id == occupancy.id) {
                                addresses.forEach(address => {
                                    if(occupancy.relationships.address.data.id == address.id) {
                                        if( isEmpty(address.attributes.lender.westpac['addressType']) || isEmpty(address.attributes.lender.westpac['businessPremisesStatus']) ) {
                                            completePersonRecord = false
                                        }
                                    }
                                })
                            }
                        })
                    })
                }

                return completePersonRecord
            },

            checkBusinessAddressRecord(business) {
                let { addresses } = this
                let completeBusinessRecord = true

                if(addresses.length) {
                    addresses.forEach(address => {
                        if(business.relationships.addresses.data.length) {
                            business.relationships.addresses.data.forEach(dat => {
                                if(dat.id === address.id) {
                                    if( isEmpty(address.attributes.lender.westpac['addressType']) || isEmpty(address.attributes.lender.westpac['businessPremisesStatus']) ) {
                                        completeBusinessRecord = false
                                    }
                                }
                            })
                            
                        }
                    })
                }

                return completeBusinessRecord
            },

            async populateData(entity) {

                actions.setSteps([])
                let activeSteps = []

                let data = {
                    incomes: [],
                    addresses: [],
                    occupancies: [],
                    business: null,
                }

                let { incomes, occupancies, addresses } = this

                // get the incomes
                if(incomes.length) {
                    incomes.forEach(income => {
                        entity.relationships.incomes.data.forEach(dat => {
                            if(dat.id == income.id) {
                                data.incomes.push(income)
                            }
                        })
                    })
                }

                // get the occupancies
                if(occupancies.length) {
                    occupancies.forEach(occupancy => {
                        if(entity.type != 'businesses') {
                            entity.relationships.occupancies.data.forEach(dat => {
                                if(dat.id == occupancy.id) {
                                    data.occupancies.push(occupancy)
                                }
                            })
                        }
                    })
                }

                // get the related addresses
                if(entity.type  === 'businesses') {
                    if(addresses.length) {
                        addresses.forEach(address => {
                            if(entity.relationships.addresses.data.length) {
                                entity.relationships.addresses.data.forEach(dat => {
                                    if(dat.id === address.id) {
                                        data.addresses.push(address)
                                    }   
                                })
                            }
                        })
                    }
                } else {
                    if(data.occupancies.length && addresses.length) {
                        data.occupancies.forEach(occupancy => {
                            addresses.forEach(address => {
                                if(occupancy.relationships.address.data.id == address.id) {
                                    data.addresses.push(address)
                                }
                            })
                        })
                        
                    }
                }

                if(entity.type === 'businesses') {
                    data.business = entity
                }

                // get steps layout
                const steps = stepsLayout.steps[this.entityRole] ? stepsLayout.steps[this.entityRole] : null

                if(steps) {

                    if(entity.type === 'businesses') {

                        activeSteps.push({
                            ...steps.business,
                            data: data.business,
                        })

                        if(data.addresses.length > 0) {
                            data.addresses.forEach(address => {
                                activeSteps.push({
                                    ...steps.address,
                                    data: {
                                        ...address,
                                        roles: entity.roles,
                                    },
                                    'form-ref': steps.address['form-ref'] + '-' + address.id,
                                })
                            })
                        }

                    } else {

                        // If Beneficial Owner
                        if( entity.roles[0] === 'Beneficial Owner' ) {

                            if(data.incomes.length > 0) {

                                data.incomes.forEach(income => {
                                    activeSteps.push({
                                        ...steps.incomes,
                                        data: income,
                                        'form-ref': steps.incomes['form-ref'] + '-' + income.id,
                                    })
                                })

                            } else {
                                activeSteps.push({
                                    ...steps.incomes,
                                    data: {attributes: entity.attributes}
                                })
                            }

                            if(data.addresses.length > 0) {

                                data.addresses.forEach((address, index) => {
                                    activeSteps.push({
                                        ...steps.address,
                                        data: {
                                            ...address,
                                            "occupancy": data.occupancies[index]
                                        },
                                        'form-ref': steps.address['form-ref'] + '-' + address.id,
                                    })
                                })
                                
                            } else {

                                activeSteps.push({
                                    ...steps.address,
                                    data: {attributes: entity.attributes}
                                })

                            }

                        } else {

                            if(data.incomes.length > 0) {
                                data.incomes.forEach(income => {
                                    activeSteps.push({
                                        ...steps.incomes,
                                        data: income,
                                        'form-ref': steps.incomes['form-ref'] + '-' + income.id,
                                    })
                                })
                            }

                            if(data.addresses.length > 0) {
                                data.addresses.forEach((address, index) => {
                                    activeSteps.push({
                                        ...steps.address,
                                        data: {
                                            ...address,
                                            "occupancy": data.occupancies[index]
                                        },
                                        'form-ref': steps.address['form-ref'] + '-' + address.id,
                                    })
                                })
                            }

                        }

                    }
                    
                    actions.setSteps(activeSteps)

                }

            },

            async handleNext(){

                let stepsArray = this.steps

                if(this.$refs[stepsArray[this.carousel]['form-ref']].length && this.$refs[stepsArray[this.carousel]['form-ref']][0]) {
                    await this.$refs[stepsArray[this.carousel]['form-ref']][0].validate()
                }

            },
            async handleBack(){
                if(this.isFirstStep)
                    this.closeModal()
                else
                    this.carousel--
            },
            async handleSubmitEntityPersonInformation(payload){
                try {

                    this.requirementsDialogLoading = true

                    if(payload.hasOwnProperty('id') && payload.id) {

                        let res = await this.updateIncome(payload)

                        if(res.status == 200) {

                            this.updateLocalResourceAttributes(res.data.data)

                            if(this.isLastStep) {
                                this.closeModal()
                                actions.resetStore();
                            } else {
                                this.carousel++
                                this.requirementsDialogLoading = false
                            }

                            
                        } else {

                            this.requirementsDialogLoading = false
                        }

                    } else if(this.selectedEntity.roles[0] === "Beneficial Owner" && this.selectedEntity.type === 'people') {
                        const income = {
                            type: 'incomes',
                            attributes: {
                                ...payload.attributes
                            },
                            relationships: {
                                "application": {
                                    "data": {
                                        "type": "applications",
                                        "id": this.appId
                                    }
                                },
                                "people": {
                                    "data": [
                                        {
                                            "type": "people",
                                            "id": this.selectedEntity.id
                                        }
                                    ],
                                    "meta": {
                                        strategy: "replace"
                                    }
                                },
                                "kind":{
                                    "data": {
                                        "type": "kinds",
                                        // other income
                                        "id": '12-26'
                                    }
                                }
                            }
                        }

                        let res = await this.createIncome(income)

                        if(res.status == 201) {

                            this.updateLocalResourceAttributes(res.data.data)

                            if(this.isLastStep) {
                                this.closeModal()
                                actions.resetStore();
                            } else {
                                this.carousel++
                                this.requirementsDialogLoading = false
                            }

                        }

                    }

                    this.requirementsDialogLoading = false

                } catch (err) {
                    this.requirementsDialogLoading = false
                }
            },
            async handleSubmitEntityBusinessInformation(payload){
                try {

                    this.requirementsDialogLoading = true

                    if(payload.hasOwnProperty('id') && payload.id) {

                        let res = await this.updateBusiness(payload)

                        if(res.status == 200) {

                            this.updateLocalResourceAttributes(res.data.data)

                            if(this.isLastStep) {
                                this.closeModal()
                                actions.resetStore();
                            } else {
                                this.carousel++
                                this.requirementsDialogLoading = false
                            }

                            
                        } else {

                            this.requirementsDialogLoading = false
                        }

                    }

                    this.requirementsDialogLoading = false

                } catch (err) {
                    this.requirementsDialogLoading = false
                }
            },
            async handleSubmitEntityAddress(payload){
                try {
                    this.requirementsDialogLoading = true

                    if(payload.hasOwnProperty('id') && payload.id) {

                        let res = await this.updateAddress(payload)

                        if(res.status == 200) {

                            this.updateLocalResourceAttributes(res.data.data)

                            if(this.isLastStep) {
                                this.closeModal()
                                actions.resetStore();
                            } else {
                                this.carousel++
                                this.requirementsDialogLoading = false
                            }

                            
                        } else {

                            this.requirementsDialogLoading = false
                        }

                    } else if(this.selectedEntity.roles[0] === "Beneficial Owner" && this.selectedEntity.type === 'people') {
                        let address = {
                            "type": "addresses",
                            "attributes": {
                                ...payload.attributes
                            },
                            "relationships": {
                                "application": {
                                    "data": {
                                        "type": "applications",
                                        "id": `${this.appId}`
                                    }
                                }
                            }
                        }

                        let addressRes = await this.createAddress(address)

                        if(addressRes.status === 201) {

                            let occupancy = {
                                type: 'occupancies',
                                attributes: {},
                                relationships: {
                                    "application": {
                                        "data": {
                                            "type": "applications",
                                            "id": this.appId
                                        }
                                    },
                                    "address": {
                                        "data": {
                                            "type": "addresses",
                                            "id": addressRes.data.data.id
                                        }
                                    },
                                    "person": {
                                        "data": {
                                            "type": "people",
                                            "id": this.selectedEntity.id
                                        }
                                    },
                                }
                            }

                            const occRes = await this.createOccupancy(occupancy)

                            if(occRes.status === 201) {
                                this.updateLocalResourceAttributes(occRes.data.data)
                                this.updateLocalResourceAttributes(addressRes.data.data)

                                if(this.isLastStep) {
                                    this.closeModal()
                                    actions.resetStore();
                                    await this.pull()
                                } else {
                                    this.carousel++
                                    this.requirementsDialogLoading = false
                                }
                            }
                        }
                        
                    }

                    this.requirementsDialogLoading = false
                } catch (err) {
                    this.requirementsDialogLoading = false
                }
            },
            closeModal() {
                this.requirementsDialogLoading = false
                this.requirementsDialog = false
                this.entityRole = null
                this.carousel = 0
                this.selectedData = {
                    income: null,
                    address: null,
                    occupancy: null,
                    business: null
                }
            },
            updateLocalResourceAttributes(resource){

                if(!resource) return;

                let getType = resource.type

                this[getType].forEach( (v, i) => {

                    if(v.id == resource.id) {
                        this[getType][i]['attributes'] = resource.attributes
                    }

                })

            },
            handleLoading(payload){
                this.requirementsDialogLoading = payload
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
    
    